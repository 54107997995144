import { ComponentStyleConfig, defineStyleConfig } from '@chakra-ui/react';

// const Header = defineStyleConfig({
//   // The styles all button have in common
//   baseStyle: {
//     color: 'blue.400',
//   },
// });

const Heading: ComponentStyleConfig = {
  // style object for base or default style
  baseStyle: {
    color: 'black',
  },
  //   // styles for different sizes ("sm", "md", "lg")
  //   sizes: {},
  //   // styles for different visual variants ("outline", "solid")
  //   variants: {},
  //   // default values for 'size', 'variant' and 'colorScheme'
  //   defaultProps: {
  //     size: '',
  //     variant: '',
  //     colorScheme: '',
  //   },
};

export default Heading;
