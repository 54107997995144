import { ComponentStyleConfig } from '@chakra-ui/react';

const Progress: ComponentStyleConfig = {
  // style object for base or default style
  baseStyle: {
    colorScheme: 'orange',
    color: 'orange.700',
  },
  //   // styles for different sizes ("sm", "md", "lg")
  //   sizes: {},
  //   // styles for different visual variants ("outline", "solid")
  //   variants: {},
  //   // default values for 'size', 'variant' and 'colorScheme'
  //   defaultProps: {
  //     size: '',
  //     variant: '',
  //     colorScheme: '',
  //   },
};

export default Progress;
