import { Flex, Spinner } from '@chakra-ui/react';
import React, { useContext, useEffect } from 'react';

function LoadingScreen() {
  return (
    <Flex flex={1} width="100%" height="100%" justifyContent="center" alignItems="center">
      <Spinner size="lg" color="blue.700" />
    </Flex>
  );
}

export default LoadingScreen;
