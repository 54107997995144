/* eslint-disable import/prefer-default-export */
import { UserCredential } from '@firebase/auth';
import { User as UserAuth } from 'firebase/auth';
import fetchWrapper from './fetchWrapper';
import { PAYMENTS_API, PROFESSIONALS_API } from '../config/ServiceConstants';

export function createConnectedUserAccount(userAuth: UserAuth): Promise<any> {
  const url = `${PROFESSIONALS_API}/connectedaccount`;
  return fetchWrapper(userAuth).post(url, window.location.href);
}
