import * as React from 'react';
import { ChakraProvider, Box, Text, Link, VStack, Code, Grid, theme, Heading } from '@chakra-ui/react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import donnetheme from './theme';
import Fonts from './theme/foundations/customfonts';
import { CurrentUserProvider } from './contexts/CurrentUserProvider';
import RouteComponent from './RouteComponent';
import { CurrentProfessionalProvider } from './contexts/CurrentProfessionalProvider';

export function App() {
  return (
    <ChakraProvider theme={donnetheme}>
      <Fonts />
      <CurrentUserProvider>
        <CurrentProfessionalProvider>
          <DndProvider backend={HTML5Backend}>
            <RouteComponent />
          </DndProvider>
        </CurrentProfessionalProvider>
      </CurrentUserProvider>
    </ChakraProvider>
  );
}
