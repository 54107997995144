/* eslint-disable react/no-array-index-key */
import moment from 'moment';
import {
  Box,
  Text,
  Heading,
  HStack,
  Flex,
  Image,
  Button,
  Highlight,
  Spinner,
  Icon,
  IconButton,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  Tooltip,
  SimpleGrid,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { User as UserAuth } from 'firebase/auth';
import { useFilePicker } from 'use-file-picker';
import { ChevronRightIcon, DeleteIcon, InfoIcon } from '@chakra-ui/icons';
import { MultiSelect } from 'chakra-multiselect';
import { RiEditCircleFill } from 'react-icons/ri';
import { CurrentUserContext } from '../contexts/CurrentUserProvider';
import { CurrentProfessionalContext } from '../contexts/CurrentProfessionalProvider';
import { auth } from '../config/firebase';
import AddIcon from '../components/AddIcon';
import { ProfessionalUser } from '../models/ProfessionalUser';
import {
  createProfessionalRepository,
  createProfessionalResourceFile,
  createProfessionalResourceNote,
  deleteProfessionalResourceFile,
  deleteProfessionalResourceNote,
  fetchProfessionalRepositories,
  fetchProfessionalResourceFiles,
  fetchProfessionalResourceNotes,
  updateProfessionalRepository,
  updateProfessionalResourceFile,
  updateProfessionalResourceNote,
} from '../services/ProfessionalsService';
import { ProfessionalRepository, ProfessionalRepositoryInstance, ProfessionalRepositoryInstanceItem } from '../models/ProfessionalRepository';
import LoadingScreen from '../components/LoadingScreen';
import '../theme/multiselect-overrides.module.css';
import { ProfessionalResourceNote } from '../models/ProfessionalResourceNote';
import { ProfessionalResourceFile } from '../models/ProfessionalResourceFile';
import UserMediaAssetDisplay from '../components/UserMediaAssetDisplay';

function ResourcesScreen() {
  const { currentUser } = useContext(CurrentUserContext);
  const { currentProfessional } = useContext(CurrentProfessionalContext);
  const [user, loading, error] = useAuthState(auth);
  const [notes, setNotes] = useState<ProfessionalResourceNote[]>([]);
  const [selectedNote, setSelectedNote] = useState<ProfessionalResourceNote | undefined>();
  const [repositories, setRepositories] = useState<ProfessionalRepository[] | undefined>();
  const [selectedRepository, setSelectedRepository] = useState<ProfessionalRepository | undefined>();
  const [repositoryToModify, setRepositoryToModify] = useState<ProfessionalRepository | undefined>();
  const [favorites, setFavorites] = useState<ProfessionalResourceFile[] | undefined>();
  const [selectedFavorite, setSelectedFavorite] = useState<ProfessionalResourceFile | undefined>();
  const [documents, setDocuments] = useState<ProfessionalResourceFile[] | undefined>();
  const [selectedDocument, setSelectedDocument] = useState<ProfessionalResourceFile | undefined>();

  useEffect(() => {
    if (!user) return;
    if (!currentProfessional) return;
    const fetchRepositories = async () => {
      await fetchProfessionalRepositories(user, currentProfessional.userid)
        .then((res) => res.json())
        .then((result: ProfessionalRepository[]) => {
          setRepositories(result);
        });
    };
    fetchRepositories().catch(console.error);
  }, [currentProfessional, user]);

  useEffect(() => {
    if (!user) return;
    if (!currentProfessional) return;
    const fetchNotes = async () => {
      await fetchProfessionalResourceNotes(user, currentProfessional.userid)
        .then((res) => res.json())
        .then((result: ProfessionalResourceNote[]) => {
          setNotes(result);
        });
    };
    fetchNotes().catch(console.error);
  }, [currentProfessional, user]);

  useEffect(() => {
    if (!user) return;
    if (!currentProfessional) return;
    const fetchFiles = async () => {
      await fetchProfessionalResourceFiles(user, currentProfessional.userid)
        .then((res) => res.json())
        .then((result: ProfessionalResourceFile[]) => {
          setFavorites(result.filter((r) => r.type.toLowerCase() === 'favorite'));
          setDocuments(result.filter((r) => r.type.toLowerCase() === 'document'));
        });
    };
    fetchFiles().catch(console.error);
  }, [currentProfessional, user]);

  const [openFileSelector, { filesContent, plainFiles, loading: loadingFileSelector, errors, clear: clearFilePicker }] = useFilePicker({
    readAs: 'DataURL',
    accept: ['image/*', '.png', '.jpg', '.jpeg'],
    multiple: false,
    limitFilesConfig: { max: 1 },
    // minFileSize: 0.1, // in megabytes
    maxFileSize: 20,
    imageSizeRestrictions: {
      maxHeight: 2000, // in pixels
      maxWidth: 2000,
      minHeight: 20,
      minWidth: 20,
    },
  });

  const [
    openFileSelectorDocuments,
    {
      filesContent: filesContentDocuments,
      plainFiles: plainFilesDocuments,
      loading: loadingFileSelectorDocuments,
      errors: errorsDocuments,
      clear: clearFilePickerDocuments,
    },
  ] = useFilePicker({
    readAs: 'DataURL',
    accept: ['applicaion/*', '.pdf', '.doc', '.docx', '.txt'],
    multiple: false,
    limitFilesConfig: { max: 1 },
    maxFileSize: 20,
  });

  if (!user || !currentProfessional) return <LoadingScreen />;

  const handleDeleteNote = async () => {
    if (!selectedNote) return;
    if (selectedNote.id === 0) {
      setSelectedNote(undefined);
    } else {
      await deleteProfessionalResourceNote(user as UserAuth, currentProfessional.userid, selectedNote.id);
      let clone: ProfessionalResourceNote[] = structuredClone(notes);
      clone = clone.filter((n) => n.id !== selectedNote.id);
      setNotes(clone);
      setSelectedNote(undefined);
    }
  };

  const handleSaveNote = async () => {
    if (!selectedNote) return;
    if (selectedNote.id === 0) {
      // create
      await createProfessionalResourceNote(user as UserAuth, currentProfessional.userid, selectedNote)
        .then((res) => res.json())
        .then((result) => {
          const clone: ProfessionalResourceNote[] = structuredClone(notes);
          clone.push(result);
          setNotes(clone);
          setSelectedNote(undefined);
        });
    } else {
      await updateProfessionalResourceNote(user as UserAuth, currentProfessional.userid, selectedNote);
      let clone: ProfessionalResourceNote[] = structuredClone(notes);
      clone = clone.map((n) => (n.id !== selectedNote.id ? n : selectedNote));
      setNotes(clone);
      setSelectedNote(undefined);
    }
  };

  const updateFieldValueOfRepositoryInstance = (instanceid: number, fieldname: string, newvalue: string) => {
    const clone: ProfessionalRepository = structuredClone(selectedRepository);
    clone.instances.filter((i) => i.id === instanceid)[0].items.filter((i) => i.field === fieldname)[0].value = newvalue;
    setSelectedRepository(clone);
  };

  const handleSaveRepository = async () => {
    if (!repositoryToModify) return;
    if (repositoryToModify.id === 0) {
      await createProfessionalRepository(user as UserAuth, currentProfessional.userid, repositoryToModify)
        .then((res) => res.json())
        .then((result) => {
          const clone: ProfessionalRepository[] = structuredClone(repositories);
          clone.push(result);
          setRepositories(clone);
          setRepositoryToModify(undefined);
        });
    } else {
      await updateProfessionalRepository(user as UserAuth, currentProfessional.userid, repositoryToModify.id, repositoryToModify)
        .then((res) => res.json())
        .then((result) => {
          let clone: ProfessionalRepository[] = structuredClone(repositories);
          clone = clone.map((r) => (r.id !== repositoryToModify.id ? r : result));
          setRepositories(clone);
          setRepositoryToModify(undefined);
        });
    }
  };

  const renderNotes = () => {
    if (!notes) return <Spinner />;
    return (
      <>
        <Flex flexDir="column" flex={1}>
          {notes.map((note, i) => (
            <Flex
              key={i.toString()}
              flex={1}
              boxShadow="base"
              rounded="lg"
              py={1}
              my={2}
              borderWidth={1}
              justifyContent="space-between"
              alignItems="center"
              _hover={{ boxShadow: 'xl', borderWidth: 2 }}
              onClick={() => setSelectedNote(note)}
            >
              <Text ml={4}>{note.title}</Text>
              <ChevronRightIcon color="blue.400" h={6} w={6} mr={2} />
            </Flex>
          ))}
        </Flex>
        <Drawer placement="right" size="xl" onClose={() => setSelectedNote(undefined)} isOpen={selectedNote !== undefined}>
          <DrawerOverlay />
          <DrawerContent bg="white">
            <DrawerBody>
              <Flex flex={1} flexDir="column" m={8}>
                <Text size="sm">Title</Text>
                <Input
                  mb={4}
                  width="40%"
                  value={selectedNote?.title}
                  onChange={(e) => setSelectedNote({ ...selectedNote, title: e.target.value } as ProfessionalResourceNote)}
                />
                <Text size="sm">Note</Text>
                <Textarea
                  p={4}
                  mb={4}
                  resize="vertical"
                  size="2xl"
                  height="md"
                  value={selectedNote?.content}
                  onChange={(e) => setSelectedNote({ ...selectedNote, content: e.target.value } as ProfessionalResourceNote)}
                />
              </Flex>
              <Flex borderTopWidth={1} mx={8} mt={16} py={4}>
                <Flex flex={1} justifyContent="space-between">
                  <Button variant="outline" mr={4} onClick={handleDeleteNote}>
                    Delete Note
                  </Button>
                  <Flex flex={1} flexGrow={1} justifyContent="flex-end">
                    <Button variant="darkoutline" mr={4} onClick={() => setSelectedNote(undefined)}>
                      Cancel
                    </Button>
                    <Button variant="solid" onClick={handleSaveNote}>
                      Save Note
                    </Button>
                  </Flex>
                </Flex>
              </Flex>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </>
    );
  };

  const renderRepositoryItems = () => {
    if (!repositories) return <Spinner />;
    return (
      <>
        <Flex flexDir="column" flex={1}>
          {repositories.map((repository, i) => (
            <Flex
              key={i.toString()}
              flex={1}
              boxShadow="base"
              rounded="lg"
              py={1}
              my={2}
              borderWidth={1}
              justifyContent="space-between"
              alignItems="center"
              _hover={{ boxShadow: 'xl', borderWidth: 2 }}
              onClick={() => setSelectedRepository(repository)}
            >
              <Text ml={4}>{repository.title}</Text>
              <ChevronRightIcon color="blue.400" h={6} w={6} mr={2} />
            </Flex>
          ))}
        </Flex>
        <Drawer placement="right" size="xl" onClose={() => setSelectedRepository(undefined)} isOpen={selectedRepository !== undefined}>
          <DrawerOverlay />
          <DrawerContent bg="white">
            <DrawerHeader mb={6}>
              <Flex alignItems="center">
                <Text fontWeight="bold" fontSize="2xl">
                  Repository
                </Text>
                <ChevronRightIcon color="blue.400" h={6} w={6} mx={2} />
                <Text fontWeight="bold" fontSize="2xl">
                  {selectedRepository?.title}
                </Text>
                <Button
                  variant="outline"
                  ml={6}
                  borderRadius="2xl"
                  onClick={() => {
                    setRepositoryToModify(selectedRepository);
                    setSelectedRepository(undefined);
                  }}
                >
                  Edit
                </Button>
              </Flex>
            </DrawerHeader>
            <DrawerBody>
              <Flex flex={1} flexDir="column">
                {selectedRepository?.instances.map((instance, i) => (
                  <Flex key={i.toString()} alignItems="center">
                    {instance.items.map((item, i2) => (
                      <Flex key={i2.toString()} flexDir="column" mr={4} mb={4}>
                        <Text fontSize="xs">{item.field}</Text>
                        <Input size="md" value={item.value} onChange={(e) => updateFieldValueOfRepositoryInstance(instance.id, item.field, e.target.value)} />
                      </Flex>
                    ))}
                    {i > 0 && (
                      <DeleteIcon
                        mt={2}
                        ml={2}
                        onClick={() => {
                          const clone: ProfessionalRepository = structuredClone(selectedRepository);
                          clone.instances = clone.instances.filter((inst) => inst.id !== instance.id); // this logic may be suspect
                          setSelectedRepository(clone);
                        }}
                      />
                    )}
                  </Flex>
                ))}
                <Button
                  variant="solid"
                  mt={4}
                  width="20%"
                  onClick={() => {
                    if (!selectedRepository) return;
                    const clone: ProfessionalRepository = structuredClone(selectedRepository);
                    const newInstance: ProfessionalRepositoryInstance = {
                      id: Math.max(...clone.instances.map((i) => i.id)) + 1,
                      items: selectedRepository.fields.map(
                        (f) =>
                          ({
                            field: f,
                            value: '',
                          } as ProfessionalRepositoryInstanceItem)
                      ),
                    };
                    clone.instances.push(newInstance);
                    setSelectedRepository(clone);
                  }}
                >
                  Add new
                </Button>
              </Flex>
            </DrawerBody>
            <DrawerFooter>
              <Flex>
                <Button variant="darkoutline" mr={4} onClick={() => setSelectedRepository(undefined)}>
                  Cancel
                </Button>
                <Button
                  variant="solid"
                  onClick={() => {
                    if (!selectedRepository) return;
                    updateProfessionalRepository(user as UserAuth, currentProfessional.userid, selectedRepository.id, selectedRepository)
                      .then((res) => res.json())
                      .then((result) => {
                        let clone: ProfessionalRepository[] = structuredClone(repositories);
                        clone = clone.map((r) => (r.id !== selectedRepository.id ? r : result));
                        setRepositories(clone);
                        setSelectedRepository(undefined);
                      });
                  }}
                >
                  Save Repository
                </Button>
              </Flex>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
        <Modal size="lg" isCentered isOpen={repositoryToModify !== undefined} onClose={() => setRepositoryToModify(undefined)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{repositoryToModify && repositoryToModify.id > 0 ? 'Edit' : 'Add'} Repository</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex flexDir="column">
                <Text>Repository Name</Text>
                <Input
                  value={repositoryToModify?.title}
                  onChange={(e) => {
                    const clone: ProfessionalRepository = structuredClone(repositoryToModify);
                    clone.title = e.target.value;
                    setRepositoryToModify(clone);
                  }}
                />
                <Text>Default Fields</Text>
                <MultiSelect
                  create
                  options={[
                    { label: 'Brand', value: 'Brand' },
                    { label: 'Code', value: 'Code' },
                    { label: 'URL', value: 'URL' },
                    { label: 'Notes', value: 'Notes' },
                    { label: 'Description', value: 'Description' },
                    { label: 'Title', value: 'Title' },
                  ]}
                  value={repositoryToModify?.fields || ''}
                  onChange={(e) => {
                    const clone: ProfessionalRepository = structuredClone(repositoryToModify);
                    clone.fields = e as string[];
                    setRepositoryToModify(clone);
                  }}
                />
              </Flex>
            </ModalBody>
            <ModalFooter>
              <Button variant="darkoutline" mr={3} onClick={() => setRepositoryToModify(undefined)}>
                Cancel
              </Button>
              <Button variant="solid" onClick={() => handleSaveRepository()}>
                Save Repository
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  };

  const renderFavorites = () => {
    if (!favorites) return <Spinner />;
    return (
      <>
        <SimpleGrid columns={2} spacing={8} flex={1}>
          {favorites.map((fav) => (
            <Flex key={fav.id.toString()}>
              <UserMediaAssetDisplay uri={fav.file as string} type="image" height={32} width={32} />
              <Icon
                as={RiEditCircleFill}
                boxSize={6}
                color="orange.400"
                background="white"
                borderRadius="full"
                padding={1}
                transform="translateY(86px) translateX(-26px)"
                onClick={() => setSelectedFavorite(fav)}
              />
            </Flex>
          ))}
        </SimpleGrid>
        <Modal size="md" isCentered isOpen={selectedFavorite !== undefined} onClose={() => setSelectedFavorite(undefined)}>
          <ModalOverlay />
          <ModalContent borderRadius="lg">
            <ModalHeader>{selectedFavorite && selectedFavorite.id > 0 ? 'Edit' : 'Add'} Favorite</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex flexDir="column" justifyContent="center" m={2}>
                <Text size="sm">Favorite Description</Text>
                <Input
                  value={selectedFavorite?.title}
                  onChange={(e) =>
                    setSelectedFavorite({
                      ...selectedFavorite,
                      title: e.target.value,
                    } as ProfessionalResourceFile)
                  }
                />
                <Image src="/icons/camera_add.png" mt={6} h={12} w={12} onClick={() => openFileSelector()} />
                {loadingFileSelector && <Spinner />}
                {errors && errors.length > 0 && <Text>{JSON.stringify(errors)}</Text>}
                {filesContent && filesContent[0] && <Image src={filesContent[0].content} maxHeight={64} fit="contain" />}
                {!filesContent[0] && selectedFavorite?.file && <UserMediaAssetDisplay uri={selectedFavorite.file} type="image" height={64} width={64} />}
              </Flex>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="darkoutline"
                mr={4}
                onClick={() => {
                  setSelectedFavorite(undefined);
                  clearFilePicker();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="solid"
                bg="red.400"
                mr={4}
                onClick={async () => {
                  if (!selectedFavorite) return;
                  await deleteProfessionalResourceFile(user as UserAuth, user.uid, selectedFavorite.id)
                    .then(() => {
                      let clone: ProfessionalResourceFile[] = structuredClone(favorites);
                      clone = clone.filter((l) => l.id !== selectedFavorite?.id);
                      setFavorites(clone);
                      setSelectedFavorite(undefined);
                      clearFilePicker();
                    })
                    .catch((er) => console.log(er));
                }}
              >
                Delete
              </Button>
              <Button
                variant="solid"
                onClick={async () => {
                  if (!selectedFavorite) return;
                  if (selectedFavorite.id === 0) {
                    await createProfessionalResourceFile(user as UserAuth, user.uid, selectedFavorite, plainFiles[0])
                      .then((res) => res.json())
                      .then((result) => {
                        favorites.push(result);
                        setFavorites(favorites);
                        setSelectedFavorite(undefined);
                        clearFilePicker();
                      })
                      .catch((er) => console.log(er));
                  } else {
                    await updateProfessionalResourceFile(user as UserAuth, user.uid, selectedFavorite, plainFiles[0])
                      .then((res) => res.json())
                      .then((result) => {
                        const clone: ProfessionalResourceFile[] = structuredClone(favorites);
                        const indx = clone.findIndex((f) => f.id === selectedFavorite.id);
                        clone[indx] = result;
                        setFavorites(clone);
                        setSelectedFavorite(undefined);
                        clearFilePicker();
                      })
                      .catch((er) => console.log(er));
                  }
                }}
              >
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  };

  const renderDocuments = () => {
    if (!documents) return <Spinner />;
    return (
      <>
        <Flex flexDir="column" flex={1}>
          {documents.map((doc) => (
            <Flex
              key={doc.toString()}
              flex={1}
              boxShadow="base"
              rounded="lg"
              py={1}
              my={2}
              borderWidth={1}
              justifyContent="space-between"
              alignItems="center"
              _hover={{ boxShadow: 'xl', borderWidth: 2 }}
              onClick={() => setSelectedDocument(doc)}
            >
              <Text ml={4}>{doc.title}</Text>
              <ChevronRightIcon color="blue.400" h={6} w={6} mr={2} />
            </Flex>
          ))}
        </Flex>
        <Modal size="md" isCentered isOpen={selectedDocument !== undefined} onClose={() => setSelectedDocument(undefined)}>
          <ModalOverlay />
          <ModalContent borderRadius="lg">
            <ModalHeader>{selectedDocument && selectedDocument.id > 0 ? 'Edit' : 'Add'} Document</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex flexDir="column" justifyContent="center" m={2}>
                <Text size="sm">Document Description</Text>
                <Input
                  value={selectedDocument?.title}
                  onChange={(e) =>
                    setSelectedDocument({
                      ...selectedDocument,
                      title: e.target.value,
                    } as ProfessionalResourceFile)
                  }
                />
                <Image src="/icons/camera_add.png" mt={6} h={12} w={12} onClick={() => openFileSelectorDocuments()} />
                {loadingFileSelectorDocuments && <Spinner />}
                {errorsDocuments && errorsDocuments.length > 0 && <Text>{JSON.stringify(errorsDocuments)}</Text>}
                {filesContentDocuments && filesContentDocuments[0] && <Text>{filesContentDocuments[0].name}</Text>}
                {!filesContentDocuments[0] && selectedDocument?.file && <Text>{selectedDocument.title}</Text>}
              </Flex>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="darkoutline"
                mr={4}
                onClick={() => {
                  setSelectedDocument(undefined);
                  clearFilePickerDocuments();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="solid"
                bg="red.400"
                mr={4}
                onClick={async () => {
                  if (!selectedDocument) return;
                  await deleteProfessionalResourceFile(user as UserAuth, user.uid, selectedDocument.id)
                    .then(() => {
                      let clone: ProfessionalResourceFile[] = structuredClone(documents);
                      clone = clone.filter((l) => l.id !== selectedDocument?.id);
                      setDocuments(clone);
                      setSelectedDocument(undefined);
                      clearFilePickerDocuments();
                    })
                    .catch((er) => console.log(er));
                }}
              >
                Delete
              </Button>
              <Button
                variant="solid"
                onClick={async () => {
                  if (!selectedDocument) return;
                  if (selectedDocument.id === 0) {
                    await createProfessionalResourceFile(user as UserAuth, user.uid, selectedDocument, plainFiles[0])
                      .then((res) => res.json())
                      .then((result) => {
                        documents.push(result);
                        setDocuments(documents);
                        setSelectedDocument(undefined);
                        clearFilePickerDocuments();
                      })
                      .catch((er) => console.log(er));
                  } else {
                    await updateProfessionalResourceFile(user as UserAuth, user.uid, selectedDocument, plainFiles[0])
                      .then((res) => res.json())
                      .then((result) => {
                        const clone: ProfessionalResourceFile[] = structuredClone(documents);
                        const indx = clone.findIndex((f) => f.id === selectedDocument.id);
                        clone[indx] = result;
                        setDocuments(clone);
                        setSelectedDocument(undefined);
                        clearFilePickerDocuments();
                      })
                      .catch((er) => console.log(er));
                  }
                }}
              >
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  };

  return (
    <Flex flex={1} m={8} p={8} flexDir="column">
      <Flex justifyContent="space-between" mb={8} alignItems="center">
        <Text variant="header" color="charcoal" fontWeight="bold" mr={6}>
          Resources
        </Text>
      </Flex>
      <Flex mx={2}>
        <Flex flex={1} flexDir="column" mr={8}>
          <Flex alignItems="center">
            <Text fontWeight="bold" fontSize="2xl" mr={4}>
              Repository
            </Text>
            <AddIcon
              onClick={() =>
                setRepositoryToModify({
                  id: 0,
                  userid: currentProfessional.userid,
                  title: '',
                  fields: [],
                  instances: [],
                  isdeleted: false,
                })
              }
            />
          </Flex>
          <Flex>{renderRepositoryItems()}</Flex>
        </Flex>
        <Flex flex={1} flexDir="column" ml={8}>
          <Flex alignItems="center">
            <Text fontWeight="bold" fontSize="2xl" mr={4}>
              Notes
            </Text>
            <AddIcon
              onClick={() =>
                setSelectedNote({
                  id: 0,
                  userid: currentProfessional.userid,
                  title: '',
                  content: '',
                } as ProfessionalResourceNote)
              }
            />
          </Flex>
          <Flex>{renderNotes()}</Flex>
        </Flex>
      </Flex>
      <Flex mt={8} mx={2} flex={1}>
        <Flex flex={1} flexDir="column" mr={8}>
          <Flex alignItems="center">
            <Text fontWeight="bold" fontSize="2xl" mr={1}>
              Favorites
            </Text>
            <Tooltip label="Add your favorite style tips, cheat sheets, past lookbooks, referral list, tax documentation" fontSize="xs">
              <InfoIcon />
            </Tooltip>
            <AddIcon ml={4} onClick={() => setSelectedFavorite({ id: 0, userid: currentProfessional.userid, type: 'favorite' })} />
          </Flex>
          <Flex mt={2}>{renderFavorites()}</Flex>
        </Flex>
        <Flex flex={1} flexDir="column" ml={8}>
          <Flex alignItems="center">
            <Text fontWeight="bold" fontSize="2xl" mr={1}>
              Documents
            </Text>
            <AddIcon ml={4} onClick={() => setSelectedDocument({ id: 0, userid: currentProfessional.userid, type: 'document' })} />
          </Flex>
          <Flex mt={2}>{renderDocuments()}</Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default ResourcesScreen;
