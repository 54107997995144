import { User as UserAuth } from 'firebase/auth';
import fetchWrapper from './fetchWrapper';
import { INVOICE_API } from '../config/ServiceConstants';
import { Invoice } from '../models/Invoice';

export function fetchInvoice(userAuth: UserAuth, id: number): Promise<any> {
  const url = `${INVOICE_API}/${id}`;
  return fetchWrapper(userAuth).get(url);
}

export function fetchInvoicesForProfessional(userAuth: UserAuth, prouserid: string): Promise<any> {
  const url = `${INVOICE_API}/professional/${prouserid}`;
  return fetchWrapper(userAuth).get(url);
}

export function fetchInvoicesForProfessionalForClient(userAuth: UserAuth, prouserid: string, clientuserid: string): Promise<any> {
  const url = `${INVOICE_API}?prouserid=${prouserid}&clientuserid=${clientuserid}`;
  return fetchWrapper(userAuth).get(url);
}

export function createInvoice(userAuth: UserAuth, item: Invoice): Promise<any> {
  const url = `${INVOICE_API}`;
  return fetchWrapper(userAuth).post(url, item);
}

export function updateInvoice(userAuth: UserAuth, item: Invoice): Promise<any> {
  const url = `${INVOICE_API}/${item.id}`;
  return fetchWrapper(userAuth).put(url, item);
}

export function submitInvoiceToClient(userAuth: UserAuth, id: number): Promise<any> {
  const url = `${INVOICE_API}/${id}/submit`;
  return fetchWrapper(userAuth).patch(url);
}
