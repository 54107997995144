/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { Text, Heading, HStack, Flex, Image, Button, Stat, Spinner, Center, StatLabel, StatNumber, Avatar, Box, VStack } from '@chakra-ui/react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { LineChart, Legend, DonutChart, BarChart, Subtitle, Title } from '@tremor/react';
import { User as UserAuth } from 'firebase/auth';
import { createColumnHelper } from '@tanstack/react-table';
import { User } from '../models/User';
import { auth } from '../config/firebase';
import { CurrentUserContext } from '../contexts/CurrentUserProvider';
import { CurrentProfessionalContext } from '../contexts/CurrentProfessionalProvider';
import { ProfessionalUser } from '../models/ProfessionalUser';
import { fetchProfessionalDashboard } from '../services/ProfessionalsService';
import { ProfessionalDashboardModel, ServiceRevenueInSeason } from '../models/ProfessionalDashboard';
import LoadingScreen from '../components/LoadingScreen';
import { PageTabs } from '../components/PageTabs';
import { userTableCellDisplayData, DataTable } from '../components/DataTable';
import CustomDatePickerInput from '../components/CustomDatePickerInput';
import { EXPERT_SERVICE_OFFERINGS } from '../models/ExpertServiceOfferings';
import colors from '../theme/foundations/colors';

function EmptyDataState() {
  return (
    <Flex flexDir="column" alignItems="center" p={8}>
      <Image h={24} w={24} src="/images/nodata.png" />
      <Text mt={2}>Not enough data.</Text>
      <Text>Continue booking services to see trends.</Text>
    </Flex>
  );
}

function DashboardScreen() {
  const [user] = useAuthState(auth);
  const [selectedTab, setSelectedTab] = useState('Insights');
  const [selectedEarningsTab, setSelectedEarningsTab] = useState('Total Revenue');
  const { currentProfessional } = useContext(CurrentProfessionalContext);
  const [dateRangeFrom, setDateRangeFrom] = useState<Date | null>();
  const [dateRangeTo, setDateRangeTo] = useState<Date | null>();
  const [loading, setLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState<ProfessionalDashboardModel>();

  useEffect(() => {
    if (!user) return;
    if (!currentProfessional) return;
    if (loading) return;
    const fetch = async () => {
      console.log('fetching for: ', dateRangeFrom, ' - ', dateRangeTo);
      setLoading(true);
      await fetchProfessionalDashboard(user, currentProfessional.userid, dateRangeFrom as Date | undefined, dateRangeTo as Date | undefined)
        .then((res) => res.json())
        .then((result: ProfessionalDashboardModel) => {
          setDashboardData(result);
          console.log(result);
          setLoading(false);
        });
    };
    fetch().catch(console.error);
  }, [currentProfessional, user, dateRangeFrom, dateRangeTo]);

  if (loading) return <LoadingScreen />;
  if (!dashboardData) return <EmptyDataState />;

  const moneyDataFormatter = (number: number) => `$ ${Intl.NumberFormat('us').format(number).toString()}`;

  const formattedTopClientData = !dashboardData?.topClients
    ? []
    : dashboardData.topClients.map((s) => ({
        name: s.name,
        Revenue: s.value,
      }));

  const formattedAvgBilledHoursData = !dashboardData?.averageBilledHoursByClient
    ? []
    : dashboardData.averageBilledHoursByClient.map((s) => ({
        name: s.name,
        'Billed Hours': s.value,
      }));

  const formattedRevenueByServiceData = !dashboardData?.revenueByServiceType
    ? []
    : dashboardData.revenueByServiceType.map((s) => ({
        name: s.name,
        portion: s.value,
      }));

  const formattedRevenueByVirtualVsInPerson = !dashboardData?.revenueByVirtualVsInPerson
    ? []
    : dashboardData.revenueByVirtualVsInPerson.map((s) => ({
        name: s.name,
        portion: s.value,
      }));

  const getSeasonsData = () => {
    if (!dashboardData) return undefined;
    if (!dashboardData.revenueByServicesBySeason) return undefined;
    if (dashboardData.revenueByServicesBySeason.length < 1) return undefined;

    const formattedDataRows: any[] = [];
    dashboardData.revenueByServicesBySeason.forEach((s) => {
      const datarow = {
        season: s.seasonname,
      };
      EXPERT_SERVICE_OFFERINGS.filter((eso) => eso.value !== 'All').forEach((so) => {
        Object.defineProperty(datarow, `${so.value}`, {
          value: s.servicerevenue.filter((v: ServiceRevenueInSeason) => v.service === so.value)[0]?.revenue ?? 0,
        });
      });
      formattedDataRows.push(datarow);
    });

    return formattedDataRows;
  };
  // TODO: move this out of a function like the others, then memoize
  const formattedRevenueByServiceBySeason = getSeasonsData();

  const renderDateRangeSelectors = () => (
    <>
      <Flex flexDir="column" flex={1}>
        <Text fontSize="sm">From</Text>
        <DatePicker
          customInput={<CustomDatePickerInput />}
          selectsStart
          selected={dateRangeFrom}
          isClearable
          startDate={dateRangeFrom}
          endDate={dateRangeTo}
          onChange={(date) => setDateRangeFrom(date)}
        />
      </Flex>
      <Box w={2} />
      <Flex flexDir="column" flex={1}>
        <Text fontSize="sm">To</Text>
        <DatePicker
          customInput={<CustomDatePickerInput />}
          selectsEnd
          selected={dateRangeTo}
          isClearable
          startDate={dateRangeFrom}
          endDate={dateRangeTo}
          minDate={dateRangeFrom}
          onChange={(date) => setDateRangeTo(date)}
        />
      </Flex>
    </>
  );

  const renderHypeMetrics = () => (
    <Flex flex={1} alignItems="center" mb={12}>
      <Flex flexDir="column" mr={8}>
        <Stat borderWidth={1} p={4}>
          <Flex flexDirection="column" justifyContent="center" alignItems="center">
            <StatLabel color="charcoal" fontWeight="bold">
              Total Revenue to Date
            </StatLabel>
            <StatNumber>{moneyDataFormatter(dashboardData.totalRevenueToDate)}</StatNumber>
            {/* <StatHelpText>Feb 12 - Feb 28</StatHelpText> */}
          </Flex>
        </Stat>
      </Flex>
      <Flex flexDir="column" mr={8}>
        <Stat borderWidth={1} p={4}>
          <Flex flexDirection="column" justifyContent="center" alignItems="center">
            <StatLabel color="charcoal" fontWeight="bold">
              Total Outfits Created
            </StatLabel>
            <StatNumber>{dashboardData.totalOutfitsCreated}</StatNumber>
          </Flex>
        </Stat>
      </Flex>
      <Flex flexDir="column" mr={8}>
        <Stat borderWidth={1} p={4}>
          <Flex flexDirection="column" justifyContent="center" alignItems="center">
            <StatLabel color="charcoal" fontWeight="bold">
              Total Number of Clients
            </StatLabel>
            <StatNumber>{dashboardData.totalNumberOfClients}</StatNumber>
          </Flex>
        </Stat>
      </Flex>
      <Flex flexDir="column" mr={8}>
        <Stat borderWidth={1} p={4}>
          <Flex flexDirection="column" justifyContent="center" alignItems="center">
            <StatLabel color="charcoal" fontWeight="bold">
              Repeat Client Rate
            </StatLabel>
            <StatNumber>{Math.round(dashboardData.clientRepeatRate * 100).toFixed(2)}%</StatNumber>
          </Flex>
        </Stat>
      </Flex>
    </Flex>
  );

  const renderInsights = () => (
    <Flex flexDir="column" flex={1}>
      <Flex flex={1} width="90%">
        {renderHypeMetrics()}
        <Flex flex={0.35}>{renderDateRangeSelectors()}</Flex>
      </Flex>
      <Flex flex={1} flexDir="column" width="80%" borderWidth={1} borderRadius="lg" p={4} mb={6}>
        <Text color="orange.400" fontWeight="bold" fontSize="lg">
          Top Clients ($)
        </Text>
        {(!formattedTopClientData || formattedTopClientData.length === 0) && <EmptyDataState />}
        {formattedTopClientData && formattedTopClientData.length > 0 && (
          <BarChart
            data={formattedTopClientData}
            dataKey="name"
            categories={['Revenue']}
            colors={['sky']}
            showAnimation
            showTooltip
            valueFormatter={moneyDataFormatter}
            yAxisWidth="w-12"
          />
        )}
      </Flex>
      <Flex flex={1} flexDir="column" width="80%" borderWidth={1} borderRadius="lg" p={4} mb={6}>
        <Text color="orange.400" fontWeight="bold" fontSize="lg">
          Average Billed Hours with Client (Hours)
        </Text>
        {(!formattedAvgBilledHoursData || formattedAvgBilledHoursData.length === 0) && <EmptyDataState />}
        {formattedAvgBilledHoursData && formattedAvgBilledHoursData.length > 0 && (
          <BarChart
            data={formattedAvgBilledHoursData}
            dataKey="name"
            categories={['Billed Hours']}
            colors={['sky']}
            showAnimation
            // valueFormatter={moneyDataFormatter}
            yAxisWidth="w-12"
          />
        )}
      </Flex>
      <Flex flex={1} flexDir="column" width="80%" borderWidth={1} borderRadius="lg" p={4} mb={6}>
        <Text color="orange.400" fontWeight="bold" fontSize="lg">
          % Revenue Earned by Service
        </Text>
        {(!formattedRevenueByServiceData || formattedRevenueByServiceData.length === 0) && <EmptyDataState />}
        {formattedRevenueByServiceData && formattedRevenueByServiceData.length > 0 && (
          <DonutChart
            data={formattedRevenueByServiceData}
            variant="pie"
            showLabel={false}
            showAnimation
            category="portion"
            dataKey="name"
            showTooltip
            // valueFormatter={valueFormatter}
            // marginTop="mt-6"
            colors={['slate', 'violet', 'indigo', 'rose', 'cyan', 'amber', 'emerald', 'lime']}
          />
        )}
        <Center>
          <Legend
            categories={dashboardData.revenueByServiceType ? dashboardData.revenueByServiceType.map((s) => s.name) : []}
            colors={['yellow', 'sky', 'indigo', 'orange', 'slate', 'amber', 'emerald', 'lime']}
            marginTop="mt-3"
          />
        </Center>
      </Flex>
      <Flex flex={1} flexDir="column" width="80%" borderWidth={1} borderRadius="lg" p={4} mb={6}>
        <Text color="orange.400" fontWeight="bold" fontSize="lg">
          % Revenue By Virtual vs. In Person
        </Text>
        {(!formattedRevenueByVirtualVsInPerson || formattedRevenueByVirtualVsInPerson.length === 0) && <EmptyDataState />}
        {formattedRevenueByVirtualVsInPerson && formattedRevenueByVirtualVsInPerson.length > 0 && (
          <DonutChart
            data={formattedRevenueByVirtualVsInPerson}
            variant="pie"
            showLabel={false}
            showAnimation
            category="portion"
            dataKey="name"
            showTooltip
            colors={['orange', 'sky', 'indigo', 'orange', 'slate', 'amber', 'emerald', 'lime']}
          />
        )}
        <Center>
          <Legend
            categories={dashboardData.revenueByVirtualVsInPerson ? dashboardData.revenueByVirtualVsInPerson.map((s) => s.name) : []}
            colors={['orange', 'sky', 'indigo', 'orange', 'slate', 'amber', 'emerald', 'lime']}
            marginTop="mt-3"
          />
        </Center>
      </Flex>
      <Flex flex={1} flexDir="column" width="80%" borderWidth={1} borderRadius="lg" p={4} mb={6}>
        <Text color="orange.400" fontWeight="bold" fontSize="lg">
          % Revenue By Season
        </Text>
        {(!formattedRevenueByServiceBySeason || formattedRevenueByServiceBySeason.length === 0) && <EmptyDataState />}
        {formattedRevenueByServiceBySeason && formattedRevenueByServiceBySeason.length > 0 && (
          <LineChart
            data={formattedRevenueByServiceBySeason}
            dataKey="season"
            showLegend
            categories={EXPERT_SERVICE_OFFERINGS.filter((eso) => eso.value !== 'All').map((so) => so.value)}
            colors={['yellow', 'sky', 'indigo', 'orange', 'slate', 'amber', 'emerald', 'lime']}
            marginTop="mt-3"
          />
        )}
      </Flex>
    </Flex>
  );

  const renderTotalRevenueTab = () => {
    if (!dashboardData.totalRevenueByMonth) return <Box />;
    type requestDisplayData = {
      month: string;
      revenue: number;
      servicecount: number;
      clientcount: number;
    };

    const tabledata: requestDisplayData[] = dashboardData.totalRevenueByMonth.map((er) => ({
      month: er.month,
      revenue: er.totalearnings,
      clientcount: er.clientcount,
      servicecount: er.servicecount,
      date: er.date,
    }));

    const chartdata = dashboardData.totalRevenueByMonth
      .sort((a, b) => (b.date > a.date ? -1 : 1))
      .map((er) => ({
        month: er.month,
        revenue: er.totalearnings,
      }));

    const columnHelper = createColumnHelper<requestDisplayData>();

    const columns = [
      columnHelper.accessor('month', {
        cell: (info: any) => info.getValue(),
        header: 'Month',
      }),
      columnHelper.accessor('revenue', {
        cell: (info: any) => `$${info.getValue()}`,
        header: 'Revenue',
        meta: {
          isNumeric: true,
        },
      }),
      columnHelper.accessor('clientcount', {
        cell: (info: any) => `${info.getValue()}`,
        header: 'Clients',
        meta: {
          isNumeric: true,
        },
      }),
      columnHelper.accessor('servicecount', {
        cell: (info: any) => `${info.getValue()}`,
        header: 'Services',
        meta: {
          isNumeric: true,
        },
      }),
    ];

    return (
      <Flex flexDir="column">
        <Flex flex={1} flexDir="column" width="100%" borderWidth={1} borderRadius="lg" p={4} mb={6}>
          <Text color="orange.400" fontWeight="bold" fontSize="lg">
            Total Revenue
          </Text>
          <LineChart
            // className="mt-6"
            data={chartdata}
            dataKey="month"
            // index="name"
            categories={['revenue']}
            colors={['orange']}
            showAnimation
            showLegend
            // valueFormatter={dataFormatter}
            // yAxisWidth={40}
          />
        </Flex>
        <DataTable columns={columns} data={tabledata} shadeAlternatingRows={false} handleRowSelected={() => console.log('clicked')} />
      </Flex>
    );
  };

  const renderEarningsByClientTab = () => {
    type requestDisplayData = {
      client: userTableCellDisplayData;
      totalearnings: number;
      billedhours: number;
      services: string[];
      userid: string;
    };

    const tabledata: requestDisplayData[] = dashboardData.clientEarningsDetails.map((er) => ({
      client: {
        name: er.clientusername,
        avatarurl: er.clientavatarurl,
      },
      totalearnings: er.totalearnings,
      billedhours: er.billedhours,
      services: er.services,
      userid: er.clientuserid,
    }));

    const columnHelper = createColumnHelper<requestDisplayData>();

    const columns = [
      columnHelper.accessor('client', {
        // TODO: extract this to a function
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info: any) => (
          <HStack>
            <Avatar src={info.getValue().avatarurl} size="sm" />
            <Text>{info.getValue().name}</Text>
          </HStack>
        ),
        header: 'Client',
      }),
      columnHelper.accessor('totalearnings', {
        cell: (info: any) => `$${info.getValue()}`,
        header: 'Total Earnings',
        meta: {
          isNumeric: true,
        },
      }),
      columnHelper.accessor('billedhours', {
        cell: (info: any) => `${info.getValue()} hours`,
        header: 'Billed Hours',
      }),
      columnHelper.accessor('services', {
        cell: (info: any) => info.getValue().join(', \n'),
        header: 'Services Requested ',
      }),
    ];

    return (
      <Flex flex={1}>
        <DataTable columns={columns} data={tabledata} shadeAlternatingRows={false} handleRowSelected={() => console.log('clicked')} />
      </Flex>
    );
  };

  const renderEarningsByServiceTab = () => {
    if (!dashboardData.serviceEarningsByTypeDetails) return <Box />;
    type requestDisplayData = {
      service: string;
      earnings: number;
      clientcount: number;
    };

    const tabledata: requestDisplayData[] = dashboardData.serviceEarningsByTypeDetails.map((er) => ({
      service: er.servicename,
      clientcount: er.clientcount,
      earnings: er.earnings,
    }));

    const columnHelper = createColumnHelper<requestDisplayData>();

    const columns = [
      columnHelper.accessor('service', {
        cell: (info: any) => info.getValue(),
        header: 'Service',
      }),
      columnHelper.accessor('earnings', {
        cell: (info: any) => `$${info.getValue()}`,
        header: 'Earnings',
        meta: {
          isNumeric: true,
        },
      }),
      columnHelper.accessor('clientcount', {
        cell: (info: any) => `${info.getValue()} clients`,
        header: 'Service History',
      }),
    ];

    return <DataTable columns={columns} data={tabledata} shadeAlternatingRows={false} handleRowSelected={() => console.log('clicked')} />;
  };

  const renderEarnings = () => (
    <Flex flexDir="column" flex={1}>
      <Text variant="header" color="charcoal" fontWeight="bold" mr={6} mb={6}>
        Earnings
      </Text>
      <Flex mb={6} justifyContent="space-between" alignItems="center">
        <Flex flex={3}>
          <Button variant={selectedEarningsTab === 'Total Revenue' ? 'solid' : 'darkoutline'} onClick={() => setSelectedEarningsTab('Total Revenue')} mr={4}>
            Total Revenue
          </Button>
          <Button
            variant={selectedEarningsTab === 'Earnings By Client' ? 'solid' : 'darkoutline'}
            onClick={() => setSelectedEarningsTab('Earnings By Client')}
            mr={4}
          >
            Earnings By Client
          </Button>
          <Button
            variant={selectedEarningsTab === 'Earnings By Type' ? 'solid' : 'darkoutline'}
            onClick={() => setSelectedEarningsTab('Earnings By Type')}
            mr={4}
          >
            Earnings By Type
          </Button>
        </Flex>
        <Flex flex={1}>{renderDateRangeSelectors()}</Flex>
      </Flex>
      <Flex flexDir="column" flex={1} width="100%">
        {selectedEarningsTab === 'Total Revenue' && renderTotalRevenueTab()}
        {selectedEarningsTab === 'Earnings By Client' && renderEarningsByClientTab()}
        {selectedEarningsTab === 'Earnings By Type' && renderEarningsByServiceTab()}
        {/* {selectedEarningsTab === '% Revenue By Service' && renderEarningsByServiceTab()} */}
      </Flex>
    </Flex>
  );

  return (
    <Flex flexDir="column" flex={1} width="100%" px={12} py={8}>
      <Flex mb={12}>
        <PageTabs tabNames={['Insights', 'Earnings']} selectedTab={selectedTab} handleTabSelected={setSelectedTab} />
      </Flex>
      <Flex>
        {selectedTab === 'Insights' && renderInsights()}
        {selectedTab === 'Earnings' && renderEarnings()}
      </Flex>
    </Flex>
  );
}

export default DashboardScreen;
