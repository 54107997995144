import { User as UserAuth } from 'firebase/auth';
import { WARDROBE_API, WARDROBE_ITEMS_API } from '../config/ServiceConstants';
import loggerService from './LoggerService';
import { WardrobeItemQuery } from '../models/WardrobeItemQuery';
import { WardrobeItem, WardrobeItemUploadDTO } from '../models/WardrobeItem';
import fetchWrapper from './fetchWrapper';

export function searchWardrobeItems(userAuth: UserAuth, wardrobeId: number, query?: WardrobeItemQuery): Promise<any> {
  let url = `${WARDROBE_API}/${wardrobeId}/items/search`;
  console.log('wardrobeitemservice.query: ', query);
  if (query) {
    url += '?';
    if (query.po && query.po > 0) {
      url += `&po=${query.po}`; // page offset, defaults to 0 for first page
    }
    if (query.ps) {
      url += `&ps=${query.ps}`; // page size, e.g. items per page
    }
    if (query.sf) {
      url += `&sf=${query.sf}`; // sort field, e.g. modifieddate
    }
    if (query.sd) {
      url += `&sd=${query.sd}`; // sort direction, e.g. asc or desc
    }
    // if (query.favs) {
    //   url += '&favs=true';
    // }
    if (query.includeArchivedItems) {
      url += '&archived=true';
    }
    if (query.primary && query.primary.length > 0 && query.primary.toLowerCase() !== 'all') {
      url += `&primary=${query.primary}`;
    }
    if (query.subcategory && query.subcategory.length > 0 && query.subcategory.toLowerCase() !== 'all') {
      url += `&subcategory=${query.subcategory}`;
    }
    if (query.brand && query.brand.length > 0) {
      url += `&brand=${query.brand}`;
    }
    if (query.weather && query.weather.length > 0) {
      url += `&weather=${query.weather}`;
    }
    if (query.ownership && query.ownership.length > 0) {
      url += `&ownership=${query.ownership}`;
    }
    if (query.color && query.color.length > 0) {
      url += `&color=${query.color}`;
    }
    if (query.tags && query.tags.length > 0) {
      url += `&tags=${query.tags}`;
    }
    if (query.userid) {
      // this tells the serivce to return the wardrobe items of the specified user
      url += `&userid=${query.userid}`;
    }
    if (query.searchterm) {
      url += `&searchterm=${query.searchterm}`;
    }
  }
  console.log('searching: ', url);
  return fetchWrapper(userAuth).get(url);
}

export function fetchWardrobeItem(userAuth: UserAuth, wardrobeId: number, wardrobeItemId: number): Promise<any> {
  const url = `${WARDROBE_API}/${wardrobeId}/items/${wardrobeItemId}`;
  return fetchWrapper(userAuth).get(url);
}

export function fetchWardrobeItemThumbnails(userAuth: UserAuth, wardrobeId: number): Promise<any> {
  const url = `${WARDROBE_API}/${wardrobeId}/items/firstbycategory`;
  return fetchWrapper(userAuth).get(url);
}

export function updateWardrobeItem(userAuth: UserAuth, wardrobeItem: WardrobeItem): Promise<any> {
  const url = `${WARDROBE_API}/${wardrobeItem.wardrobeid}/items/${wardrobeItem.id}`;
  return fetchWrapper(userAuth).put(url, wardrobeItem);
}

export function createWardrobeItemFromModel(userAuth: UserAuth, wardrobeItem: WardrobeItem): Promise<any> {
  const url = `${WARDROBE_API}/0/items`;
  return fetchWrapper(userAuth).post(url, wardrobeItem);
}

export async function uploadNewWardrobeItem(userAuth: UserAuth, wardrobeId: number, image: File): Promise<any> {
  const data: FormData = new FormData();
  data.append('file', image);
  const token = await userAuth.getIdToken();
  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'File-Name': image.name,
    },
    body: data,
  };
  const url = `${WARDROBE_API}/${wardrobeId}/items/upload`;
  return fetch(url, requestOptions);
}

export async function uploadNewWardrobeItemForClient(userAuth: UserAuth, dto: WardrobeItemUploadDTO): Promise<any> {
  const data: FormData = new FormData();
  data.append('imageFile', dto.image as File);
  data.append('entity', JSON.stringify(dto));
  const token = await userAuth.getIdToken();
  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      // 'Content-Type': 'multipart/form-data',
      'File-Name': dto && dto.image ? dto.image.name : 'temp.jpg',
    },
    body: data,
  };
  const url = `${WARDROBE_API}/0/items/upload/forclient`;
  return fetch(url, requestOptions);
}

export async function uploadTouchupWardrobeItem(userAuth: UserAuth, wardrobeId: number, wardrobeItemId: number, svg: string): Promise<any> {
  const token = await userAuth.getIdToken();
  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'text/plain',
      'Content-Type': 'text/plain',
    },
    body: svg,
  };
  const url = `${WARDROBE_API}/${wardrobeId}/items/${wardrobeItemId}/touchup`;
  return fetch(url, requestOptions);
}

export function ensureWardrobe(userAuth: UserAuth): Promise<any> {
  const url = WARDROBE_API;
  return fetchWrapper(userAuth).get(url);
}

export function deleteWardrobeItem(userAuth: UserAuth, wardrobeItem: WardrobeItem): Promise<any> {
  const url = `${WARDROBE_API}/${wardrobeItem.wardrobeid}/items/${wardrobeItem.id}`;
  return fetchWrapper(userAuth).delete(url);
}

export function fetchWardrobeStats(userAuth: UserAuth, userid: string): Promise<any> {
  const url = `${WARDROBE_API}/${userid}/stats`;
  return fetchWrapper(userAuth).get(url);
}

export function fetchWardrobeSubcategories(userAuth: UserAuth, userid: string): Promise<any> {
  const url = `${WARDROBE_API}/${userid}/subcategories`;
  return fetchWrapper(userAuth).get(url);
}
