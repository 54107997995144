const colors = {
  primary: {
    50: '#FAF5FF',
    100: '#ECD6F5',
    200: '#D9ADEB',
    400: '#B3CFE9',
    600: '#B8CEE7',
    700: '#611F7A',
  },
  black: {
    200: '#1A1919',
    300: '#262626',
    700: '#1A1A1A',
    800: '#191919',
  },
  charcoal: '#3A3A3A',
  brown: {
    200: '#E7C9B1',
    400: '#9F5A23',
    600: '#4E3018',
  },
  grey: {
    50: '#F5F5F5',
    100: '#C8C8C8',
    200: '#DEDEDE',
    300: '#d3d3d3',
    400: '#999999',
    500: '#737373',
    600: '#3D3D3D',
    700: '#434343',
  },
  green: {
    200: '#DAF1E9',
    400: '#6AC8A9',
    700: '#1B4B3B',
  },
  olive: {
    200: '#DFDBB9',
    400: '#847E30',
    600: '#464220',
  },
  pink: {
    200: '#F5D6E2',
    400: '#F6A9C6',
    600: '#',
  },
  purple: {
    50: '#FAF5FF',
    100: '#ECD6F5',
    200: '#D9ADEB',
    // 500: '#BB6BD9',
    600: '#BB6BD9',
    700: '#611F7A',
  },
  white: '#fff',
  blue: {
    200: '#D9E5F2',
    400: '#B3CFE9',
    600: '#274A72',
  },
  yellow: {
    200: '#FFF8E6',
    400: '#F5D03A',
    700: '#644B02',
  },
  orange: {
    200: '#F1B9A7',
    400: '#EE5523',
    600: '#AF411D',
  },
  darkText: '#1A1A1A',
  highlight: {
    green: '#D5D86B',
    green2: '#6AC8A9',
    purple: '#BB6BD9',
    orange: '#D25A4B',
    red: '#CD3A3F',
  },
};

export default colors;
