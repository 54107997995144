export interface LookBookQuery {
  /**
   * A string that identifies the User for who to get the lookbooks.
   */
  userid: string;
  /**
   * sort field
   */
  sf?: string;
  /**
   * sort direction (ASC or DESC)
   */
  sd?: string;
  /**
   * page size
   */
  ps?: number;
  /**
   * page offset
   */
  po?: number;
  /**
   * value to search for
   */
  searchterm?: string;
  ownership?: string;
  tags?: string[];
}

export const defaultLookBookQuery = {
  po: 1,
  ps: 40,
  sf: 'createddate',
  sd: 'desc',
  userid: '', // this tells the service to return items for the client
  ownership: 'all',
  searchterm: undefined,
};
