/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import {
  Link,
  Center,
  Heading,
  FormControl,
  Input,
  Flex,
  InputGroup,
  Text,
  Button,
  Box,
  Divider,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import { ViewIcon } from '@chakra-ui/icons';
import { auth, logInWithEmailAndPassword, signInWithGoogle, signInWithApple, signInWithFacebook, sendPasswordReset } from '../config/firebase';

function LoginScreen() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [pwVisibility, setPwVisibility] = useState(false);
  const [showPwRecover, setShowPwRecover] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [user, loading, error] = useAuthState(auth);
  const [loginError, setLoginError] = useState('');
  const toast = useToast();
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) {
      console.log(user);
      // navigate('/home');
    }
  }, [user, loading]);

  // const handlePasswordVisibility = () => {
  // if (rightIcon === 'visibility') {
  //   setRightIcon('visibility-off');
  //   setPwVisibility(!pwVisibility);
  // } else if (rightIcon === 'visibility-off') {
  //   setRightIcon('visibility');
  //   setPwVisibility(!pwVisibility);
  // }
  // };

  return (
    <>
      <Flex flexDir="column" justifyContent="center" alignItems="center">
        <Flex bg="blue.400" flex={1} width="100%" justifyContent="center" py={8}>
          <Heading fontSize="5xl" fontFamily="BeniBold" color="orange.400">
            DONNE
          </Heading>
        </Flex>
        <Flex flex={1} mx={[2, 2, 2, 24]} width={['100%', '100%', '80%', '60%']}>
          <Flex flex={1} flexDir="column" borderRadius="lg" borderWidth={1} my={12} width={['100%', '100%', '70%', '60%']}>
            <Flex flex={1} flexDir="column" justifyContent="center" alignItems="center" p={8} mx={[2, 2, 4, 32]}>
              <Heading flex={1} fontWeight="700" fontSize={['2xl', '2xl', '2xl', '3xl']} my={4} letterSpacing={0.6}>
                Log in to Donne
              </Heading>
              <FormControl isRequired isInvalid={invalid}>
                <Input placeholder="Email" autoCapitalize="none" type="email" autoFocus m={2} value={email} onChange={(e: any) => setEmail(e.target.value)} />
                <InputGroup>
                  <Input
                    placeholder="Password"
                    autoCapitalize="none"
                    type={pwVisibility ? 'text' : 'password'}
                    m={2}
                    pr={12}
                    value={password}
                    onChange={(e: any) => setPassword(e.target.value)}
                  />
                  <InputRightElement>
                    <ViewIcon m={0} pt={4} pr={2} h={8} w={8} onClick={() => setPwVisibility(!pwVisibility)} />
                  </InputRightElement>
                </InputGroup>
                {/* <FormControl.ErrorMessage>{loginError}</FormControl.ErrorMessage> */}
              </FormControl>

              <Text
                as="u"
                color="orange.400"
                onClick={() => {
                  setShowPwRecover(true);
                  toast({
                    title: 'Reset requested',
                    description: 'Please check your email for instructions',
                    status: 'info',
                    duration: 5000,
                    isClosable: true,
                  });
                }}
              >
                Forgot Password?
              </Text>

              <Button bg="blue.400" onClick={() => logInWithEmailAndPassword(email, password)} mt="4">
                Continue with email
              </Button>

              <Flex flex={1} width="100%" my={2} flexDir="row" justifyContent="center" alignItems="center">
                <Divider bg="charcoal" width={36} borderWidth={1} />
                <Text as="b" my={2} mx={4}>
                  Or
                </Text>
                <Divider bg="charcoal" width={36} borderWidth={1} />
              </Flex>

              <Flex flexDir="column" flex={1} width="100%">
                <Button variant="outline" my={2} onClick={() => signInWithApple()}>
                  Sign in with Apple
                </Button>
                <Button variant="outline" my={2} onClick={() => signInWithGoogle()}>
                  Sign in with Google
                </Button>
                <Button variant="outline" my={2} onClick={() => signInWithFacebook()}>
                  Sign in with Facebook
                </Button>
              </Flex>

              <Divider mt={6} mb={4} bg="muted.400" borderWidth={1} />

              <Center>
                <Text fontWeight="bold" color="orange.400" fontSize="lg">
                  Want to be a Donne?
                </Text>
              </Center>

              <Button variant="outline" mt={2} onClick={() => navigate('/application')}>
                Apply to join
              </Button>
            </Flex>
            {message.length > 0 && <Text>{message}</Text>}
          </Flex>
        </Flex>
      </Flex>
      <Modal size="sm" isCentered isOpen={showPwRecover} onClose={() => setShowPwRecover(false)}>
        <ModalOverlay />
        <ModalContent borderRadius="lg">
          <ModalHeader>Forgot Password?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex flexDir="column" justifyContent="center" m={2}>
              <Text fontSize="sm">Email:</Text>
              <Input onChange={(e) => setEmail(e.target.value)} />
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button variant="outline" mr={4} onClick={() => setShowPwRecover(false)}>
              Cancel
            </Button>
            <Button variant="solid" onClick={() => sendPasswordReset(email)}>
              Reset
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default LoginScreen;
