import { CalendarIcon } from '@chakra-ui/icons';
import { Button } from '@chakra-ui/react';
import React from 'react';

const CustomDatePickerInput = React.forwardRef(({ value, onClick }: any, ref: any) => (
  <Button borderRadius="md" bg="white" borderWidth={1} borderColor="grey.400" color="grey.700" px={2} m={0} size="sm" maxH={6} onClick={onClick} ref={ref}>
    {value || <CalendarIcon mr={1} />}
  </Button>
));

export default CustomDatePickerInput;
