import { defineStyleConfig } from '@chakra-ui/react';

const Toast = defineStyleConfig({
  // The styles all button have in common
  baseStyle: {
    backgroundColor: 'blue.400',
  },
});

export default Toast;
