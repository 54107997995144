// TODO: enum
// eslint-disable-next-line import/prefer-default-export
export const EXPERT_SERVICE_OFFERINGS = [
  {
    thumbnailUri: 'https://storage.googleapis.com/catovera_app_assets/all_services_icon.png', // TODO: use a custom fallback image
    selectedUri: 'https://storage.googleapis.com/catovera_app_assets/all_services_icon_selected.png',
    title: 'All Services',
    value: 'All',
    defaultDescription: 'All',
  },
  {
    thumbnailUri: 'https://storage.googleapis.com/catovera_app_assets/closet_organization_icon.png', // TODO: use a custom fallback image
    selectedUri: 'https://storage.googleapis.com/catovera_app_assets/closet_organization_icon_selected.png',
    title: 'Closet Organization',
    value: 'Closet Organization',
    defaultDescription: "Expert organizes user's entire closet/wardobe",
  },
  {
    thumbnailUri: 'https://storage.googleapis.com/catovera_app_assets/wardrobe_digitization_icon.png',
    selectedUri: 'https://storage.googleapis.com/catovera_app_assets/wardrobe_digitization_icon_selected.png',
    title: 'Wardrobe Digitization',
    value: 'Wardrobe Digitization',
    defaultDescription: "Expert or digitzer hired to take and upload photos of user's wardobe",
  },
  {
    thumbnailUri: 'https://storage.googleapis.com/catovera_app_assets/wardrobe_donate_icon.png',
    selectedUri: 'https://storage.googleapis.com/catovera_app_assets/wardrobe_donate_icon_selected.png',
    title: 'Wardrobe Donate',
    value: 'Wardrobe Donate',
    defaultDescription: 'Expert hired to help user clean out / donate wardobe',
  },
  {
    thumbnailUri: 'https://storage.googleapis.com/catovera_app_assets/wardrobe_sell_icon.png',
    selectedUri: 'https://storage.googleapis.com/catovera_app_assets/wardrobe_sell_icon_selected.png',
    title: 'Wardrobe Sell',
    value: 'Wardrobe Sell',
    defaultDescription: 'Expert hired to help user list and sell unwanted wardobe items',
  },
  {
    thumbnailUri: 'https://storage.googleapis.com/catovera_app_assets/digital_wardrobe_styling_icon.png',
    selectedUri: 'https://storage.googleapis.com/catovera_app_assets/digital_wardrobe_styling_icon_selected.png',
    title: 'Digital Wardrobe Styling',
    value: 'Digital Wardrobe Styling',
    defaultDescription: "Virtual styling through Donne app - would include expert helping user style what's already in their digital wardrobe",
  },
  {
    thumbnailUri: 'https://storage.googleapis.com/catovera_app_assets/item_sourcing_icon.png',
    selectedUri: 'https://storage.googleapis.com/catovera_app_assets/item_sourcing_icon_selected.png',
    title: 'Item Sourcing',
    value: 'Item Sourcing',
    defaultDescription: 'Personal shopping for new wardrobe items (could be virtual or in person)',
  },
  {
    thumbnailUri: 'https://storage.googleapis.com/catovera_app_assets/event_styling_icon.png',
    selectedUri: 'https://storage.googleapis.com/catovera_app_assets/event_styling_icon_selected.png',
    title: 'Event Styling',
    value: 'Event Styling',
    defaultDescription: 'Expert styling user for one or more events',
  },
  {
    thumbnailUri: 'https://storage.googleapis.com/catovera_app_assets/capsule_wardrobe_icon.png',
    selectedUri: 'https://storage.googleapis.com/catovera_app_assets/capsule_wardrobe_icon_selected.png',
    title: 'Capsule Wardrobe',
    value: 'Capsule Wardrobe',
    defaultDescription: 'Expert helping user put together a "capsule" or specific subset of clothing items that complement each other',
  },
  {
    thumbnailUri: 'https://storage.googleapis.com/catovera_app_assets/lookbook_creation_icon.png',
    selectedUri: 'https://storage.googleapis.com/catovera_app_assets/lookbook_creation_icon_selected.png',
    title: 'Lookbook Creation',
    value: 'Lookbook Creation',
    defaultDescription: 'A collection of photos or catalogue of items/outfits. Could be done virtually/ through collections',
  },
  {
    thumbnailUri: 'https://storage.googleapis.com/catovera_app_assets/style_coaching_icon.png',
    selectedUri: 'https://storage.googleapis.com/catovera_app_assets/style_coaching_icon_selected.png',
    title: 'Style Coaching',
    value: 'Style Coaching',
    defaultDescription: 'Helping user refine or shape their style',
  },
];

export const SERVICE_PRICING_OPTIONS = [
  {
    name: 'per hour',
    value: 'hour',
  },
  {
    name: 'per day',
    value: 'day',
  },
  {
    name: 'flat fee',
    value: 'flat',
  },
  {
    name: 'quote',
    value: 'quote',
  },
  {
    name: 'percent',
    value: 'percent',
  },
];
