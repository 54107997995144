import { Heading } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { logout } from '../config/firebase';

function Logout() {
  useEffect(() => {
    logout();
  }, []);
  return <Heading>Thank you!</Heading>;
}

export default Logout;
