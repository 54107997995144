// import { UserAuth } from '../models/UserAuth';
import { User as UserAuth } from 'firebase/auth';
import loggerService from './LoggerService';

export default function fetchWrapper(userAuth: UserAuth) {
  function request(method: any) {
    return async (url: string, body: any = null) => {
      const token = await userAuth?.getIdToken(); // with this approach the api handles refreshing the token
      const requestOptions: any = {
        method,
        headers: { Authorization: `Bearer ${token}` },
      };
      if (body) {
        requestOptions.headers['Content-Type'] = 'application/json';
        requestOptions.body = JSON.stringify(body);
      }
      return fetch(url, requestOptions);
    };
  }

  return {
    get: request('GET'),
    post: request('POST'),
    patch: request('PATCH'),
    put: request('PUT'),
    delete: request('DELETE'),
  };
}
