/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, chakra, TableRowProps } from '@chakra-ui/react';
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import { useReactTable, flexRender, getCoreRowModel, ColumnDef, SortingState, getSortedRowModel } from '@tanstack/react-table';

export type userTableCellDisplayData = {
  name: string;
  avatarurl: string;
};

// https://chakra-ui.com/getting-started/with-react-table

export type DataTableProps<Data extends object> = {
  data: Data[];
  columns: ColumnDef<Data, any>[];
  shadeAlternatingRows: boolean;
  handleRowSelected?: (index: number) => void;
};

export function DataTable<Data extends object>({ data, columns, shadeAlternatingRows, handleRowSelected }: DataTableProps<Data>) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  return (
    <Table shadow="md" width="100%" flex={1}>
      <Thead>
        {table.getHeaderGroups().map((headerGroup: { id: React.Key | null | undefined; headers: any[] }) => (
          <Tr key={headerGroup.id} bg="grey.200">
            {headerGroup.headers.map((header) => {
              // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
              const { meta } = header.column.columnDef;
              return (
                <Th key={header.id} onClick={header.column.getToggleSortingHandler()} isNumeric={meta?.isNumeric} fontFamily="interregular">
                  {flexRender(header.column.columnDef.header, header.getContext())}

                  <chakra.span pl="4">
                    {header.column.getIsSorted() ? (
                      header.column.getIsSorted() === 'desc' ? (
                        <TriangleDownIcon aria-label="sorted descending" />
                      ) : (
                        <TriangleUpIcon aria-label="sorted ascending" />
                      )
                    ) : null}
                  </chakra.span>
                </Th>
              );
            })}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {table.getRowModel().rows.map((row: any, rowindex: number) => (
          <Tr
            key={row.id}
            bg={shadeAlternatingRows && rowindex % 2 !== 0 ? 'grey.200' : 'white'}
            borderBottomWidth={2}
            // onClick={() => handleRowSelected(row.id)}
            onClick={handleRowSelected ? () => handleRowSelected(row.id) : undefined}
          >
            {row.getVisibleCells().map((cell: any) => {
              // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly:
              const { meta } = cell.column.columnDef;
              return (
                <Td key={cell.id} isNumeric={meta?.isNumeric}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              );
            })}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}
