/* eslint-disable import/prefer-default-export */
import { UserCredential } from '@firebase/auth';
import { User as UserAuth } from 'firebase/auth';
import fetchWrapper from './fetchWrapper';
import { MESSAGES_API, USERS_SERVICE_API } from '../config/ServiceConstants';
import loggerService from './LoggerService';
import { CreateMessageDTO, MessageThread } from '../models/Message';

export function fetchUserMessages(userAuth: UserAuth): Promise<any> {
  const url = MESSAGES_API;
  return fetchWrapper(userAuth).get(url);
}

export function fetchMessage(userAuth: UserAuth, messageid: number): Promise<any> {
  const url = `${MESSAGES_API}/${messageid}`;
  return fetchWrapper(userAuth).get(url);
}

export function createMessage(userAuth: UserAuth, message: CreateMessageDTO): Promise<any> {
  const url = `${MESSAGES_API}`;
  return fetchWrapper(userAuth).post(url, message);
}

export function createMessageThread(userAuth: UserAuth, messageid: number, thread: string): Promise<any> {
  const url = `${MESSAGES_API}/${messageid}/thread`;
  return fetchWrapper(userAuth).post(url, thread);
}

export function putMessageAsRead(userAuth: UserAuth, messageid: number): Promise<any> {
  const url = `${MESSAGES_API}/${messageid}/read`;
  loggerService.debug(url);
  return fetchWrapper(userAuth).put(url);
}
