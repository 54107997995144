/* eslint-disable react/destructuring-assignment */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable dot-notation */
/* eslint-disable react/no-array-index-key */
import {
  Box,
  Text,
  Flex,
  Select,
  Button,
  Textarea,
  Avatar,
  Input,
  Image,
  SimpleGrid,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Link,
  Switch,
  Spinner,
  Icon,
  useToast,
  HStack,
  Breadcrumb,
  BreadcrumbItem,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  DrawerCloseButton,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useFilePicker } from 'use-file-picker';
import { useAuthState } from 'react-firebase-hooks/auth';
import { User as UserAuth } from 'firebase/auth';
import { Rating } from 'react-simple-star-rating';
import { EditIcon, CalendarIcon, WarningTwoIcon, StarIcon } from '@chakra-ui/icons';
import { RiEditCircleFill } from 'react-icons/ri';
import * as FontAwesome from 'react-icons/fa';
import { MultiSelect } from 'chakra-multiselect';
import moment from 'moment';
import { useLocation, useParams } from 'react-router-dom';
import { User } from '../models/User';
import { auth } from '../config/firebase';
import { CurrentUserContext } from '../contexts/CurrentUserProvider';
import { CurrentProfessionalContext } from '../contexts/CurrentProfessionalProvider';
import LoadingScreen from '../components/LoadingScreen';
import { PageTabs } from '../components/PageTabs';
import { PortfolioLink, ProfessionalUser, ServiceDetails } from '../models/ProfessionalUser';
import { EXPERT_SERVICE_OFFERINGS, SERVICE_PRICING_OPTIONS } from '../models/ExpertServiceOfferings';
import { updateUser, uploadUserProfilePic } from '../services/UsersService';
import { updateProfessionalDetails, updateProfessionalDetailsWithFile } from '../services/ProfessionalsService';
import AuthorizedUserMediaAssetDisplay from '../components/AuthorizedUserMediaAssetDisplay';
import UserMediaAssetDisplay from '../components/UserMediaAssetDisplay';
import UserPortfolioDisplay from '../components/UserPortfolioDisplay';
import AddIcon from '../components/AddIcon';
import CustomDatePickerInput from '../components/CustomDatePickerInput';
import { ProfessionalEngagementServiceRecord } from '../models/ProfessionalEngagementServiceRecord';
import { ProfessionalReview } from '../models/ProfessionalReview';
import { STYLES_LIST } from '../models/StylesList';
import loggerService from '../services/LoggerService';
import ProfileActivationControl from '../components/ProfileActivationControl';
import colors from '../theme/foundations/colors';
import { createConnectedUserAccount } from '../services/PaymentsService';
import { ConnectedAccount } from '../models/PaymentsModels';

type EditableProfileAvatarProps = {
  currentAvatarUrl: string;
  handleAvatarChanged: (newvalue: string) => void;
};

function EditableProfileAvatar({ currentAvatarUrl, handleAvatarChanged }: EditableProfileAvatarProps) {
  const { currentUser } = useContext(CurrentUserContext);
  const [user] = useAuthState(auth);
  const [openFileSelector, { filesContent, plainFiles, loading, errors, clear: clearFilePicker }] = useFilePicker({
    readAs: 'DataURL',
    accept: ['image/*', '.png', '.jpg', 'jpeg'],
    multiple: false,
    limitFilesConfig: { max: 1 },
    // minFileSize: 0.1, // in megabytes
    maxFileSize: 20,
    imageSizeRestrictions: {
      maxHeight: 2000, // in pixels
      maxWidth: 2000,
      minHeight: 20,
      minWidth: 20,
    },
  });

  return (
    <>
      <Flex>
        <Avatar src={currentAvatarUrl} size="2xl" mb={4} />
        <Icon as={RiEditCircleFill} boxSize={5} color="orange.400" transform="translateY(96px) translateX(-12px)" onClick={openFileSelector} />
      </Flex>
      <Modal size="sm" isCentered isOpen={plainFiles && plainFiles[0] !== undefined} onClose={clearFilePicker}>
        <ModalOverlay />
        <ModalContent borderRadius="lg">
          <ModalHeader>Upload Avatar</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex flexDir="column" justifyContent="center" alignItems="center">
              <Avatar src={filesContent[0]?.content} size="2xl" mb={4} />
              {errors && errors.length > 0 && <Text>{JSON.stringify(errors)}</Text>}
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="space-between" flex={1}>
              <Button
                variant="darkoutline"
                onClick={() => {
                  clearFilePicker();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="solid"
                onClick={() => {
                  uploadUserProfilePic(user as UserAuth, plainFiles[0])
                    .then((res) => res.json())
                    .then((r) => {
                      handleAvatarChanged(r);
                      clearFilePicker();
                    })
                    .catch((e) => console.log(e));
                }}
              >
                Save
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

type ProfessionalReviewCardProps = {
  review: ProfessionalReview;
};

function ProfessionalReviewCard({ review }: ProfessionalReviewCardProps) {
  return (
    <Flex flexDirection="column" borderWidth={1} borderRadius="md" p={4}>
      <Flex alignItems="center" mb={2}>
        <Avatar src={review.reviewuseravatarurl} size="md" />
        <Flex flexDirection="column" my={1} ml={4} alignItems="flex-start">
          <Flex alignItems="center">
            <Text fontWeight="bold">{review.reviewuserdisplayname}</Text>
            {review.reviewuserlocation && (
              <Text fontSize="xs" ml={2}>
                [{review.reviewuserlocation}]
              </Text>
            )}
          </Flex>
          <Flex alignItems="center">
            <Rating initialValue={review.rating} allowFraction SVGstyle={{ display: 'inline-block' }} size={14} readonly />
            <Text fontSize="xs" ml={1}>
              {moment(review.reviewdate).format('M/D/YY')}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex>
        <Text noOfLines={3}>{review.review}</Text>
      </Flex>
    </Flex>
  );
}

type ProfessionalRatingSummaryProps = {
  rating: number;
  reviews: ProfessionalReview[];
};

function ProfessionalRatingSummary({ rating, reviews }: ProfessionalRatingSummaryProps) {
  const [showReviewDetails, setShowReviewDetails] = useState(false);
  const [sortedReviews, setSortedReviews] = useState(reviews);

  const handleSort = (sortval: string) => {
    const clone = [...sortedReviews];
    const sorted = clone.sort((a, b) => {
      if (a && b && sortval === 'datedesc' && a.reviewdate && b.reviewdate) return b.reviewdate.valueOf() > a.reviewdate.valueOf() ? 1 : -1;
      if (a && b && sortval === 'dateasc' && a.reviewdate && b.reviewdate) return a.reviewdate.valueOf() > b.reviewdate.valueOf() ? 1 : -1;
      if (a && b && sortval === 'ratingdesc') return a.rating < b.rating ? 1 : -1;
      if (a && b && sortval === 'ratingasc') return a.rating > b.rating ? 1 : -1;
      return 0;
    });
    setSortedReviews(sorted);
  };

  return (
    <Flex flexDir="column">
      <Text fontWeight="bold">Average Rating</Text>
      <Flex flexDir="column" justifyContent="center">
        {!rating || (rating === 0.0 && <Text>No Reviews Yet</Text>)}
        <Rating initialValue={rating} allowFraction SVGstyle={{ display: 'inline-block' }} size={20} readonly />
        {reviews && reviews.length > 0 && (
          <Text as="u" onClick={() => setShowReviewDetails(true)}>
            See all Reviews
          </Text>
        )}
      </Flex>
      <Drawer placement="right" size="lg" onClose={() => setShowReviewDetails(false)} isOpen={showReviewDetails}>
        <DrawerOverlay />
        <DrawerContent bg="white">
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            <Text variant="header" color="charcoal">
              See All Reviews
            </Text>
          </DrawerHeader>
          <DrawerBody px={12}>
            <Flex alignItems="center" justifyContent="space-between">
              <Flex flexDir="column" my={2} flex={1}>
                <HStack alignItems="center" my={1}>
                  <StarIcon color="blue.400" boxSize="8" />
                  <Text fontSize="xl" fontWeight="bold" color="blue.400">
                    {rating}
                  </Text>
                </HStack>
                <Text mt={1} color="blue.400">
                  {reviews.length} reviews
                </Text>
              </Flex>
              <Select
                variant="outline"
                placeholder="Sort..."
                flex={1}
                mx={1}
                bg="white"
                color="charcoal"
                borderColor="charcoal"
                fontWeight="bold"
                onChange={(e) => handleSort(e.target.value)}
              >
                <option value="datedesc">Newest</option>
                <option value="dateasc">Oldest</option>
                <option value="ratingdesc">Highest</option>
                <option value="ratingasc">Lowest</option>
              </Select>
            </Flex>
            <Flex flexDir="column">
              {sortedReviews &&
                sortedReviews.map((review) => (
                  <Flex key={review.id.toString()} mb={6}>
                    <ProfessionalReviewCard review={review} />
                  </Flex>
                ))}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}

function AccountScreen() {
  const location = useLocation();
  const { currentUser } = useContext(CurrentUserContext);
  const [user] = useAuthState(auth);
  const [showSpinner, setShowSpinner] = useState(false);
  const { currentProfessional, setCurrentProfessional } = useContext(CurrentProfessionalContext);
  const toast = useToast();
  const [selectedTab, setSelectedTab] = useState(location.state?.tab || 'Personal Info');
  const [showServicesModal, setShowServicesModal] = useState(false);
  const [selectedService, setSelectedService] = useState<ServiceDetails | undefined>();
  const [prouser, setprouser] = useState<ProfessionalUser>(currentProfessional as ProfessionalUser);
  const [userdata, setUserData] = useState<User>(currentUser as User);
  const [selectedSocialIcon, setSelectedSocialIcon] = useState<PortfolioLink | undefined>(undefined);
  const [connectedAccount, setConnectedAccount] = useState<ConnectedAccount | undefined>((currentProfessional as ProfessionalUser).connectedAccount);

  useEffect(() => {
    if (!currentProfessional) return;
    if (prouser) return;
    setprouser(currentProfessional);
  }, [currentProfessional]);

  useEffect(() => {
    if (!currentUser) return;
    if (userdata) return;
    setUserData(currentUser);
  }, [currentUser]);

  const [openFileSelector, { filesContent, plainFiles, loading, errors, clear: clearFilePicker }] = useFilePicker({
    readAs: 'DataURL',
    accept: ['image/*', '.png', '.jpg', 'jpeg'],
    multiple: false,
    limitFilesConfig: { max: 1 },
    // minFileSize: 0.1, // in megabytes
    maxFileSize: 20,
    imageSizeRestrictions: {
      maxHeight: 2000, // in pixels
      maxWidth: 2000,
      minHeight: 20,
      minWidth: 20,
    },
  });

  if (!currentProfessional || !currentUser) return <LoadingScreen />;
  if (!prouser || !userdata) return <LoadingScreen />;
  // const prouser = currentProfessional as ProfessionalUser;

  // This page needs to support editing fields that belong to both the core user entity and the professionaldetails entity.
  // Be careful because the ProfessionalUser model has fields from the User entity that should not be edited from the ProfDetail entity

  const updateUserDataField = (field: string, value: any) => {
    const clone = structuredClone(userdata);
    const pList = field.split('.');
    const key = pList.pop();
    const pointer = pList.reduce((accumulator, currentValue) => {
      if (accumulator[currentValue] === undefined) accumulator[currentValue] = {};
      return accumulator[currentValue];
    }, clone);
    if (key) pointer[key] = value;

    setUserData(clone);
  };

  const handleSavePersonalInfo = async () => {
    updateUser(user as UserAuth, userdata)
      .then((result) => {
        toast({
          title: 'Personal Info Saved',
          description: 'Data saved successfuly',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
      })
      .catch((er) => console.log(er));
  };

  const updateProDataField = (field: string, value: any) => {
    const clone = structuredClone(prouser);
    const pList = field.split('.');
    const key = pList.pop();
    const pointer = pList.reduce((accumulator, currentValue) => {
      if (accumulator[currentValue] === undefined) accumulator[currentValue] = {};
      return accumulator[currentValue];
    }, clone);
    if (key) pointer[key] = value;
    setprouser(clone);
  };

  const updateUserAvatarUrl = (url: string) => {
    updateUserDataField('useravatarurl', url);
  };

  const handleSaveProfessionalDetails = async () => {
    if (plainFiles[0]) {
      updateProfessionalDetailsWithFile(user as UserAuth, prouser as ProfessionalUser, plainFiles[0])
        .then((result) => {
          setprouser(result);
          setCurrentProfessional(result);
        })
        .then((ne) => clearFilePicker())
        .then((re) => {
          toast({
            title: 'Profile Saved',
            description: 'Data saved successfuly',
            status: 'success',
            duration: 4000,
            isClosable: true,
          });
        })
        .catch((err) => console.log(err));
    } else {
      updateProfessionalDetails(user as UserAuth, prouser as ProfessionalUser).then((result) => {
        // console.log('result: ', result);
        // setCurrentProfessional(result);
        if (result.ok) {
          toast({
            title: 'Profile Saved',
            description: 'Data saved successfuly',
            status: 'success',
            duration: 4000,
            isClosable: true,
          });
        }
      });
    }
  };

  const handleCreateConnectedAccount = async () => {
    // https://stripe.com/docs/connect/express-accounts
    // https://stripe.com/docs/connect/collect-then-transfer-guide?platform=react-native&ui=payment-sheet#create-account
    setShowSpinner(true);
    await createConnectedUserAccount(user as UserAuth)
      .then((res: any) => res.json())
      .then((result: ConnectedAccount) => {
        console.log('result: ', result);
        setConnectedAccount(result);
        const clone = structuredClone(prouser);
        clone.connectedAccount = result;
        setprouser(clone);
        setConnectedAccount(result);
      })
      .catch((err) => console.log(err))
      .finally(() => setShowSpinner(false));
  };

  const handleConfigureStripeAccountPressed = () => {
    const clone: ConnectedAccount = structuredClone(connectedAccount);
    clone.status = 'temp'; // 'details_submitted';
    setConnectedAccount(clone);
    console.log('accountLink: ', clone.accountLink);
    // window.location.replace(clone.accountLink);
  };

  const renderPersonalInfoTab = () => {
    if (showSpinner) return <Spinner />;
    return (
      <Flex flex={1} width="100%" p={16}>
        <Flex flex={0.2} flexDir="column" alignItems="center" mx={4}>
          <EditableProfileAvatar currentAvatarUrl={userdata.useravatarurl} handleAvatarChanged={updateUserAvatarUrl} />
          <ProfessionalRatingSummary rating={prouser.rating} reviews={prouser.reviewdetails} />
        </Flex>
        <Flex flex={0.8} flexDir="column" mx={4}>
          <Text fontSize="18" fontWeight="bold" mb={2}>
            Personal Info
          </Text>
          <Flex flexDir="column" flex={1}>
            <Flex flex={1} my={2}>
              <Flex flex={1} flexDir="column" mr={4}>
                <Text fontSize="xs">Email *</Text>
                <Input value={userdata.email} onChange={(e) => updateUserDataField('email', e.target.value)} />
              </Flex>
              <Flex flex={1} flexDir="column" mr={2}>
                <Text fontSize="xs">Phone</Text>
                <Input value={userdata.phone} onChange={(e) => updateUserDataField('phone', e.target.value)} />
              </Flex>
            </Flex>
            <Flex flex={1} my={2}>
              <Flex flex={1} flexDir="column" mr={4}>
                <Text fontSize="xs">Address *</Text>
                <Input
                  name="street-address"
                  autoComplete="street-address"
                  value={userdata.address?.line1}
                  onChange={(e) => updateUserDataField('address.line1', e.target.value)}
                />
              </Flex>
              <Flex flex={1} flexDir="column" mr={2}>
                <Text fontSize="xs">Address Line 2</Text>
                <Input autoComplete="address-line2" value={userdata.address?.line2} onChange={(e) => updateUserDataField('address.line2', e.target.value)} />
                {/* <Input
                autoComplete="address-line2"
                value={userdata.address?.line2}
                onChange={(e) => setUserData({ ...userdata, 'address.line2': e.target.value } as User)}
              /> */}
              </Flex>
            </Flex>
            <Flex flex={1} my={2}>
              <Flex flex={1} flexDir="column" mr={4}>
                <Text fontSize="xs">City *</Text>
                <Input
                  name="city"
                  autoComplete="address-level2"
                  value={userdata.address?.city}
                  onChange={(e) => updateUserDataField('address.city', e.target.value)}
                />
              </Flex>
              <Flex flex={1} flexDir="row" mr={2}>
                <Flex flex={1} flexDir="column" mr={2}>
                  <Text fontSize="xs">State *</Text>
                  <Input
                    autoComplete="address-level1"
                    value={userdata.address?.stateCode}
                    onChange={(e) => updateUserDataField('address.stateCode', e.target.value)}
                  />
                </Flex>
                <Flex flex={1} flexDir="column">
                  <Text fontSize="xs">Zipcode *</Text>
                  <Input
                    name="postal-code"
                    autoComplete="postal-code"
                    value={userdata.address?.zipCode}
                    onChange={(e) => updateUserDataField('address.zipCode', e.target.value)}
                  />
                </Flex>
              </Flex>
            </Flex>
            <Flex flex={1} flexDir="row">
              <Flex flex={1} flexDir="column" mr={4}>
                <Text fontSize="xs">Date of Birth *</Text>
                <Flex flexDir="row" alignItems="center">
                  <CalendarIcon mr={1} />
                  <Flex borderWidth={1} borderRadius="md" flex={1} py={2}>
                    <DatePicker
                      selected={userdata?.birthday ? moment(userdata.birthday, 'YYY-MM-DD').toDate() : null}
                      isClearable
                      onChange={(date) => updateUserDataField('birthday', date)}
                    />
                  </Flex>
                </Flex>
              </Flex>
              <Flex flex={1} flexDir="column">
                <Text fontSize="xs">Pronouns</Text>
                <Input name="pronouns" value={userdata.preferredPronouns} onChange={(e) => updateUserDataField('preferredPronouns', e.target.value)} />
              </Flex>
            </Flex>
            <Flex my={8} flexDir="column">
              <Text fontSize="18" fontWeight="bold" mb={2}>
                Connect / View Payment Account
              </Text>
              <Text>Donne partners with Stripe, an industry leader, to manage payments.</Text>
              {!connectedAccount && (
                <Button variant="primary" maxWidth="50%" px={1} mt={2} onClick={handleCreateConnectedAccount}>
                  Create Stripe Account
                </Button>
              )}
              {connectedAccount && connectedAccount.status === 'pending_onboarding' && connectedAccount.accountLink && (
                <Link
                  maxWidth="50%"
                  color="white"
                  bg="black"
                  px={4}
                  py={1}
                  textAlign="center"
                  borderRadius="xl"
                  href={connectedAccount.accountLink}
                  onClick={handleConfigureStripeAccountPressed}
                >
                  Configure Your Stripe Account
                </Link>
                // <Button variant="primary" maxWidth="50%" px={1} mt={2} onClick={handleConfigureStripeAccountPressed}>
                //   Configure Stripe Account
                // </Button>
              )}
              {connectedAccount && connectedAccount.status === 'details_submitted' && (
                <Link
                  isExternal
                  maxWidth="50%"
                  color="white"
                  bg="black"
                  px={4}
                  py={1}
                  textAlign="center"
                  borderRadius="xl"
                  href="https://connect.stripe.com/express_login"
                >
                  View Your Stripe Account
                </Link>
              )}
              {/* <Text as="i" color="orange.400">
              Coming Soon!
            </Text> */}
            </Flex>
            <Flex mt={16} width="90%" justifyContent="space-between">
              <Button variant="darkoutline" onClick={() => setUserData(currentUser)}>
                Cancel
              </Button>
              <Button variant="solid" onClick={handleSavePersonalInfo}>
                Save Personal Info
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    );
  };

  const renderSocialIconsSection = () => {
    if (!prouser.portfolioData?.portfolioLinks) return <Box />;
    return (
      <>
        <Flex>
          {prouser.portfolioData?.portfolioLinks?.map((item, i) => {
            const iconname = `Fa${item.platform}`;
            let faicon = FontAwesome[iconname as keyof typeof FontAwesome];
            if (!faicon) faicon = FontAwesome.FaGlobe;
            return (
              <Flex key={i.toString()} flexDir="column" mx={4}>
                {faicon &&
                  React.createElement(faicon, {
                    style: {
                      color: colors.charcoal,
                      height: 42,
                      width: 42,
                      background: '#D9D9D9',
                      opacity: 1,
                      borderRadius: '50%',
                      padding: '8px',
                    },
                  })}
                <Icon
                  as={RiEditCircleFill}
                  boxSize={5}
                  color="orange.400"
                  transform="translateY(-12px) translateX(26px)"
                  onClick={() => setSelectedSocialIcon(item)}
                />
              </Flex>
            );
          })}
          <AddIcon h={10} w={10} p={2} onClick={() => setSelectedSocialIcon({ platform: '', url: '' })} />
        </Flex>
        <Modal size="sm" isCentered isOpen={selectedSocialIcon !== undefined} onClose={() => setSelectedSocialIcon(undefined)}>
          <ModalOverlay />
          <ModalContent borderRadius="lg">
            <ModalHeader>Edit Social Account</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex flexDir="column" justifyContent="center" m={2}>
                {/* if editing, don't allow platform change.  If new, let them choose or enter platform */}
                {selectedSocialIcon && !selectedSocialIcon.platform && (
                  <MultiSelect
                    create
                    single
                    options={[
                      { label: 'Instagram', value: 'Instagram' },
                      { label: 'Tiktok', value: 'Tiktok' },
                      { label: 'Facebook', value: 'Facebook' },
                      { label: 'Twitter', value: 'Twitter' },
                      { label: 'Pinterest', value: 'Pinterest' },
                      { label: 'Wix', value: 'Wix' },
                      { label: 'Website', value: 'Website' },
                    ]}
                    value={selectedSocialIcon?.platform || ''}
                    onChange={(e) => setSelectedSocialIcon({ platform: e as string, url: selectedSocialIcon?.url as string })}
                  />
                )}
                {selectedSocialIcon && selectedSocialIcon.platform && selectedSocialIcon.platform.length > 0 && <Text>{selectedSocialIcon.platform}</Text>}
                <Input
                  type="url"
                  my={2}
                  value={selectedSocialIcon?.url}
                  onChange={(e) => setSelectedSocialIcon({ platform: selectedSocialIcon?.platform as string, url: e.target.value })}
                />
              </Flex>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="darkoutline"
                mr={4}
                onClick={() => {
                  setSelectedSocialIcon(undefined);
                  clearFilePicker();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="solid"
                bg="red.400"
                mr={4}
                onClick={() => {
                  const clone: ProfessionalUser = structuredClone(prouser);
                  const existing = clone.portfolioData.portfolioLinks.filter((l) => l.platform === selectedSocialIcon?.platform);
                  if (existing && existing[0]) {
                    clone.portfolioData.portfolioLinks = clone.portfolioData.portfolioLinks.filter((l) => l.platform !== existing[0].platform);
                    setprouser(clone);
                  }
                  setSelectedSocialIcon(undefined);
                  clearFilePicker();
                }}
              >
                Delete
              </Button>
              <Button
                variant="solid"
                onClick={() => {
                  const clone: ProfessionalUser = structuredClone(prouser);
                  const existing = clone.portfolioData.portfolioLinks.filter((l) => l.platform === selectedSocialIcon?.platform);
                  if (existing && existing[0] && selectedSocialIcon) {
                    existing[0].url = selectedSocialIcon.url as string;
                  } else {
                    if (!clone.portfolioData.portfolioLinks) clone.portfolioData.portfolioLinks = [];
                    clone.portfolioData.portfolioLinks.push(selectedSocialIcon as PortfolioLink);
                  }
                  setprouser(clone);
                  setSelectedSocialIcon(undefined);
                  clearFilePicker();
                }}
              >
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  };

  if (errors && errors.length > 0) console.log(errors);

  const renderProfileTab = () => (
    <Flex flex={1} width="100%" p={16}>
      <Flex flex={0.2} flexDir="column" alignItems="center" mx={4}>
        <EditableProfileAvatar currentAvatarUrl={userdata.useravatarurl} handleAvatarChanged={updateUserAvatarUrl} />
        <ProfessionalRatingSummary rating={prouser.rating} reviews={prouser.reviewdetails} />
      </Flex>
      <Flex flex={0.8} flexDir="column" mx={4}>
        <Text fontSize="18" fontWeight="bold" mb={2}>
          Profile
        </Text>
        <Flex flexDir="column" flex={1}>
          <Flex flex={1} my={1}>
            <Flex flex={2} flexDir="column" mr={4}>
              <Text fontSize="xs">Profile Tagline / Title *</Text>
              <Input value={prouser.tagline} onChange={(e) => updateProDataField('tagline', e.target.value)} />
            </Flex>
            <Flex flex={1} flexDir="column" mr={4}>
              <Text fontSize="xs">Years of Experience</Text>
              <Input
                type="number"
                value={prouser.years_of_experience}
                onChange={(e) => updateProDataField('years_of_experience', e.target.valueAsNumber as unknown as number)}
              />
            </Flex>
            <ProfileActivationControl togglevalue={prouser.visible_to_clients} handleOnChange={updateProDataField} userdata={userdata} prouser={prouser} />
          </Flex>
          <Flex flex={1} my={2}>
            <Flex flex={1} flexDir="column" mr={4}>
              <Text fontSize="xs">Languages spoken</Text>
              <Input
                value={prouser.languages.join(', ')}
                onChange={(e) => {
                  let newarray = e.target.value.split(',');
                  newarray = newarray.map((s) => s.trim());
                  updateProDataField('languages', newarray);
                }}
              />
            </Flex>
            <Flex flex={1} flexDir="column" mr={2}>
              <Text fontSize="xs">Styles</Text>
              <MultiSelect
                multiple
                options={STYLES_LIST.map((lbl) => ({ label: lbl, value: lbl }))}
                value={prouser?.styles || undefined}
                onChange={(e) => {
                  updateProDataField('styles', e as string[]);
                }}
              />
            </Flex>
          </Flex>
          <Flex flex={1} my={2}>
            <Flex flex={1} flexDir="column" mr={2}>
              <Text fontSize="xs">Profile Short bio: *</Text>
              <Textarea p={2} maxLength={599} value={prouser.bio} onChange={(e) => updateProDataField('bio', e.target.value)} />
            </Flex>
          </Flex>
          <Flex flex={1} my={4} width="50%">
            <Flex flex={1} flexDir="column" alignItems="flex-start">
              <Flex mb={4} flex={1}>
                <Text fontSize="xs" mr={4}>
                  Public Cover Photo
                </Text>
                <EditIcon color="orange.400" onClick={() => openFileSelector()} />
                {loading && <Spinner />}
                {errors && errors.length > 0 && <Text>Error: file size too large.</Text>}
              </Flex>
              {/* <Image src={filesContent && filesContent[0] ? filesContent[0].content : prouser.profile_background_image} maxHeight={64} fit="contain" /> */}
              <UserMediaAssetDisplay
                uri={filesContent && filesContent[0] ? filesContent[0].content : (prouser.profile_background_image as string)}
                type="image"
                height={64}
                width={64}
                // bg="pink"
              />
            </Flex>
          </Flex>
          <Flex flex={1} my={1} width="50%">
            <Flex flex={1} flexDir="column" alignItems="flex-start">
              <Flex flex={1} justifyContent="space-between" width="80%" mb={4} alignItems="center">
                <Text fontWeight="bold">Social Accounts</Text>
              </Flex>
              {renderSocialIconsSection()}
            </Flex>
          </Flex>
          <Flex mt={16} width="90%" justifyContent="space-between">
            <Button variant="darkoutline" onClick={() => setprouser(currentProfessional)}>
              Cancel
            </Button>
            <Button
              variant="solid"
              onClick={() => {
                handleSaveProfessionalDetails();
                handleSavePersonalInfo();
              }}
            >
              Save Profile
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );

  const renderPortfolioTab = () => <UserPortfolioDisplay handleUpdateCompleted={() => console.log('todo: handle')} />;

  const renderServicesTab = () => (
    <Flex flex={1} p={16} width="100%">
      <Flex flex={0.2} flexDir="column" alignItems="center" mx={4}>
        <EditableProfileAvatar currentAvatarUrl={userdata.useravatarurl} handleAvatarChanged={updateUserAvatarUrl} />
        <ProfessionalRatingSummary rating={prouser.rating} reviews={prouser.reviewdetails} />
      </Flex>
      <Flex flex={0.8} flexDir="column" mx={4}>
        <Flex alignItems="center" mb={2}>
          <Text fontSize="18" fontWeight="bold" mr={2}>
            Services
          </Text>
          <Box
            p={2}
            onClick={() => {
              setSelectedService(undefined);
              setShowServicesModal(true);
            }}
          >
            <AddIcon />
          </Box>
        </Flex>
        <Flex flex={1}>
          <SimpleGrid minChildWidth={80} spacing={8} flex={1}>
            {prouser.serviceDetails.map((service, i) => {
              const iconurl = EXPERT_SERVICE_OFFERINGS.filter((s) => s.value === service.serviceName)[0]?.thumbnailUri;
              const pricetext = `$${service.pricepoint} ${service.pricetype}`;
              let serviceDescription = service.description;
              if (!serviceDescription) serviceDescription = EXPERT_SERVICE_OFFERINGS.filter((so) => so.value === service.serviceName)[0].defaultDescription;
              const needsAttention = !service.pricepoint || !service.pricetype || !service.turnaroundTimeInDays;
              return (
                <Flex key={i.toString()} flex={1} py={2} px={2} borderWidth={1} alignItems="center">
                  <Image src={iconurl} ml={1} mr={2} fit="contain" />
                  <Flex flex={5} flexDir="column" mx={2}>
                    <Text fontSize="sm" fontWeight="bold">
                      {service.serviceName}
                    </Text>
                    {!needsAttention && <Text fontSize="sm">{pricetext}</Text>}
                    {needsAttention && (
                      <HStack>
                        <WarningTwoIcon color="yellow.400" />
                        <Text>Action Required: Complete Details</Text>
                      </HStack>
                    )}
                    {service.isInactive && (
                      <Text fontWeight="bold" color="grey.700" p={1} bg="yellow.400">
                        INACTIVE
                      </Text>
                    )}
                  </Flex>
                  <Flex flex={1}>
                    <Popover placement="bottom-end">
                      <PopoverTrigger>
                        <Text pl="2" fontWeight="bold" fontSize="lg" lineHeight={1} justifyContent="center" alignItems="center">
                          ...
                        </Text>
                      </PopoverTrigger>
                      <PopoverContent bg="white" borderWidth={1} borderColor="black" mt={2} maxWidth="14rem" _focusVisible={{ boxShadow: undefined }}>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverBody>
                          <Flex flexDir="column" m={1}>
                            <Text
                              my={1}
                              p={1}
                              onClick={() => {
                                setSelectedService(service);
                                setShowServicesModal(true);
                              }}
                            >
                              Edit Service
                            </Text>
                            <Text
                              my={1}
                              p={1}
                              onClick={() => {
                                const clone: ProfessionalUser = structuredClone(prouser);
                                const serviceToDeactivate = clone.serviceDetails.filter((l) => l.serviceName === service.serviceName)[0];
                                if (serviceToDeactivate) {
                                  serviceToDeactivate.isInactive = !serviceToDeactivate.isInactive;
                                  setprouser(clone);
                                }
                              }}
                            >
                              Set as {service.isInactive ? 'Active' : 'Inactive'}
                            </Text>
                            <Text
                              my={1}
                              p={1}
                              onClick={() => {
                                const clone: ProfessionalUser = structuredClone(prouser);
                                clone.serviceDetails = clone.serviceDetails.filter((sd) => sd.serviceName !== service.serviceName);
                                setprouser(clone);
                              }}
                            >
                              Remove Service
                            </Text>
                          </Flex>
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </Flex>
                  <Modal isOpen={showServicesModal} onClose={() => setShowServicesModal(false)} size="3xl">
                    <ModalOverlay bg="rgba(0,0,0,.1)" />
                    <ModalContent>
                      <ModalHeader py={2}>{selectedService ? 'Edit Service' : 'Add New Service'}</ModalHeader>
                      <ModalCloseButton />
                      <ModalBody pb={6} display="flex" flex={1} flexDir="column">
                        <Flex width="70%" flexDir="column">
                          <FormControl isRequired>
                            <FormLabel>Service Category</FormLabel>
                            <Select
                              value={selectedService?.serviceName}
                              variant="outline"
                              borderColor="charcoal"
                              onChange={(e) =>
                                setSelectedService({
                                  ...selectedService,
                                  serviceName: e.target.value,
                                } as ServiceDetails)
                              }
                            >
                              {EXPERT_SERVICE_OFFERINGS.filter((so) => so.value !== 'All').map((so) => (
                                <option key={so.value} value={so.value}>
                                  {so.title}
                                </option>
                              ))}
                            </Select>
                            {/* <Flex alignItems="center" px={1}>
                              <InfoOutlineIcon color="blue.400" mr={2} />
                              <Link href="/" size="sm" color="blue.400">
                                TODO: See category descriptions &gt;
                              </Link>
                            </Flex> */}
                          </FormControl>
                          <FormControl isRequired mt={4}>
                            <FormLabel>Service Description</FormLabel>
                            <Textarea
                              value={selectedService?.description}
                              onChange={(e) =>
                                setSelectedService({
                                  ...selectedService,
                                  description: e.target.value,
                                } as ServiceDetails)
                              }
                            />
                          </FormControl>
                        </Flex>
                        <Flex flex={1} my={4} alignItems="flex-end">
                          <Flex flex={1}>
                            <FormControl isRequired mt={4}>
                              <FormLabel mb={0}>Cost Per Service</FormLabel>
                              <FormLabel fontSize="xs" m={0} p={0}>
                                Cost you will charge for this service
                              </FormLabel>
                              <Flex alignItems="center" mt={2}>
                                <Text pr={0.5}>$</Text>
                                <Input
                                  width="40%"
                                  mr={2}
                                  borderWidth={1}
                                  borderColor="charcoal"
                                  type="number"
                                  value={selectedService?.pricepoint}
                                  onChange={(e) =>
                                    setSelectedService({
                                      ...selectedService,
                                      pricepoint: e.target.valueAsNumber,
                                    } as unknown as ServiceDetails)
                                  }
                                />
                                <Select
                                  variant="outline"
                                  maxWidth="50%"
                                  value={selectedService?.pricetype}
                                  onChange={(e) =>
                                    setSelectedService({
                                      ...selectedService,
                                      pricetype: e.target.value,
                                    } as ServiceDetails)
                                  }
                                >
                                  {SERVICE_PRICING_OPTIONS.map((so) => (
                                    <option key={so.value} value={so.value}>
                                      {so.name}
                                    </option>
                                  ))}
                                </Select>
                              </Flex>
                            </FormControl>
                          </Flex>
                          <Flex flex={1}>
                            <FormControl isRequired>
                              <FormLabel mb={0}>Estimated Turnaround Time</FormLabel>
                              <FormLabel fontSize="xs" m={0} p={0}>
                                Average time (in days) it takes you to complete this service
                              </FormLabel>
                              <Flex alignItems="center" mt={2}>
                                <Input
                                  width="40%"
                                  borderColor="charcoal"
                                  mr={2}
                                  type="number"
                                  value={selectedService?.turnaroundTimeInDays}
                                  onChange={(e) => {
                                    setSelectedService({
                                      ...selectedService,
                                      turnaroundTimeInDays: e.target.valueAsNumber as unknown as number,
                                    } as ServiceDetails);
                                  }}
                                />
                                <Text> days</Text>
                              </Flex>
                            </FormControl>
                          </Flex>
                          <Flex flex={1}>
                            <FormControl>
                              <FormLabel mb={0}>Estimated Service Charge</FormLabel>
                              <FormLabel fontSize="xs" m={0} p={0}>
                                Average total charge for this service
                              </FormLabel>
                              <Flex alignItems="center" mt={2}>
                                <Text pr={0.5}>$</Text>
                                <Input
                                  width="40%"
                                  borderColor="charcoal"
                                  mr={2}
                                  type="number"
                                  value={selectedService?.averagevalue}
                                  onChange={(e) => {
                                    setSelectedService({
                                      ...selectedService,
                                      averagevalue: e.target.valueAsNumber as unknown as number,
                                    } as ServiceDetails);
                                  }}
                                />
                                <Text>total</Text>
                              </Flex>
                            </FormControl>
                          </Flex>
                        </Flex>
                      </ModalBody>
                      <ModalFooter px={4}>
                        <Button
                          variant="darkoutline"
                          mr={3}
                          onClick={() => {
                            setSelectedService(undefined);
                            setShowServicesModal(false);
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="solid"
                          mr={3}
                          onClick={() => {
                            if (
                              !selectedService ||
                              !selectedService.serviceName ||
                              !selectedService.description ||
                              !selectedService.pricepoint ||
                              !selectedService.turnaroundTimeInDays
                            ) {
                              toast({
                                title: 'Missing Required Info',
                                description: 'Please enter all required fields',
                                status: 'error',
                                duration: 3000,
                                isClosable: true,
                              });
                              return;
                            }
                            const clone: ProfessionalUser = structuredClone(prouser);
                            const existing = clone.serviceDetails.filter((l) => l.serviceName === selectedService?.serviceName);
                            if (existing && existing[0] && selectedService) {
                              // this is an update:
                              existing[0].serviceName = selectedService.serviceName;
                              existing[0].description = selectedService.description;
                              existing[0].pricepoint = selectedService.pricepoint;
                              existing[0].pricetype = selectedService.pricetype;
                              existing[0].averagevalue = selectedService.averagevalue;
                              existing[0].turnaroundTimeInDays = selectedService.turnaroundTimeInDays;
                            } else {
                              // this is a new:
                              if (!clone.serviceDetails) clone.serviceDetails = [];
                              clone.serviceDetails.push(selectedService as ServiceDetails);
                            }
                            setprouser(clone);
                            setSelectedService(undefined);
                            setShowServicesModal(false);
                          }}
                        >
                          Save Service
                        </Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                </Flex>
              );
            })}
          </SimpleGrid>
        </Flex>
        <Flex mt={16} width="90%" justifyContent="space-between">
          <Button variant="darkoutline" onClick={() => setprouser(currentProfessional)}>
            Cancel
          </Button>
          <Button
            variant="solid"
            onClick={() => {
              handleSaveProfessionalDetails();
            }}
          >
            Save Services
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );

  return (
    <Flex flex={1} width="100%" flexDir="column" alignItems="center" px={12} py={8}>
      <Flex flex={1} width="100%" flexDir="column" alignItems="center" borderWidth={1} borderRadius="md" my={4}>
        <PageTabs tabNames={['Personal Info', 'Profile', 'Portfolio', 'Services']} selectedTab={selectedTab} handleTabSelected={setSelectedTab} />
        {selectedTab === 'Personal Info' && renderPersonalInfoTab()}
        {selectedTab === 'Profile' && renderProfileTab()}
        {selectedTab === 'Portfolio' && renderPortfolioTab()}
        {selectedTab === 'Services' && renderServicesTab()}
      </Flex>
    </Flex>
  );
}

export default AccountScreen;
