// // Make sure values below matches any of the keys in `customfonts.js`
export const fonts = {
  heading: "'BeniBold', sans-serif",
  body: "'Inter', sans-serif",
  mono: "'Inter', sans-serif",
  interbold: "'InterBold', sans-serif",
  interextrabold: "'InterExtraBold', sans-serif",
};

// export const fontConfig = {
//   SofiaPro: {
//     100: {
//       normal: 'SofiaProUltaLight',
//       italic: 'SofiaProUltaLightItalic',
//     },
//     200: {
//       normal: 'SofiaProExtraLight',
//       italic: 'SofiaProExtraLightItalic',
//     },
//     300: {
//       normal: 'SofiaProLight',
//       italic: 'SofiaProLightItalic',
//     },
//     400: {
//       normal: 'SofiaPro',
//       italic: 'SofiaProItalic',
//     },
//     500: {
//       normal: 'SofiaProMedium',
//       italic: 'SofiaProMediumItalic',
//     },
//     600: {
//       normal: 'SofiaProMedium',
//       italic: 'SofiaProMediumItalic',
//     },
//     700: {
//       normal: 'SofiaProBold',
//       italic: 'SofiaProBoldItalic',
//     },
//   },
// };
