import { ComponentStyleConfig } from '@chakra-ui/react';

const Textarea: ComponentStyleConfig = {
  // style object for base or default style
  baseStyle: {
    borderWidth: 1,
    borderRadius: 'lg',
    p: 1,
    borderColor: 'black.800',
  },
  // styles for different sizes ("sm", "md", "lg")
  sizes: {},
  // styles for different visual variants ("outline", "solid")
  variants: {},
  // default values for 'size', 'variant' and 'colorScheme'
  defaultProps: {
    size: '',
    variant: '',
    colorScheme: '',
  },
};

export default Textarea;
