import { WardrobeItemCategory } from './WardrobeItemCategory';

export interface WardrobeItemQuery {
  /**
   * A string that identifies the User making a WardrobeItemQuery.
   */
  userid: string;
  /**
   * A number that identifies the Wardrobe queried by a WardrobeItemQuery.
   */
  wardrobeid: number | undefined;
  /**
   * sort field
   */
  sf?: string;
  /**
   * sort direction (ASC or DESC)
   */
  sd?: string;
  /**
   * page size
   */
  ps?: number;
  /**
   * page offset
   */
  po?: number;
  /**
   *  primary category, e.g. tops, bottoms, dress, shoes...
   */
  primary?: WardrobeItemCategory | string;
  subcategory?: string;
  /**
   * level of formality
   */
  formality?: string;
  /**
   * which weathers this belongs to
   */
  weather?: string;
  /**
   * which brand of clothing the item is from
   */
  brand?: string;
  color?: string;
  tags?: string[];
  /**
   * A number that identifies the Closet queried by a WardrobeItemQuery.
   */
  closetid?: number;
  /**
   * Can be "owned" or "unowned" to filter on this
   */
  ownership?: string;
  /**
   * Whether a WardrobeItemQuery should also query archived WardrobeItems.
   */
  includeArchivedItems?: boolean;
  /**
   * toggles viewing archived items
   */
  favs?: boolean;
  /**
   * free text search term
   */
  searchterm?: string;
}

export const defaultWardrobeItemQuery = {
  po: 1,
  ps: 40,
  sf: 'createddate',
  sd: 'desc',
  primary: WardrobeItemCategory.All,
  subcategory: 'all',
  favs: false,
  includeArchivedItems: false,
  ownership: 'all',
  wardrobeid: 0, // client.user.wardrobeid, // this is undefined but not a problem for now
  userid: '', // this tells teh serivce to return items for the client
  searchterm: undefined,
};
