import { User as UserAuth } from 'firebase/auth';
import { LOOKBOOK_API } from '../config/ServiceConstants';
import loggerService from './LoggerService';
import fetchWrapper from './fetchWrapper';
import { LookBook } from '../models/LookBook';
import { LookBookQuery } from '../models/LookBookQuery';

export function searchLookBooks(userAuth: UserAuth, query?: LookBookQuery): Promise<any> {
  let url = `${LOOKBOOK_API}/search`;
  if (query) {
    url += '?';
    if (query.po && query.po > 0) {
      url += `&po=${query.po}`; // page offset, defaults to 0 for first page
    }
    if (query.ps) {
      url += `&ps=${query.ps}`; // page size, e.g. items per page
    }
    if (query.sf) {
      url += `&sf=${query.sf}`; // sort field, e.g. modifieddate
    }
    if (query.sd) {
      url += `&sd=${query.sd}`; // sort direction, e.g. asc or desc
    }
    if (query.userid) {
      url += `&userid=${query.userid}`;
    }
    if (query.tags && query.tags.length > 0) {
      url += `&tags=${query.tags}`;
    }
    if (query.ownership && query.ownership.length > 0) {
      url += `&ownership=${query.ownership}`;
    }
    if (query.searchterm) {
      url += `&searchterm=${query.searchterm}`;
    }
  }
  return fetchWrapper(userAuth).get(url);
}

export function createLookBook(userAuth: UserAuth, lookBook: LookBook): Promise<any> {
  const url = `${LOOKBOOK_API}`;
  return fetchWrapper(userAuth).post(url, lookBook);
}

export function updateLookBook(userAuth: UserAuth, id: number, lookBook: LookBook): Promise<any> {
  const url = `${LOOKBOOK_API}/${id}`;
  return fetchWrapper(userAuth).put(url, lookBook);
}

// export async function createLookBook(
//   userAuth: UserAuth,
//   LookBook: LookBook,
//   uploadNewBackground: boolean
// ): Promise<any> {
//   if (!uploadNewBackground) {
//     const url = `${LOOKBOOK_API}`;
//     return fetchWrapper(userAuth).post(url, LookBook);
//   }
//   const filename = LookBook.background;
//   const formData = new FormData();
//   if (uploadNewBackground && filename) {
//     formData.append('file', {
//     //   name: 'temp',
//       type: 'image/jpeg',
//       uri: filename,
//     });
//   }
//   formData.append('model', JSON.stringify(LookBook));

//   const token = await userAuth.identity.getIdToken();
//   const requestOptions = {
//     method: 'POST',
//     headers: {
//       Authorization: `Bearer ${token}`,
//       Accept: 'application/json',
//       'Content-Type': 'multipart/form-data',
//       'File-Name': filename,
//     },
//     body: formData,
//   };
//   const url = `${LOOKBOOK_API}/createwithimage`;
//   return fetch(url, requestOptions);
// }

// export async function update(userAuth: UserAuth, collection: Collection, uploadNewBackground: boolean): Promise<any> {
//   if (!uploadNewBackground) {
//     const url = `${COLLECTION_API}/${collection.id}`;
//     loggerService.debug(url);
//     return fetchWrapper(userAuth).put(url, collection);
//   }
//   const filename = collection.background;
//   const formData = new FormData();
//   formData.append('file', {
//     name: 'temp',
//     type: 'image/jpeg',
//     uri: Platform.OS === 'android' ? filename : filename.replace('file://', ''),
//   });
//   formData.append('model', JSON.stringify(collection));
//   const token = await userAuth.identity.getIdToken();
//   const requestOptions = {
//     method: 'PUT',
//     headers: {
//       Authorization: `Bearer ${token}`,
//       Accept: 'application/json',
//       'Content-Type': 'multipart/form-data',
//       'File-Name': filename,
//     },
//     body: formData,
//   };
//   const url = `${COLLECTION_API}/updatewithimage/${collection.id}`;
//   return fetch(url, requestOptions);
// }
