import React from 'react';
import { Global } from '@emotion/react';

function Fonts() {
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <Global
      styles={`
      /* latin */
      @font-face {
        font-family: 'BeniBold';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('./fonts/BeniBold.woff2') format('otf'), url('./fonts/BeniBold.woff2') format('woff');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* latin */
      @font-face {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('./fonts/Inter-Regular.otf') format('otf'), url('./fonts/Inter-Regular.otf') format('otf');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* latin */
      @font-face {
        font-family: 'InterBold';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('./fonts/Inter-Bold.otf') format('otf'), url('./fonts/Inter-Bold.otf') format('otf');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* latin */
      @font-face {
        font-family: 'InterExtraBold';
        font-style: normal;
        font-weight: 900;
        font-display: swap;
        src: url('./fonts/Inter-ExtraBold.otf') format('otf'), url('./fonts/Inter-ExtraBold.otf') format('otf');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* latin */
      @font-face {
        font-family: 'InterItalic';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('./fonts/Inter-Italic.otf') format('otf'), url('./fonts/Inter-Italic.otf') format('otf');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      `}
    />
  );
}

export default Fonts;
