// import { logger, consoleTransport } from 'react-native-logs';

// const defaultConfig = {
//   severity: 'debug',
//   transport: consoleTransport,
//   transportOptions: {
//     color: 'ansi', // custom option that color consoleTransport logs
//   },
//   levels: {
//     debug: 0,
//     info: 1,
//     warn: 2,
//     error: 3,
//   },
//   async: true,
//   printLevel: true,
//   printDate: true,
//   enabled: true,
// };

// const loggerService = logger.createLogger(defaultConfig);

const loggerService = console;

export default loggerService;
