import React, { useState, createContext } from 'react';
import { User } from '../models/User';

export const CurrentUserContext = createContext<any>(null);

export function CurrentUserProvider({ children }: any) {
  const [currentUser, setCurrentUser] = useState<User | undefined>();

  // TODO: fix the below thing
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  return <CurrentUserContext.Provider value={{ currentUser, setCurrentUser }}>{children}</CurrentUserContext.Provider>;
}
