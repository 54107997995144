import { ComponentStyleConfig } from '@chakra-ui/react';

const Flex: ComponentStyleConfig = {
  // style object for base or default style
  baseStyle: {
    flex: 1,
    width: '100%',
  },
  // styles for different sizes ("sm", "md", "lg")
  sizes: {},
  // styles for different visual variants ("outline", "solid")
  variants: {},
  // default values for 'size', 'variant' and 'colorScheme'
  defaultProps: {
    size: '',
    variant: '',
    colorScheme: '',
    flex: 1,
  },
};

export default Flex;
