/* eslint-disable import/prefer-default-export */
import { User as UserAuth } from 'firebase/auth';
import moment from 'moment';
import fetchWrapper from './fetchWrapper';
import { PROFESSIONALS_API } from '../config/ServiceConstants';
import { PortfolioFeaturedWork, ProfessionalOnboardingRequirements, ProfessionalUser } from '../models/ProfessionalUser';
import { ProfessionalToDo } from '../models/ProfessionalToDo';
import { ProfessionalClientNote } from '../models/ProfessionalClientNote';
import { ProfessionalResourceNote } from '../models/ProfessionalResourceNote';
import { ProfessionalRepository } from '../models/ProfessionalRepository';
import { Application } from '../models/Application';
import { ProfessionalResourceFile } from '../models/ProfessionalResourceFile';

export function fetchProfessionalDetails(userAuth: UserAuth, userId: string): Promise<any> {
  const url = `${PROFESSIONALS_API}/${userId}/professionaldetails`;
  return fetchWrapper(userAuth).get(url);
}

export function updateProfessionalDetails(userAuth: UserAuth, user: ProfessionalUser): Promise<any> {
  const url = `${PROFESSIONALS_API}/${user.userid}/professionaldetails`;
  console.log('updating pro: ', url);
  return fetchWrapper(userAuth).put(url, user);
}

export function updateProfessionalOnboardingRequirements(userAuth: UserAuth, model: ProfessionalOnboardingRequirements | undefined): Promise<any> {
  const url = `${PROFESSIONALS_API}/${userAuth.uid}/professionaldetails/onboardingrequirements`;
  return fetchWrapper(userAuth).put(url, model);
}

export function deleteProfessionalOnboardingRequirements(userAuth: UserAuth): Promise<any> {
  const url = `${PROFESSIONALS_API}/${userAuth.uid}/professionaldetails/onboardingrequirements`;
  return fetchWrapper(userAuth).delete(url);
}

export async function updateProfessionalDetailsWithFile(userAuth: UserAuth, user: ProfessionalUser, file: File): Promise<any> {
  const data: FormData = new FormData();
  data.append('imageFile', file);
  data.append('entity', JSON.stringify(user));
  const token = await userAuth.getIdToken();
  const requestOptions = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      // 'Content-Type': 'multipart/form-data',
      'File-Name': file.name,
    },
    body: data,
  };
  const url = `${PROFESSIONALS_API}/${user.userid}/professionaldetailswithimage`;
  console.log('updating pro: ', url);
  return fetch(url, requestOptions);
}

export async function upsertPortfolioFeaturedWork(userAuth: UserAuth, featuredwork: PortfolioFeaturedWork[], files: File[]): Promise<any> {
  const data: FormData = new FormData();
  files.forEach((file) => {
    data.append('imageFiles', file);
  });
  data.append('entity', JSON.stringify(featuredwork));
  // data.append('imagefiles', files);
  const token = await userAuth.getIdToken();
  const requestOptions = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      // 'Content-Type': 'multipart/form-data',
      // 'File-Name': file.name,
    },
    body: data,
  };
  const url = `${PROFESSIONALS_API}/${userAuth.uid}/professionaldetails/portfoliodata/featuredwork`;
  console.log('updating pro: ', url);
  return fetch(url, requestOptions);
}

export function fetchProfessionalToDos(userAuth: UserAuth, userId: string): Promise<any> {
  const url = `${PROFESSIONALS_API}/${userId}/todos`;
  return fetchWrapper(userAuth).get(url);
}

export function createProfessionalToDo(userAuth: UserAuth, userId: string, item: ProfessionalToDo): Promise<any> {
  const url = `${PROFESSIONALS_API}/${userId}/todos`;
  return fetchWrapper(userAuth).post(url, item);
}

export function updateProfessionalToDo(userAuth: UserAuth, userId: string, item: ProfessionalToDo): Promise<any> {
  const url = `${PROFESSIONALS_API}/${userId}/todos`;
  return fetchWrapper(userAuth).put(url, item);
}

export function deleteProfessionalToDo(userAuth: UserAuth, userId: string, id: number): Promise<any> {
  const url = `${PROFESSIONALS_API}/${userId}/todos/${id}`;
  return fetchWrapper(userAuth).delete(url);
}

export function fetchProfessionalNotesForClient(userAuth: UserAuth, proUserId: string, clientUserId: string): Promise<any> {
  const url = `${PROFESSIONALS_API}/${proUserId}/notes/${clientUserId}`;
  return fetchWrapper(userAuth).get(url);
}

export function createProfessionalClientNote(userAuth: UserAuth, userId: string, item: ProfessionalClientNote): Promise<any> {
  const url = `${PROFESSIONALS_API}/${userId}/professionalclientnote`;
  return fetchWrapper(userAuth).post(url, item);
}

export function deleteProfessionalClientNote(userAuth: UserAuth, userId: string, id: number): Promise<any> {
  const url = `${PROFESSIONALS_API}/${userId}/professionalclientnote/${id}`;
  return fetchWrapper(userAuth).delete(url);
}

export function fetchProfessionalResourceNotes(userAuth: UserAuth, userId: string): Promise<any> {
  const url = `${PROFESSIONALS_API}/${userId}/resourcenotes`;
  return fetchWrapper(userAuth).get(url);
}

export function createProfessionalResourceNote(userAuth: UserAuth, userId: string, item: ProfessionalResourceNote): Promise<any> {
  const url = `${PROFESSIONALS_API}/${userId}/resourcenotes`;
  return fetchWrapper(userAuth).post(url, item);
}

export function updateProfessionalResourceNote(userAuth: UserAuth, userId: string, item: ProfessionalResourceNote): Promise<any> {
  const url = `${PROFESSIONALS_API}/${userId}/resourcenotes/${item.id}`;
  return fetchWrapper(userAuth).put(url, item);
}

export function deleteProfessionalResourceNote(userAuth: UserAuth, userId: string, id: number): Promise<any> {
  const url = `${PROFESSIONALS_API}/${userId}/resourcenotes/${id}`;
  return fetchWrapper(userAuth).delete(url);
}

export function fetchProfessionalResourceFiles(userAuth: UserAuth, userId: string): Promise<any> {
  const url = `${PROFESSIONALS_API}/${userId}/resourcefiles`;
  return fetchWrapper(userAuth).get(url);
}

export async function createProfessionalResourceFile(userAuth: UserAuth, userId: string, item: ProfessionalResourceFile, file: File): Promise<any> {
  const data: FormData = new FormData();
  data.append('imageFile', file);
  data.append('entity', JSON.stringify(item));
  const token = await userAuth.getIdToken();
  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'File-Name': file.name,
    },
    body: data,
  };
  const url = `${PROFESSIONALS_API}/${userId}/resourcefiles`;
  return fetch(url, requestOptions);
}

export async function updateProfessionalResourceFile(userAuth: UserAuth, userId: string, item: ProfessionalResourceFile, file: File): Promise<any> {
  const data: FormData = new FormData();
  data.append('imageFile', file);
  data.append('entity', JSON.stringify(item));
  const token = await userAuth.getIdToken();
  const requestOptions = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'File-Name': file.name,
    },
    body: data,
  };
  const url = `${PROFESSIONALS_API}/${userId}/resourcefiles/${item.id}`;
  return fetch(url, requestOptions);
}

export function deleteProfessionalResourceFile(userAuth: UserAuth, userId: string, id: number): Promise<any> {
  const url = `${PROFESSIONALS_API}/${userId}/resourcefiles/${id}`;
  return fetchWrapper(userAuth).delete(url);
}

export function fetchProfessionalRepositories(userAuth: UserAuth, userId: string): Promise<any> {
  const url = `${PROFESSIONALS_API}/${userId}/repositories`;
  return fetchWrapper(userAuth).get(url);
}

export function createProfessionalRepository(userAuth: UserAuth, userId: string, item: ProfessionalRepository): Promise<any> {
  const url = `${PROFESSIONALS_API}/${userId}/repositories`;
  return fetchWrapper(userAuth).post(url, item);
}

export function updateProfessionalRepository(userAuth: UserAuth, userId: string, repositoryId: number, item: ProfessionalRepository): Promise<any> {
  const url = `${PROFESSIONALS_API}/${userId}/repositories/${repositoryId}`;
  return fetchWrapper(userAuth).put(url, item);
}

export function fetchProfessionalDashboard(userAuth: UserAuth, userId: string, fromdate?: Date, todate?: Date): Promise<any> {
  const qstrfrom = fromdate ? `&fromdate=${moment(fromdate).format('MM-DD-YYYY')}` : '';
  const qstrto = todate ? `&todate=${moment(todate).format('MM-DD-YYYY')}` : '';
  const url = `${PROFESSIONALS_API}/${userId}/dashboard?${qstrfrom}${qstrto}`;
  return fetchWrapper(userAuth).get(url);
}

export function fetchProfessionalMonthlyEarnings(userAuth: UserAuth, userId: string): Promise<any> {
  const url = `${PROFESSIONALS_API}/${userId}/dashboard/monthlyearnings`;
  return fetchWrapper(userAuth).get(url);
}

export function createProfessionalApplication(userAuth: UserAuth, userId: string, model: Application): Promise<any> {
  const url = `${PROFESSIONALS_API}/${userId}/applications`;
  console.log(model);
  return fetchWrapper(userAuth).post(url, model);
}

export function fetchProfessionalApplicationForUser(userAuth: UserAuth, userId: string): Promise<any> {
  const url = `${PROFESSIONALS_API}/${userId}/applications`;
  return fetchWrapper(userAuth).get(url);
}

export function getClientListForProfessional(userAuth: UserAuth): Promise<any> {
  const url = `${PROFESSIONALS_API}/${userAuth.uid}/client-list`;
  return fetchWrapper(userAuth).get(url);
}

export function fetchClientForProfessional(userAuth: UserAuth, clientuserid: string): Promise<any> {
  const url = `${PROFESSIONALS_API}/${userAuth.uid}/client/${clientuserid}`;
  return fetchWrapper(userAuth).get(url);
}
