import { chakra, Icon, Image } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { MentionsInput, Mention, SuggestionDataItem } from 'react-mentions';
import { useAuthState } from 'react-firebase-hooks/auth';
import { User as UserAuth } from 'firebase/auth';
import { Client } from '../models/Client';
import { getClientListForProfessional } from '../services/ProfessionalsService';
import { auth } from '../config/firebase';

type PageTabsProps = {
  value: string;
  handleOnChange: (newvalue: string) => void;
};

function MentionableTextInput({ value, handleOnChange, ...rest }: PageTabsProps) {
  const [user] = useAuthState(auth);
  const [mentionsList, setMentionsList] = useState<SuggestionDataItem[]>([]);

  useEffect(() => {
    if (!user) return;
    if (mentionsList && mentionsList.length > 0) return;
    const fetchClientList = async () => {
      await getClientListForProfessional(user)
        .then((res) => res.json())
        .then((result: Client[]) => {
          // TODO: memoize?
          setMentionsList(
            result.map((r) => ({
              id: r.user.userid,
              display: r.user.username,
            }))
          );
        });
    };
    fetchClientList().catch(console.error);
  }, [mentionsList, user]);

  return (
    <MentionsInput
      style={{ borderWidth: 1, padding: 1 }}
      placeholder={"Mention people using '@'"}
      value={value}
      onChange={(e) => handleOnChange(e.target.value)}
    >
      <Mention
        trigger="@"
        data={mentionsList}
        style={{ backgroundColor: '#cee4e5' }}
        markup="@__display__"
        // renderSuggestion={this.renderUserSuggestion}
      />
    </MentionsInput>
  );
}

export default chakra(MentionableTextInput);
