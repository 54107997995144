/* eslint-disable import/prefer-default-export */
import { UserCredential } from '@firebase/auth';
import { User as UserAuth } from 'firebase/auth';
import fetchWrapper from './fetchWrapper';
import { NOTIFICATIONS_API } from '../config/ServiceConstants';

export function fetchUserNotifications(userAuth: UserAuth): Promise<any> {
  const url = NOTIFICATIONS_API;
  return fetchWrapper(userAuth).get(url);
}

export function markNotificationAcknowledged(userAuth: UserAuth, id: number): Promise<any> {
  const url = `${NOTIFICATIONS_API}/${id}/acknowledge`;
  return fetchWrapper(userAuth).patch(url);
}
