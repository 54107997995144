import { defineStyleConfig } from '@chakra-ui/react';

const Text = defineStyleConfig({
  // The styles all button have in common
  baseStyle: {
    fontFamily: 'interregular',
  },
  // Two variants: outline and solid
  variants: {
    header: {
      fontSize: '2xl',
      color: 'orange.400',
      fontWeight: 700,
      letterSpacing: 0.4,
    },
  },
  // The default size and variant values
  defaultProps: {
    // size: 'md',
    // variant: 'solid',
  },
});

export default Text;
