/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import PropTypes from 'prop-types';
import { Calendar, momentLocalizer, Event, SlotInfo } from 'react-big-calendar';
import DatePicker from 'react-datepicker';
import {
  Button,
  HStack,
  Heading,
  Flex,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  Input,
  Select,
  Divider,
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React, { forwardRef, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { User as UserAuth } from 'firebase/auth';
import { CheckCircleIcon, NotAllowedIcon, ViewIcon } from '@chakra-ui/icons';
import { User } from '../models/User';
import { auth } from '../config/firebase';
import { CurrentUserContext } from '../contexts/CurrentUserProvider';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './CalendarStyles.css';
import { CurrentProfessionalContext } from '../contexts/CurrentProfessionalProvider';
import { createScheduleItem, deleteScheduleItem, fetchUsersAvailability, fetchUsersSchedule, updateScheduleItem } from '../services/ScheduleService';
import { ScheduleItem } from '../models/ScheduleItem';
import { ProfessionalUser } from '../models/ProfessionalUser';
import { updateProfessionalDetails } from '../services/ProfessionalsService';
import CustomDatePickerInput from '../components/CustomDatePickerInput';

const localizer = momentLocalizer(moment);

function AvailabilityScreen() {
  const { currentProfessional } = useContext(CurrentProfessionalContext);
  const [user, loading, error] = useAuthState(auth);
  const [scheduleItems, setScheduleItems] = useState<ScheduleItem[]>([]);
  const [events, setEvents] = useState<Event[]>([]);
  const [pickerStartDate, setPickerStartDate] = useState<Date | null>(new Date());
  const [pickerEndDate, setPickerEndDate] = useState<Date | null>(new Date());
  const [selectedEvent, setSelectedEvent] = useState<Event | undefined>();
  const [selectedScheduleItem, setSelectedScheduleItem] = useState<ScheduleItem | undefined>();
  const [showEditEventModal, setShowEditEventModal] = useState(false);
  const [responseTime, setResponseTime] = useState(currentProfessional?.schedulingpreferences?.responseTimeInDays);
  const eventtypes = ['Available', 'Virtual Only', 'Unavailable'];

  useEffect(() => {
    if (!user) return;
    const fetchSched = async () => {
      await fetchUsersSchedule(user, user.uid)
        .then((res) => res.json())
        .then((result: ScheduleItem[]) => {
          console.log(result);
          setScheduleItems(result);
          const mapped: Event[] = result.map((si) => ({
            start: si.startDateTime,
            end: si.endDateTime,
            title: eventtypes.includes(si.appointmentType) ? si.appointmentType : si.description,
            allDay: si.allDay,
          }));
          setEvents(mapped);
        });
    };
    fetchSched().catch(console.error);
  }, [user]);

  const handleSaveResponseTime = () => {
    if (responseTime) {
      const clone: ProfessionalUser = JSON.parse(JSON.stringify(currentProfessional));
      clone.schedulingpreferences.responseTimeInDays = responseTime;
      updateProfessionalDetails(user as UserAuth, clone).then((res) => res.json());
    }
  };

  const handleSetDateBlock = (eventtype: string) => {
    if (!pickerStartDate || !pickerEndDate) return;
    if (!user) return;
    const clone = [...events];
    const newevent: Event = {
      start: moment(pickerStartDate).startOf('day').toDate(),
      end: moment(pickerEndDate).endOf('day').toDate(),
      title: eventtype,
      allDay: true,
    };
    clone.push(newevent);
    setEvents(clone);
    // persist:
    const newscheduleitem: ScheduleItem = {
      id: 0,
      createdby: user.uid,
      status: 'Active',
      appointmentType: eventtype,
      description: '',
      startDateTime: pickerStartDate,
      endDateTime: pickerEndDate,
      allDay: true,
      // invitees: [user.uid],
    };
    createScheduleItem(user, newscheduleitem).then((res) => res.json());
    setPickerStartDate(moment(pickerEndDate).add(1, 'days').startOf('day').toDate());
    setPickerEndDate(moment(pickerEndDate).add(1, 'days').startOf('day').toDate());
  };

  const handleAddItemFromCalendar = (info: SlotInfo) => {
    // this creates the context for a modal to save the item
    if (!info || !info) return;
    if (!user) return;
    const newevent: Event = {
      start: info.start,
      end: info.end,
      title: 'Pick event type',
      allDay: true,
      // resource? what is this
    };
    setSelectedEvent(newevent);

    const newscheduleitem: ScheduleItem = {
      id: 0,
      createdby: user.uid,
      status: 'Active',
      appointmentType: '',
      description: '',
      startDateTime: info.start,
      endDateTime: info.end,
      allDay: true,
      // invitees: [user.uid],
    };
    setSelectedScheduleItem(newscheduleitem);
  };

  const handleEditEvent = (event: Event, newtype: string) => {
    if (!user) return;
    const clone = [...events];
    const eventindex = clone.findIndex(
      (ev) =>
        moment(ev.start).format('YYYYMMDD') === moment(event.start).format('YYYYMMDD') &&
        moment(ev.end).format('YYYYMMDD') === moment(event.end).format('YYYYMMDD')
    );
    if (eventindex > -1) {
      // update
      clone[eventindex].title = newtype;
      setEvents(clone);
      // get the corresponding scheduleItem and update it:
      const siindex = scheduleItems.findIndex(
        (ev) =>
          moment(ev.startDateTime).format('YYYYMMDD') === moment(event.start).format('YYYYMMDD') &&
          moment(ev.endDateTime).format('YYYYMMDD') === moment(event.end).format('YYYYMMDD')
      );
      const si = scheduleItems[siindex];
      if (si) {
        si.appointmentType = newtype;
        updateScheduleItem(user, si).then((res) => res.json());
      }
    } else {
      // create
      if (!selectedScheduleItem) return;
      // eslint-disable-next-line no-param-reassign
      event.title = newtype;
      clone.push(event);
      setEvents(clone);
      selectedScheduleItem.appointmentType = newtype;
      createScheduleItem(user, selectedScheduleItem as ScheduleItem)
        .then((res) => res.json())
        .then((result) => {
          const siclone = structuredClone(scheduleItems);
          siclone.push(result);
          setScheduleItems(siclone);
        });
    }
    setShowEditEventModal(false);
  };

  const handleDeleteEvent = (event: Event) => {
    if (!user) return;
    const clone = [...events];
    const eventindex = clone.findIndex(
      (ev) =>
        moment(ev.start).format('YYYYMMDD') === moment(event.start).format('YYYYMMDD') &&
        moment(ev.end).format('YYYYMMDD') === moment(event.end).format('YYYYMMDD')
    );
    if (eventindex > -1) {
      const siindex = scheduleItems.findIndex(
        (ev) =>
          moment(ev.startDateTime).format('YYYYMMDD') === moment(event.start).format('YYYYMMDD') &&
          moment(ev.endDateTime).format('YYYYMMDD') === moment(event.end).format('YYYYMMDD')
      );
      const si = scheduleItems[siindex];
      console.log('deleting si: ', si);
      if (si) {
        deleteScheduleItem(user as UserAuth, si.id).then((re) => {
          console.log('events: ', events);
          console.log('eventindex: ', eventindex);
          setEvents(clone.filter((e, i) => i !== eventindex));
        });
      }
      setShowEditEventModal(false);
    }
  };

  // https://github.com/jquense/react-big-calendar/blob/master/stories/demos/exampleCode/rendering.js
  const renderCustomEvent = ({ event }: any) => {
    // console.log('renderCustomEvent');
    const bgcolor =
      // eslint-disable-next-line no-nested-ternary
      event.title === 'Available' ? 'green.200' : event.title === 'Virtual Only' ? 'yellow.100' : 'grey.200';
    const txtcolor =
      // eslint-disable-next-line no-nested-ternary
      event.title === 'Available' ? 'green.700' : event.title === 'Virtual Only' ? 'yellow.700' : 'grey.700';
    return (
      <HStack bg={bgcolor} px={2}>
        {event.title === 'Available' && <CheckCircleIcon color="green.700" />}
        {event.title === 'Virtual Only' && <ViewIcon color="yellow.700" />}
        {event.title === 'Unavailable' && <NotAllowedIcon color="grey.700" />}
        <Text color={txtcolor}>{event.title}</Text>
      </HStack>
    );
  };

  const { components } = useMemo(
    () => ({
      components: {
        event: renderCustomEvent,
      },
    }),
    []
  );

  return (
    <Flex flex={1} flexDir="column" px={12} py={8}>
      <Flex justifyContent="space-between" mb={6} alignItems="center">
        <Text variant="header" color="charcoal">
          Availability
        </Text>
        <Flex>
          <Tag size="sm" bg="green.200" fontWeight="bold" px={4} py={1} maxH={6}>
            <TagLeftIcon as={CheckCircleIcon} />
            <TagLabel>Available</TagLabel>
          </Tag>
          <Tag size="sm" bg="yellow.100" fontWeight="bold" px={4} py={1} maxH={6} ml={2}>
            <TagLeftIcon as={ViewIcon} />
            <TagLabel>Virtual Only</TagLabel>
          </Tag>
          <Tag size="sm" bg="grey.200" fontWeight="bold" px={4} py={1} maxH={6} ml={2}>
            <TagLeftIcon as={NotAllowedIcon} />
            <TagLabel>Unavailable</TagLabel>
          </Tag>
        </Flex>
      </Flex>
      <Flex>
        <Flex maxW={60} flexDir="column" alignItems="flex-start" justifyContent="flex-start">
          <Flex mb={4} justifyContent="flex-start" alignItems="flex-start" zIndex={999}>
            <DatePicker
              customInput={<CustomDatePickerInput />}
              selectsStart
              selected={pickerStartDate}
              // isClearable
              minDate={new Date()}
              startDate={pickerStartDate}
              endDate={pickerEndDate}
              onChange={(date) => setPickerStartDate(date)}
            />
            <Box w={2} />
            <DatePicker
              customInput={<CustomDatePickerInput />}
              selectsEnd
              selected={pickerEndDate}
              // isClearable
              startDate={pickerStartDate}
              endDate={pickerEndDate}
              minDate={pickerStartDate}
              onChange={(date) => setPickerEndDate(date)}
            />
          </Flex>
          <Flex flexDir="column" my={3} width="80%">
            <Tag flex={1} size="sm" bg="green.200" fontWeight="bold" px={4} py={1} maxH={6} onClick={() => handleSetDateBlock('Available')}>
              <TagLeftIcon as={CheckCircleIcon} />
              <TagLabel color="green.700">Available</TagLabel>
            </Tag>
            <Tag flex={1} size="sm" bg="yellow.100" fontWeight="bold" px={4} py={1} maxH={6} mt={3} onClick={() => handleSetDateBlock('Virtual Only')}>
              <TagLeftIcon as={ViewIcon} />
              <TagLabel color="yellow.700">Virtual Only</TagLabel>
            </Tag>
            <Tag flex={1} size="sm" bg="grey.200" fontWeight="bold" px={4} py={1} maxH={6} mt={3} onClick={() => handleSetDateBlock('Unavailable')}>
              <TagLeftIcon as={NotAllowedIcon} />
              <TagLabel color="grey.700">Unavailable</TagLabel>
            </Tag>
          </Flex>
          <Flex flexDir="column" mt={8}>
            <Divider />
            <Text fontSize="sm" fontWeight="bold" mt={4} mb={2}>
              Response Time
            </Text>
            <Text fontSize="xs" maxWidth="80%">
              Average time you plan to respond to clients. Enter 0 for same day.
            </Text>
            <HStack mt={2}>
              <Input size="xs" maxWidth="40%" value={responseTime} onChange={(e) => setResponseTime(e.target.value)} />
              <Text>days(s)</Text>
              {/* <Select size="xs" maxWidth="40%">
                <option value="hours">Hours</option>
                <option value="days">Days</option>
              </Select> */}
            </HStack>
            <Button variant="solid" my={12} width="60%" onClick={() => handleSaveResponseTime()}>
              Save
            </Button>
          </Flex>
        </Flex>
        <Flex flex={1}>
          <Calendar
            components={components}
            localizer={localizer}
            defaultDate={new Date()}
            defaultView="month"
            events={events}
            popup
            selectable
            startAccessor="start"
            endAccessor="end"
            style={{ height: '80vh', width: '98%' }}
            onSelectEvent={(event) => {
              setSelectedEvent(event);
              const siindex = scheduleItems.findIndex(
                (ev) =>
                  moment(ev.startDateTime).format('YYYYMMDD') === moment(event.start).format('YYYYMMDD') &&
                  moment(ev.endDateTime).format('YYYYMMDD') === moment(event.end).format('YYYYMMDD')
              );
              const si = scheduleItems[siindex];
              console.log('seeting selecdtedSI to: ', si);
              setSelectedScheduleItem(si);
              setShowEditEventModal(true);
            }}
            onSelectSlot={(slot) => {
              console.log(slot);
              handleAddItemFromCalendar(slot);
              setShowEditEventModal(true);
            }}
          />
          <Modal size="sm" isCentered isOpen={showEditEventModal} onClose={() => setShowEditEventModal(false)}>
            <ModalOverlay />
            <ModalContent borderRadius="lg">
              <ModalHeader>Edit Availability</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Flex flexDir="column" my={3} width="80%">
                  <Flex flexDir="column" mb={4}>
                    <Text>{selectedScheduleItem?.appointmentType}</Text>
                    <Text size="sm">Description:</Text>
                    <Input
                      value={selectedScheduleItem?.description}
                      onChange={(e) => setSelectedScheduleItem({ ...selectedScheduleItem, description: e.target.value } as ScheduleItem)}
                    />
                    <Divider mb={1} mt={4} borderWidth={0.5} />
                  </Flex>
                  <Tag
                    flex={1}
                    size="sm"
                    bg="green.200"
                    fontWeight="bold"
                    px={4}
                    py={1}
                    maxH={6}
                    onClick={() => handleEditEvent(selectedEvent as Event, 'Available')}
                  >
                    <TagLeftIcon as={CheckCircleIcon} />
                    <TagLabel color="green.700">Available</TagLabel>
                  </Tag>
                  <Tag
                    flex={1}
                    size="sm"
                    bg="yellow.100"
                    fontWeight="bold"
                    px={4}
                    py={1}
                    maxH={6}
                    mt={3}
                    onClick={() => handleEditEvent(selectedEvent as Event, 'Virtual Only')}
                  >
                    <TagLeftIcon as={ViewIcon} />
                    <TagLabel color="yellow.700">Virtual Only</TagLabel>
                  </Tag>
                  <Tag
                    flex={1}
                    size="sm"
                    bg="grey.200"
                    fontWeight="bold"
                    px={4}
                    py={1}
                    maxH={6}
                    mt={3}
                    onClick={() => handleEditEvent(selectedEvent as Event, 'Unavailable')}
                  >
                    <TagLeftIcon as={NotAllowedIcon} />
                    <TagLabel color="grey.700">Unavailable</TagLabel>
                  </Tag>
                  {selectedScheduleItem && selectedScheduleItem.id > 0 && (
                    <Tag
                      flex={1}
                      size="sm"
                      borderWidth={0.5}
                      fontWeight="bold"
                      px={4}
                      py={1}
                      maxH={6}
                      mt={3}
                      onClick={() => handleDeleteEvent(selectedEvent as Event)}
                    >
                      <TagLeftIcon as={NotAllowedIcon} />
                      <TagLabel>Delete</TagLabel>
                    </Tag>
                  )}
                </Flex>
              </ModalBody>
              <ModalFooter>
                <Button onClick={() => setShowEditEventModal(false)}>Close</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default AvailabilityScreen;
