import { extendTheme } from '@chakra-ui/react';
import { MultiSelectTheme } from 'chakra-multiselect';
import foundations from './foundations';
import styles from './styles';
// import components from './components';
import Button from './components/button';
import Drawer from './components/drawer';
import Toast from './components/toast';
import Textarea from './components/Textarea';
import Flex from './components/flex';
import Heading from './components/heading';
import Progress from './components/progress';
import MultiSelect from './components/multiselect';
import Modal from './components/modal';
import Text from './components/text';
import Select from './components/select';

const overrides = {
  styles,
  ...foundations,
  fonts: {
    heading: "'BeniBold', sans-serif",
    // body: "'Inter', sans-serif",
    interbold: "'InterBold', sans-serif",
    interextrabold: "'InterExtraBold', sans-serif",
    interregular: "'Inter', sans-serif",
    body: `Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
  },
  components: {
    Button,
    Drawer,
    Toast,
    Textarea,
    Flex,
    Heading,
    Progress,
    Modal,
    Text,
    Select,
    // MultiSelect: MultiSelectTheme,
    MultiSelect: MultiSelectTheme,
  },
};

const donnetheme = extendTheme(overrides);

// donnetheme.fonts = {
//   heading: "'BeniBold', sans-serif",
//   // body: "'Inter', sans-serif",
//   bold: "'InterBold', sans-serif",
//   body: `Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
// };

export default donnetheme;
