/* eslint-disable import/prefer-default-export */
import { User as UserAuth } from 'firebase/auth';
import fetchWrapper from './fetchWrapper';
import { SCHEDULE_API } from '../config/ServiceConstants';
import loggerService from './LoggerService';
import { ScheduleItem } from '../models/ScheduleItem';

export function fetchUsersAvailability(userAuth: UserAuth, userid: string): Promise<any> {
  const url = `${SCHEDULE_API}/availability/${userid}`;
  return fetchWrapper(userAuth).get(url);
}

export function fetchUsersSchedule(userAuth: UserAuth, userid: string, status?: string): Promise<any> {
  const url = status ? `${SCHEDULE_API}?status=${status}` : `${SCHEDULE_API}`;
  return fetchWrapper(userAuth).get(url);
}

export function fetchUsersScheduleForClient(userAuth: UserAuth, prouserid: string, clientuserid: string): Promise<any> {
  const url = `${SCHEDULE_API}/${prouserid}/${clientuserid}`;
  return fetchWrapper(userAuth).get(url);
}

export function createScheduleItem(userAuth: UserAuth, scheduleItem: ScheduleItem): Promise<any> {
  const url = `${SCHEDULE_API}`;
  return fetchWrapper(userAuth).post(url, scheduleItem);
}

export function updateScheduleItem(userAuth: UserAuth, scheduleItem: ScheduleItem): Promise<any> {
  const url = `${SCHEDULE_API}/${scheduleItem.id}`;
  return fetchWrapper(userAuth).put(url, scheduleItem);
}

export function deleteScheduleItem(userAuth: UserAuth, id: number): Promise<any> {
  const url = `${SCHEDULE_API}/${id}`;
  return fetchWrapper(userAuth).delete(url);
}
