const styles = {
  global: {
    // styles for the `body`
    body: {
      bg: '#ffffff',
      fontFamily: 'body',
      fontSize: 'sm',
    },
  },
};

export default styles;
