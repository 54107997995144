import moment from 'moment';
import { Text, Flex, Input, chakra } from '@chakra-ui/react';
import React from 'react';

type TextInputWithLabelProps = {
  fieldname: string;
  fieldvalue: any;
  fieldChangeHandler: (txt: string) => void;
  vertical?: boolean;
};

function TextInputWithLabel({ fieldname, fieldvalue, fieldChangeHandler, vertical, ...rest }: TextInputWithLabelProps) {
  return (
    <Flex flex={1} mb={0.75} py={0.5} flexDir={vertical ? 'column' : 'row'} {...rest}>
      <Text flex={1} size="sm" color="blue.400">
        {fieldname}
      </Text>
      <Input flex={1} size="sm" value={fieldvalue} onChange={(e) => fieldChangeHandler(e.target.value)} />
    </Flex>
  );
}

export default chakra(TextInputWithLabel);
