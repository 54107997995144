export interface UserQuery {
  /**
   * A string that identifies the User making a UserQuery.
   */
  userid: string;
  /**
   * ???
   */
  pageSize?: number;
  /**
   * ???
   */
  requestedPage?: number;
  /**
   * The term being searched for by a UserQuery.
   */
  queryTerm?: string;
  /**
   * The scope of the search.
   */
  scope: UserSearchScope;
}

export const enum UserSearchScope {
  All = 'all',
  Followers = 'followers',
  Following = 'following',
}
