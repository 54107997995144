/* eslint-disable react/no-array-index-key */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Flex,
  Text,
  Spinner,
  Image,
  chakra,
  Button,
  Icon,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
} from '@chakra-ui/react';
import { EditIcon, StarIcon } from '@chakra-ui/icons';
import { useAuthState } from 'react-firebase-hooks/auth';
import { User as UserAuth } from 'firebase/auth';
import { useFilePicker } from 'use-file-picker';
import { FiPlusCircle } from 'react-icons/fi';
import { RiEditCircleFill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { auth } from '../config/firebase';
import { CurrentProfessionalContext } from '../contexts/CurrentProfessionalProvider';
import { CurrentUserContext } from '../contexts/CurrentUserProvider';
import { ProfessionalUser, PortfolioFeaturedWork } from '../models/ProfessionalUser';
import LoadingScreen from './LoadingScreen';
import { upsertPortfolioFeaturedWork } from '../services/ProfessionalsService';
import AddIcon from './AddIcon';
import { OutfitQuery } from '../models/OutfitQuery';
import { Outfit } from '../models/Outfit';
import { searchOutfits } from '../services/OutfitsService';
import { PaginatedResult } from '../models/PaginatedResult';
import OutfitPartsDisplay from './OutfitPartsDisplay';

type UserPortfolioDisplayProps = {
  handleUpdateCompleted: () => void;
};

function UserPortfolioDisplay({ handleUpdateCompleted, ...rest }: UserPortfolioDisplayProps): React.ReactElement {
  const { currentUser } = useContext(CurrentUserContext);
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const { currentProfessional, setCurrentProfessional } = useContext(CurrentProfessionalContext);
  const [prouser, setprouser] = useState<ProfessionalUser>(currentProfessional as ProfessionalUser);
  const [selectedPortfolioFeaturedWork, setSelectedPortfolioFeaturedWork] = useState<PortfolioFeaturedWork | undefined>(undefined);
  const [outfits, setOutfits] = useState<Outfit[] | undefined>();
  const OUTFIT_BOX_DIMENSION = 380;

  useEffect(() => {
    if (!user) return;
    const query: OutfitQuery = {
      po: 1,
      ps: 40,
      sf: 'createddate',
      sd: 'desc',
      createdby: user.uid, // this tells the service to return items for the client
      searchterm: undefined,
    };
    const fetchOutfits = async () => {
      await searchOutfits(user, query)
        .then((res) => res.json())
        .then((result: PaginatedResult) => {
          console.log('outfits: ', result.pageItems);
          setOutfits(result.pageItems);
        });
    };
    fetchOutfits().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const [openFileSelector, { filesContent, plainFiles, loading, errors, clear: clearFilePicker }] = useFilePicker({
    readAs: 'DataURL',
    accept: ['image/*', '.png', '.jpg', 'jpeg'],
    multiple: false,
    limitFilesConfig: { max: 1 },
    // minFileSize: 0.1, // in megabytes
    maxFileSize: 20,
    imageSizeRestrictions: {
      maxHeight: 2000, // in pixels
      maxWidth: 2000,
      minHeight: 20,
      minWidth: 20,
    },
  });

  const handleSavePortfolioDetails = async () => {
    console.log('saving portfoliodetails: ', prouser);

    await upsertPortfolioFeaturedWork(user as UserAuth, prouser.portfolioData.featuredWork, plainFiles)
      .then((res) => res.json())
      .then((result) => setCurrentProfessional(prouser))
      .then(() => clearFilePicker())
      .catch((err) => console.log(err));

    handleUpdateCompleted();
  };

  if (!currentProfessional || !currentUser) return <LoadingScreen />;
  if (!prouser) return <LoadingScreen />;

  return (
    <>
      <Flex flex={1} p={16} width="100%" flexDir="column">
        <Flex flex={1} mb={2}>
          <Text fontSize="18" fontWeight="bold" mb={2} mr={12}>
            Past Work
          </Text>
          <AddIcon
            onClick={() =>
              setSelectedPortfolioFeaturedWork({
                id: 0,
                dateadded: new Date(),
                description: '',
                link: '',
                imageurl: '',
              } as PortfolioFeaturedWork)
            }
          />
        </Flex>
        <Flex flex={1} width="100%" overflowX="scroll">
          {prouser.portfolioData?.featuredWork &&
            prouser.portfolioData.featuredWork.map((item, i) => (
              <Flex key={i.toString()} flexDirection="column" mx={2} alignItems="flex-start">
                <Image src={item.imageurl} fit="contain" h={48} />
                <Icon
                  as={RiEditCircleFill}
                  color="orange.400"
                  transform="translateY(-8px) translateX(950%)"
                  onClick={() => setSelectedPortfolioFeaturedWork(item)}
                />
                <Text fontWeight="bold" w={44} mb={1}>
                  {item.description}
                </Text>
                {item.link && <Link href={item.link}>{item.link}</Link>}
                {item.isHighlighted && <StarIcon color="gold" />}
              </Flex>
            ))}
          {(!prouser.portfolioData.featuredWork || prouser.portfolioData.featuredWork.length === 0) && (
            <Text>Showcase past work, including features, photoshoots, and more</Text>
          )}
        </Flex>
        <Flex mt={16} flexDir="column">
          <Flex flex={1} mb={4}>
            <Text fontSize="18" fontWeight="bold" mb={2} mr={12}>
              Outfits
            </Text>
            <AddIcon onClick={() => navigate('/clients')} />
          </Flex>
          {outfits && outfits.length > 0 && (
            <Flex flex={1} width="100%" overflowX="scroll">
              {outfits.map((item, i) => (
                <Flex key={i.toString()} flexDirection="column" mx={2} alignItems="flex-start">
                  <OutfitPartsDisplay outfit={item} height={OUTFIT_BOX_DIMENSION} width={OUTFIT_BOX_DIMENSION} resizeRatio={1} />
                </Flex>
              ))}
            </Flex>
          )}
          {(!outfits || outfits.length === 0) && <Text>Showcase curated outfits to give clients a sense of your style expertise.</Text>}
        </Flex>
        <Flex mt={16} width="90%" justifyContent="space-between">
          <Button variant="darkoutline" onClick={() => setprouser(currentProfessional)}>
            Cancel
          </Button>
          <Button
            variant="solid"
            onClick={() => {
              handleSavePortfolioDetails();
            }}
          >
            Save Portfolio
          </Button>
        </Flex>
      </Flex>
      <Modal size="md" isCentered isOpen={selectedPortfolioFeaturedWork !== undefined} onClose={() => setSelectedPortfolioFeaturedWork(undefined)}>
        <ModalOverlay />
        <ModalContent borderRadius="lg">
          <ModalHeader>{selectedPortfolioFeaturedWork && selectedPortfolioFeaturedWork.id > 0 ? 'Edit' : 'Add'} Featured Work Item</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex flexDir="column" justifyContent="center" m={2}>
              <Text size="sm">Project Title</Text>
              <Input
                value={selectedPortfolioFeaturedWork?.description}
                onChange={(e) =>
                  setSelectedPortfolioFeaturedWork({
                    ...selectedPortfolioFeaturedWork,
                    description: e.target.value,
                  } as PortfolioFeaturedWork)
                }
              />
              <Text size="sm" my={2}>
                Project URL
              </Text>
              <Input
                type="url"
                my={2}
                value={selectedPortfolioFeaturedWork?.link}
                onChange={(e) =>
                  setSelectedPortfolioFeaturedWork({
                    ...selectedPortfolioFeaturedWork,
                    link: e.target.value,
                  } as PortfolioFeaturedWork)
                }
              />
              <Image src="/icons/camera_add.png" h={12} w={12} onClick={() => openFileSelector()} />
              {loading && <Spinner />}
              {errors && errors.length > 0 && <Text>{JSON.stringify(errors)}</Text>}
              {filesContent && filesContent[0] && <Image src={filesContent[0].content} maxHeight={64} fit="contain" />}
              {!filesContent[0] && selectedPortfolioFeaturedWork?.imageurl && (
                <Image src={selectedPortfolioFeaturedWork.imageurl} maxHeight={64} fit="contain" />
              )}
              <Text size="sm" mt={2}>
                Set as Featured on Profile:
              </Text>
              <Switch
                colorScheme="orange"
                isChecked={selectedPortfolioFeaturedWork?.isHighlighted}
                onChange={() =>
                  setSelectedPortfolioFeaturedWork({
                    ...selectedPortfolioFeaturedWork,
                    isHighlighted: !selectedPortfolioFeaturedWork?.isHighlighted,
                  } as PortfolioFeaturedWork)
                }
              />
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="darkoutline"
              mr={4}
              onClick={() => {
                setSelectedPortfolioFeaturedWork(undefined);
                clearFilePicker();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="solid"
              bg="red.400"
              mr={4}
              onClick={() => {
                const clone: ProfessionalUser = structuredClone(prouser);
                const existing = clone.portfolioData.featuredWork.filter((l) => l.id === selectedPortfolioFeaturedWork?.id);
                if (existing && existing[0]) {
                  clone.portfolioData.featuredWork = clone.portfolioData.featuredWork.filter((l) => l.id !== existing[0].id);
                  setprouser(clone);
                }
                setSelectedPortfolioFeaturedWork(undefined);
              }}
            >
              Delete
            </Button>
            <Button
              variant="solid"
              onClick={() => {
                const clone: ProfessionalUser = structuredClone(prouser);
                const existing = clone.portfolioData.featuredWork.filter((l) => l.id === selectedPortfolioFeaturedWork?.id);
                if (existing && existing[0] && selectedPortfolioFeaturedWork) {
                  // this is an update:
                  existing[0].description = selectedPortfolioFeaturedWork.description;
                  existing[0].link = selectedPortfolioFeaturedWork.link;
                  existing[0].imageurl = selectedPortfolioFeaturedWork.imageurl; // TODO: ?? where get this
                  existing[0].isHighlighted = selectedPortfolioFeaturedWork.isHighlighted;
                } else {
                  // this is a new:
                  if (!selectedPortfolioFeaturedWork) return;
                  if (!clone.portfolioData.featuredWork) clone.portfolioData.featuredWork = [];
                  selectedPortfolioFeaturedWork.id = Math.max(...clone.portfolioData.featuredWork.map((i) => i.id)) + 1;
                  selectedPortfolioFeaturedWork.imageurl = filesContent[0].content;
                  clone.portfolioData.featuredWork.push(selectedPortfolioFeaturedWork as PortfolioFeaturedWork);
                }
                // console.log(clone);
                setprouser(clone);
                setSelectedPortfolioFeaturedWork(undefined);
              }}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default UserPortfolioDisplay;
