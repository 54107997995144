import moment from 'moment';
import { Box, Button, Flex, Heading, Icon, Text } from '@chakra-ui/react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { User as UserAuth } from 'firebase/auth';
import { HiOutlinePlusCircle, HiPlusCircle } from 'react-icons/hi';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { auth } from '../config/firebase';
import { CurrentUserContext } from '../contexts/CurrentUserProvider';
import { fetchUserNotifications, markNotificationAcknowledged } from '../services/NotificationService';
import { Notification as NotificationModel } from '../models/Notification';

function Notifications() {
  const [user, loading, error] = useAuthState(auth);
  const { currentUser } = useContext(CurrentUserContext);
  const [notifications, setNotifications] = useState<NotificationModel[] | undefined>();
  const [showOnlyUnread, setShowOnlyUnread] = useState(false);

  useEffect(() => {
    if (!user) return;
    const getNotifications = async () => {
      await fetchUserNotifications(user)
        .then((res) => res.json())
        .then((result: NotificationModel[]) => {
          setNotifications(result);
        });
    };

    getNotifications().catch(console.error);
  }, [user]);

  const notificationsForDisplay = useMemo(() => {
    if (!notifications) return undefined;
    if (!showOnlyUnread) return notifications;
    return notifications.filter((n) => !n.isAcknowledged);
  }, [notifications, showOnlyUnread]);

  const handleAcknowledgeNotification = async (id: number) => {
    await markNotificationAcknowledged(user as UserAuth, id).then((result) => {
      const clone: NotificationModel[] = structuredClone(notifications);
      clone.filter((n) => n.id === id)[0].isAcknowledged = true;
      console.log(clone);
      setNotifications(clone);
    });
  };

  return (
    <Flex flexDir="column">
      <Text variant="header" color="charcoal">
        Notifications
      </Text>
      <Flex my={2}>
        <Button variant={showOnlyUnread ? 'outline' : 'solid'} size="sm" onClick={() => setShowOnlyUnread(false)}>
          All
        </Button>
        <Button variant={showOnlyUnread ? 'solid' : 'darkoutline'} size="sm" ml={2} onClick={() => setShowOnlyUnread(true)}>
          Unread
        </Button>
      </Flex>
      {notificationsForDisplay && notificationsForDisplay.length === 0 && <Text>Nothing to see here.</Text>}
      <Flex flexDir="column" mt={4} flex={1} overflowY="scroll">
        {notificationsForDisplay?.map((notification) => (
          <Flex key={notification.id.toString()} flex={1} alignItems="center" mb={4} onClick={() => handleAcknowledgeNotification(notification.id)}>
            <Flex flex={1} justifyContent="flex-start">
              <InfoOutlineIcon h={8} w={8} color="orange.400" />
            </Flex>
            <Flex flex={6} flexDir="column" px={1}>
              <Text fontWeight="bold" color="charcoal">
                {notification.title}
              </Text>
              <Text>{notification.body}</Text>
              <Text size="xs" color="grey.400">
                {moment(notification.timestamp).calendar()}
              </Text>
            </Flex>
            {!notification.isAcknowledged && <Box borderRadius="full" h="2" w="2" mr={2} bg="blue.300" />}
            {notification.isAcknowledged && <Box borderRadius="full" h="2" w="2" mr={2} bg="white" />}
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
}

export default Notifications;
