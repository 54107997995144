import React, { useState, createContext } from 'react';
import { ProfessionalUser } from '../models/ProfessionalUser';
import { User } from '../models/User';

export type CurrentProfessionalContextType = {
  currentProfessional: ProfessionalUser;
  setCurrentProfessional: (prouser: ProfessionalUser) => void;
};

export const CurrentProfessionalContext = createContext<CurrentProfessionalContextType | any>(null);

export function CurrentProfessionalProvider({ children }: any) {
  const [currentProfessional, setCurrentProfessional] = useState<ProfessionalUser | undefined>();

  // TODO: fix the below thing
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <CurrentProfessionalContext.Provider value={{ currentProfessional, setCurrentProfessional } as CurrentProfessionalContextType}>
      {children}
    </CurrentProfessionalContext.Provider>
  );
}
