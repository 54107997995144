import moment from 'moment';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Text,
  Image,
  Select,
  Spinner,
  Textarea,
  useToast,
  Box,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { User as UserAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { ChevronRightIcon, CalendarIcon } from '@chakra-ui/icons';
import { auth } from '../config/firebase';
import { ProfessionalEngagementServiceRecord } from '../models/ProfessionalEngagementServiceRecord';
import { CurrentProfessionalContext, CurrentProfessionalContextType } from '../contexts/CurrentProfessionalProvider';
import {
  createProfessionalEngagementServiceRecord,
  deleteProfessionalEngagementServiceRecord,
  updateProfessionalEngagementServiceRecord,
} from '../services/EngagementsService';
import CustomDatePickerInput from './CustomDatePickerInput';
import MentionableTextInput from './MentionableTextInput';

type ServiceRecordEditorProps = {
  selectedServiceRecord?: ProfessionalEngagementServiceRecord;
  setSelectedServiceRecord: (sr: ProfessionalEngagementServiceRecord | undefined) => void;
  handleServiceRecordCreated: (sr: ProfessionalEngagementServiceRecord) => void;
  handleServiceRecordUpdated: (sr: ProfessionalEngagementServiceRecord) => void;
  handleServiceRecordDeleted: (id: number) => void;
  clientDisplayName?: string;
  engagementId: number;
  serviceOptions: any[];
};

function ServiceRecordEditor({
  selectedServiceRecord,
  setSelectedServiceRecord,
  handleServiceRecordCreated,
  handleServiceRecordUpdated,
  handleServiceRecordDeleted,
  clientDisplayName,
  engagementId,
  serviceOptions,
  ...rest
}: ServiceRecordEditorProps) {
  const { currentProfessional }: CurrentProfessionalContextType = useContext(CurrentProfessionalContext);
  const [user] = useAuthState(auth);
  const [loading, setLoading] = useState(false);
  const [showOverrideEntry, setShowOverrideEntry] = useState(false);
  const [currentOverrideValue, setCurrentOverrideValue] = useState<undefined | number>();
  const toast = useToast();

  const handleSaveServiceRecord = async () => {
    const clone = structuredClone(selectedServiceRecord) as ProfessionalEngagementServiceRecord;
    if (!clone.service || !clone.start) {
      toast({
        title: 'Missed required data',
        description: 'Must choose service and start date/time.',
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
      return;
    }
    if (!clone.professionalengagementid) clone.professionalengagementid = engagementId;
    clone.status = clone.end ? 'Completed' : 'In Progress';
    if (!clone.id) {
      await createProfessionalEngagementServiceRecord(user as UserAuth, currentProfessional.userid, clone.professionalengagementid as number, clone)
        .then((res) => res.json())
        .then((result) => {
          //   const serviceRecordsClone = structuredClone(serviceRecords);
          //   serviceRecordsClone.push(result);
          //   setServiceRecords(serviceRecordsClone);
          handleServiceRecordCreated(result);
        })
        .catch((err) => console.log(err));
    } else {
      await updateProfessionalEngagementServiceRecord(user as UserAuth, currentProfessional.userid, clone.professionalengagementid as number, clone)
        .then((res) => res.json())
        .then((result) => {
          //   const serviceRecordsClone = structuredClone(serviceRecords);
          //   serviceRecordsClone.push(selectedServiceRecord); // THIS IS PROBABLY A BUG THAT DUPLICATES...
          //   setServiceRecords(serviceRecordsClone);
          handleServiceRecordUpdated(result);
        })
        .catch((err) => console.log(err));
    }

    setSelectedServiceRecord(undefined);
  };

  const handleDeleteServiceRecord = async () => {
    const clone = structuredClone(selectedServiceRecord) as ProfessionalEngagementServiceRecord;
    await deleteProfessionalEngagementServiceRecord(
      user as UserAuth,
      currentProfessional.userid,
      clone.professionalengagementid as number,
      clone.id as number
    ).then(() => {
      //   const srs = serviceRecords.filter((sr) => sr.id !== clone.id);
      //   setServiceRecords(srs);
      //   setSelectedServiceRecord(undefined);
      handleServiceRecordDeleted(clone.id);
    });
  };

  if (loading) return <Spinner />;

  return (
    <>
      <Drawer placement="right" size="xl" onClose={() => setSelectedServiceRecord(undefined)} isOpen={selectedServiceRecord !== undefined}>
        <DrawerOverlay />
        <DrawerContent bg="white">
          <DrawerHeader borderBottomWidth="1px">
            <Text variant="header" color="charcoal">
              Edit Service Log
            </Text>
          </DrawerHeader>
          <DrawerBody>
            <Flex flexDir="column">
              <Breadcrumb spacing="8px" mb={6} separator={<ChevronRightIcon color="blue.400" />}>
                <BreadcrumbItem>
                  <Text>Clients</Text>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <Text>{clientDisplayName}</Text>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <Text>Tracking Log</Text>
                </BreadcrumbItem>
              </Breadcrumb>
              <Flex flexDir="column" my={2}>
                <Text fontSize="lg" fontWeight="bold">
                  Service
                </Text>
                <Select
                  variant="outline"
                  width="30%"
                  flex={2}
                  mx={1}
                  bg="white"
                  color="charcoal"
                  borderColor="charcoal"
                  fontWeight="bold"
                  isRequired
                  placeholder="Select Service"
                  // value={selectedServiceRecord?.service ? selectedServiceRecord.service : serviceOptions[0].value}
                  value={selectedServiceRecord?.service}
                  onChange={(e) => setSelectedServiceRecord({ ...selectedServiceRecord, service: e.target.value } as ProfessionalEngagementServiceRecord)}
                >
                  {serviceOptions &&
                    serviceOptions
                      .filter((es) => es.value !== 'All')
                      .map((so) => (
                        <option key={so.value} value={so.value}>
                          {so.title}
                        </option>
                      ))}
                </Select>
              </Flex>
              <Flex flexDir="column" my={2}>
                <Text fontSize="lg" fontWeight="bold" mb={2}>
                  Time Spent
                </Text>
                <Flex width="50%" flex={2} alignItems="center">
                  <Flex width="50%" my={1} alignItems="center">
                    <CalendarIcon mr={1} />
                    <DatePicker
                      customInput={<CustomDatePickerInput />}
                      isClearable
                      value="Set Start Date"
                      showTimeSelect
                      selected={selectedServiceRecord?.start ? (selectedServiceRecord.start as Date) : undefined}
                      onChange={(date) => setSelectedServiceRecord({ ...selectedServiceRecord, start: date } as ProfessionalEngagementServiceRecord)}
                    />
                    {/* <Input
                    type="datetime-local"
                    px={1}
                    value={selectedServiceRecord?.start ? moment(selectedServiceRecord.start).format('YYYY-MM-DDTHH:mm') : ''}
                    onChange={(e) =>
                      setSelectedServiceRecord({ ...selectedServiceRecord, start: new Date(e.target.value) } as ProfessionalEngagementServiceRecord)
                    }
                  /> */}
                  </Flex>
                  <Text flex={1} ml={1} width="50%">
                    {moment(selectedServiceRecord?.start).format('MM/DD/YY hh:mm A')}
                  </Text>
                </Flex>
                <Flex width="50%" flex={1} my={2} alignItems="center">
                  <Flex width="50%">
                    <CalendarIcon mr={1} />
                    <DatePicker
                      customInput={<CustomDatePickerInput />}
                      isClearable
                      value="Set End Date  "
                      showTimeSelect
                      timeFormat="HH:mm:ss"
                      selected={selectedServiceRecord?.end ? (selectedServiceRecord.end as Date) : undefined}
                      onChange={(date) => setSelectedServiceRecord({ ...selectedServiceRecord, end: date } as ProfessionalEngagementServiceRecord)}
                    />
                    {/* <Input
                    type="datetime-local"
                    px={1}
                    value={selectedServiceRecord?.end ? moment(selectedServiceRecord.end).format('YYYY-MM-DDTHH:mm') : ''}
                    onChange={(e) =>
                      setSelectedServiceRecord({ ...selectedServiceRecord, end: new Date(e.target.value) } as ProfessionalEngagementServiceRecord)
                    }
                  /> */}
                  </Flex>
                  <Text flex={1} ml={1} width="50%">
                    {selectedServiceRecord?.end ? moment(selectedServiceRecord?.end).format('MM/DD/YY hh:mm A') : ''}
                  </Text>
                </Flex>
                {selectedServiceRecord?.start && selectedServiceRecord.end && (
                  <Text my={2}>
                    Selected duration: {moment(selectedServiceRecord?.end).diff(moment(selectedServiceRecord?.start), 'hours', true).toFixed(1)} hour(s)
                  </Text>
                )}
              </Flex>
              <Flex flexDir="column" my={2}>
                <Text fontSize="lg" fontWeight="bold">
                  Notes
                </Text>
                <Textarea
                  value={selectedServiceRecord?.notes}
                  onChange={(e) => setSelectedServiceRecord({ ...selectedServiceRecord, notes: e.target.value } as ProfessionalEngagementServiceRecord)}
                />
              </Flex>
              <Flex mt={2} alignItems="center">
                {selectedServiceRecord && selectedServiceRecord.id > 0 && (
                  <Box bg="olive.400" p={1} px={2} borderRadius="lg">
                    <Text color="white">
                      Total Earnings: ${selectedServiceRecord?.invoicedamount ? selectedServiceRecord.invoicedamount : selectedServiceRecord?.calculatedcharge}
                    </Text>
                  </Box>
                )}
                {selectedServiceRecord && selectedServiceRecord.id > 0 && !showOverrideEntry && (
                  <Text as="u" color="charcoal" fontWeight="bold" ml={4} onClick={() => setShowOverrideEntry(true)}>
                    Override Amount
                  </Text>
                )}
                {/* {showOverrideEntry && (
                  <Input
                    type="number"
                    value={selectedServiceRecord?.invoicedamount}
                    onChange={(e) =>
                      setSelectedServiceRecord({ ...selectedServiceRecord, invoicedamount: e.target.value } as unknown as ProfessionalEngagementServiceRecord)
                    }
                  />
                )} */}
              </Flex>
            </Flex>
          </DrawerBody>
          <DrawerFooter>
            <Flex flex={1} mx={6} justifyContent="space-between">
              {selectedServiceRecord && selectedServiceRecord.id > 0 && (
                <Button variant="solid" bg="red.400" mr={4} onClick={handleDeleteServiceRecord}>
                  Delete
                </Button>
              )}
              <Flex>
                <Button mr={3} variant="darkoutline" onClick={() => setSelectedServiceRecord(undefined)}>
                  Cancel
                </Button>
                <Button variant="solid" onClick={handleSaveServiceRecord}>
                  Save Log
                </Button>
              </Flex>
            </Flex>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <Modal
        size="sm"
        isCentered
        isOpen={showOverrideEntry}
        onClose={() => {
          setShowOverrideEntry(false);
          setCurrentOverrideValue(undefined);
        }}
      >
        <ModalOverlay />
        <ModalContent borderRadius="lg">
          <ModalHeader>Override Charges</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex flexDir="column" justifyContent="center" m={2}>
              <Text mt={2} fontSize="sm">
                Original charge amount:
              </Text>
              <Text>${selectedServiceRecord?.calculatedcharge}</Text>
            </Flex>
            <Flex flexDir="column" justifyContent="center" m={2}>
              <Text mt={2} fontSize="sm">
                Updated charge amount:
              </Text>
              <Input
                type="number"
                value={currentOverrideValue}
                onChange={(e) => {
                  setCurrentOverrideValue(e.target.valueAsNumber);
                }}
              />
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="darkoutline"
              mr={4}
              onClick={() => {
                setShowOverrideEntry(false);
                setCurrentOverrideValue(undefined);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="solid"
              onClick={() => {
                const clone = structuredClone(currentOverrideValue);
                setSelectedServiceRecord({ ...selectedServiceRecord, invoicedamount: clone } as ProfessionalEngagementServiceRecord);
                setCurrentOverrideValue(undefined);
                setShowOverrideEntry(false);
              }}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ServiceRecordEditor;
