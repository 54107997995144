/* eslint-disable react/destructuring-assignment */
import { Text, Flex, Box, Button, Link, Avatar } from '@chakra-ui/react';
import React, { useContext, useEffect } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { User } from '../models/User';
import LabelValueDisplayItem from './LabelValueDisplayItem';

type ClientDetailsDisplayProps = {
  user: User;
};

export function ClientPersonalDetailsDisplay({ user }: ClientDetailsDisplayProps) {
  if (!user) return <Box />;
  return (
    <Flex flexDir="column" flex={1}>
      <Text color="orange.400" fontWeight="bold" mb={1}>
        Personal Details
      </Text>
      <LabelValueDisplayItem fieldname="Name" fieldvalue={user.username} />
      <LabelValueDisplayItem fieldname="Age" fieldvalue={moment().diff(user.birthday, 'years', false).toString()} />
      <LabelValueDisplayItem fieldname="Profession / Industry" fieldvalue="TODO: isnt this styling?" />
      <LabelValueDisplayItem fieldname="Address" fieldvalue={user.address?.line1} />
      <LabelValueDisplayItem fieldname="City, State, Zip" fieldvalue={`${user.address?.city}, ${user.address?.stateCode}  ${user.address?.zipCode}`} />
    </Flex>
  );
}

export function ClientStyleDetailsDisplay({ user }: ClientDetailsDisplayProps) {
  if (!user) return <Box />;
  return (
    <Flex flexDir="column" flex={1}>
      <Text color="orange.400" fontWeight="bold" mb={1}>
        Styling Details
      </Text>
      <LabelValueDisplayItem fieldname="Style" fieldvalue={user.styleSummary} />
      <LabelValueDisplayItem fieldname="Fit Preference" fieldvalue={user.userSizeData?.preferredFit} />
      <LabelValueDisplayItem fieldname="Sizing (Tops)" fieldvalue={user.userSizeData?.sizingTops} />
      <LabelValueDisplayItem fieldname="Sizing (Bottoms)" fieldvalue={user.userSizeData?.sizingBottoms} />
      <LabelValueDisplayItem fieldname="Sizing (Dresses)" fieldvalue={user.userSizeData?.sizingDresses} />
      <LabelValueDisplayItem fieldname="Sizing (Shoes)" fieldvalue={user.userSizeData?.shoeSize} />
    </Flex>
  );
}

export function ClientContactDetailsDisplay({ user }: ClientDetailsDisplayProps) {
  if (!user) return <Box />;
  return (
    <Flex flexDir="column">
      <Text color="orange.400" fontWeight="bold" mb={1}>
        Contact Preferences
      </Text>
      <LabelValueDisplayItem fieldname="Email (preferred)" fieldvalue={user.email} />
      <LabelValueDisplayItem fieldname="Text (preferred)" fieldvalue={user.phone} />
      {/* <LabelValueDisplayItem fieldname="In app messaging" fieldvalue="Message->" /> */}
      <Flex flex={1} mb={0.75}>
        <Text flex={1} size="sm" color="orange.400">
          In app messaging
        </Text>
        <Link href="/messages" flex={1} size="sm">
          Message-&gt;
        </Link>
      </Flex>
    </Flex>
  );
}

export function ClientDetailsDisplay({ user }: ClientDetailsDisplayProps) {
  const navigate = useNavigate();
  if (!user) return <Box />;
  return (
    <Flex flexDir="column">
      <Flex mb={4}>
        <ClientPersonalDetailsDisplay user={user} />
        <ClientStyleDetailsDisplay user={user} />
      </Flex>
      <Flex flexDir="column" flex={0.5} maxW="50%">
        <ClientContactDetailsDisplay user={user} />
      </Flex>
      <Flex mt={6} alignItems="center">
        <Button
          bg="blue.400"
          color="charcoal"
          borderRadius="lg"
          size="sm"
          mr={8}
          onClick={() => navigate('/clientdetails', { state: { clientuserid: user.userid } })}
        >
          Go to user profile
        </Button>
        <Avatar src={user.useravatarurl} size="lg" />
      </Flex>
    </Flex>
  );
}

export default ClientDetailsDisplay;
