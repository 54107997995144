import { User as UserAuth } from 'firebase/auth';
import { OUTFIT_SERVICE_API, WARDROBE_API } from '../config/ServiceConstants';
import loggerService from './LoggerService';
import fetchWrapper from './fetchWrapper';
import { Outfit } from '../models/Outfit';
import { OutfitQuery } from '../models/OutfitQuery';

export function searchOutfits(userAuth: UserAuth, query?: OutfitQuery): Promise<any> {
  let url = `${OUTFIT_SERVICE_API}/search`;
  if (query) {
    url += '?';
    if (query.po && query.po > 0) {
      url += `&po=${query.po}`; // page offset, defaults to 0 for first page
    }
    if (query.ps) {
      url += `&ps=${query.ps}`; // page size, e.g. items per page
    }
    if (query.sf) {
      url += `&sf=${query.sf}`; // sort field, e.g. modifieddate
    }
    if (query.sd) {
      url += `&sd=${query.sd}`; // sort direction, e.g. asc or desc
    }
    if (query.userid) {
      // this tells the serivce to return the wardrobe items of the specified user
      url += `&userid=${query.userid}`;
    }
    if (query.createdby) {
      url += `&createdby=${query.createdby}`;
    }
    if (query.tags && query.tags.length > 0) {
      url += `&tags=${query.tags}`;
    }
    if (query.ownership && query.ownership.length > 0) {
      url += `&ownership=${query.ownership}`;
    }
    if (query.searchterm) {
      url += `&searchterm=${query.searchterm}`;
    }
    loggerService.debug('OutfitsService.searchOutfits.url: ', url);
  }
  return fetchWrapper(userAuth).get(url);
}

export function createOutfit(userAuth: UserAuth, outfit: Outfit): Promise<any> {
  const url = `${OUTFIT_SERVICE_API}`;
  return fetchWrapper(userAuth).post(url, outfit);
}

export function updateOutfit(userAuth: UserAuth, id: number, outfit: Outfit): Promise<any> {
  console.log('updating: ', outfit);
  const url = `${OUTFIT_SERVICE_API}/${id}`;
  return fetchWrapper(userAuth).put(url, outfit);
}

export function deleteOutfit(userAuth: UserAuth, id: number): Promise<any> {
  const url = `${OUTFIT_SERVICE_API}/${id}`;
  return fetchWrapper(userAuth).delete(url);
}
