/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
import {
  Box,
  LinkBox,
  Text,
  useToast,
  Flex,
  Heading,
  VStack,
  HStack,
  Checkbox,
  Input,
  Select,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from '@chakra-ui/react';
import { AddIcon, CloseIcon } from '@chakra-ui/icons';
import React, { useContext, useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { User as UserAuth } from 'firebase/auth';
import moment from 'moment';
import { auth } from '../config/firebase';
import { CurrentUserContext } from '../contexts/CurrentUserProvider';
import { ProfessionalToDo } from '../models/ProfessionalToDo';
import { fetchProfessionalToDos, createProfessionalToDo, deleteProfessionalToDo, updateProfessionalToDo } from '../services/ProfessionalsService';
import { CurrentProfessionalContext } from '../contexts/CurrentProfessionalProvider';
import MentionableTextInput from './MentionableTextInput';

function ToDos() {
  const [user] = useAuthState(auth);
  const { currentUser } = useContext(CurrentUserContext);
  const { currentProfessional } = useContext(CurrentProfessionalContext);
  const [todos, setToDos] = useState<ProfessionalToDo[]>([]);
  const toast = useToast();
  const [showNewToDoModal, setShowNewToDoModal] = useState(false);
  const [newToDoText, setNewToDoText] = useState('');
  const [newToDoDueDate, setNewToDoDueDate] = useState(new Date());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!user) return;
    if (!currentUser) return;
    if (!currentProfessional) return;
    if (todos && todos.length > 0) return;
    const getToDos = async () => {
      setLoading(true);
      await fetchProfessionalToDos(user, currentUser.userid)
        .then((res) => res.json())
        .then((result: ProfessionalToDo[]) => {
          const sorted =
            result &&
            result.sort((a, b) => {
              if (a && b && a.duedate && b.duedate) return b.duedate.valueOf() - a.duedate.valueOf();
              return 0;
            });
          setToDos(sorted);
          setLoading(false);
        });
    };

    getToDos().catch(console.error);
  }, [currentProfessional]); // [currentUser, currentProfessional, todos, user]

  const addItem = () => {
    const title = newToDoText;
    const duedate = newToDoDueDate;
    if (title === '') {
      toast({
        title: 'Missing info',
        description: 'Please enter some text',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    const newitem: ProfessionalToDo = {
      details: title,
      iscompleted: false,
      userid: currentUser.userid,
      created: undefined,
      duedate: duedate || new Date(),
      id: 0,
    };
    createProfessionalToDo(user as UserAuth, currentUser.userid, newitem as ProfessionalToDo)
      .then((res) => res.json())
      .then((result: ProfessionalToDo) => {
        console.log('new item: ', result);
        const clone = [...todos];
        clone.push(result);
        setToDos(clone);
      });
    setNewToDoDueDate(new Date());
    setNewToDoText('');
    setShowNewToDoModal(false);
  };

  const handleSort = (sortval: string) => {
    const clone = [...todos];
    const sorted = clone.sort((a, b) => {
      if (a && b && sortval === 'datedesc' && a.duedate && b.duedate) return b.duedate.valueOf() > a.duedate.valueOf() ? 1 : -1;
      if (a && b && sortval === 'dateasc' && a.duedate && b.duedate) return a.duedate.valueOf() > b.duedate.valueOf() ? 1 : -1;
      if (a && b && sortval === 'text') return a.details.localeCompare(b.details);
      if (a && b && sortval === 'status') return a.iscompleted ? -1 : 1;
      return 0;
    });
    setToDos(sorted);
  };

  const handleDelete = (index: number) => {
    setToDos((prevList) => {
      const temp = prevList.filter((_, itemI) => itemI !== index);
      return temp;
    });
    deleteProfessionalToDo(user as UserAuth, currentUser.userid, todos[index].id);
  };

  const handleStatusChange = (index: number) => {
    // const clone = structuredClone(todos[index]);
    const clone = JSON.parse(JSON.stringify(todos[index]));
    clone.iscompleted = !clone.iscompleted;
    updateProfessionalToDo(user as UserAuth, currentUser.userid, clone);
    setToDos((prevList) => {
      const newList = [...prevList];
      newList[index].iscompleted = !newList[index].iscompleted;
      return newList;
    });
  };

  if (loading) return <Spinner colorScheme="orange" size="xl" />;

  return (
    <Flex flex={1} width="100%">
      <Flex flex={1} flexDir="column">
        <Flex width="100%" mb={2} alignItems="center" pl={6}>
          <Text variant="header" my="2" flex={3} fontFamily="interregular">
            To Do List
          </Text>
          <Select
            variant="outline"
            placeholder="Sort..."
            flex={2}
            mx={1}
            bg="white"
            color="charcoal"
            borderColor="charcoal"
            fontWeight="bold"
            onChange={(e) => handleSort(e.target.value)}
          >
            <option value="datedesc">Newest</option>
            <option value="dateasc">Oldest</option>
            <option value="status">Status</option>
            <option value="text">Text</option>
          </Select>
          <Button ml={2} variant="solid" leftIcon={<AddIcon />} onClick={() => setShowNewToDoModal(true)}>
            Add New
          </Button>
        </Flex>
        <VStack borderWidth={1} borderRadius="lg" shadow="md" alignItems="flex-start">
          <VStack flex={1} w="100%" m={3} alignItems="flex-start" pr={2}>
            {todos && todos.length === 0 && <Text>No To Dos</Text>}
            {todos &&
              todos.map((item, itemI) => (
                <Flex flex={1} width="100%" key={itemI.toString()} py={2} pr={2}>
                  <Flex flex={0.5} width="100%" alignItems="center" justifyContent="center">
                    <Checkbox
                      flex={1}
                      colorScheme="orange"
                      iconColor="blue.400"
                      iconSize={2}
                      isChecked={item.iscompleted}
                      onChange={() => handleStatusChange(itemI)}
                      value={item.details}
                    />
                  </Flex>
                  <Box flex={8} width="100%">
                    <Text
                      width="100%"
                      flexShrink={1}
                      textAlign="left"
                      mx="2"
                      as={item.iscompleted ? 's' : undefined}
                      _light={{
                        color: item.iscompleted ? 'gray.400' : 'coolGray.800',
                      }}
                      _dark={{
                        color: item.iscompleted ? 'gray.400' : 'coolGray.50',
                      }}
                    >
                      {item.details}
                    </Text>
                  </Box>
                  <Box flex={4} width="100%" mx={2}>
                    <Text width="100%">{moment(item.duedate).calendar()}</Text>
                  </Box>
                  <LinkBox mr={1} onClick={() => handleDelete(itemI)}>
                    <CloseIcon h={4} w={4} color="blue.400" />
                  </LinkBox>
                </Flex>
              ))}
          </VStack>
        </VStack>
      </Flex>
      <Modal size="sm" isCentered isOpen={showNewToDoModal} onClose={() => setShowNewToDoModal(false)}>
        <ModalOverlay />
        <ModalContent borderRadius="lg">
          <ModalHeader>Add New To Do</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex flexDir="column" justifyContent="center" m={2}>
              <Text fontSize="sm">Note:</Text>
              {/* <Input onChange={(e) => setNewToDoText(e.target.value)} /> */}
              <MentionableTextInput value={newToDoText} handleOnChange={setNewToDoText} />
              <Text mt={2} fontSize="sm">
                Due date:
              </Text>
              <Input type="datetime-local" onChange={(e) => setNewToDoDueDate(new Date(e.target.value))} />
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button variant="darkoutline" mr={4} onClick={() => setShowNewToDoModal(false)}>
              Cancel
            </Button>
            <Button variant="solid" onClick={() => addItem()}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default ToDos;
