/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import moment from 'moment';
import {
  Box,
  Text,
  Heading,
  Flex,
  Select,
  Button,
  Center,
  Switch,
  Avatar,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Spinner,
  Input,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useToast,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Stat,
  StatLabel,
  StatNumber,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Icon,
} from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import { RiEditCircleFill } from 'react-icons/ri';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { User as UserAuth } from 'firebase/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { SmallAddIcon, CalendarIcon, ChevronRightIcon, EditIcon } from '@chakra-ui/icons';
import { BiMessageRounded } from 'react-icons/bi';
import { createColumnHelper } from '@tanstack/table-core';
import { Card, Legend, DonutChart } from '@tremor/react';
import { auth } from '../config/firebase';
import { Client } from '../models/Client';
import { createProfessionalClientNote, fetchClientForProfessional, fetchProfessionalNotesForClient } from '../services/ProfessionalsService';
import { ProfessionalClientNote } from '../models/ProfessionalClientNote';
import { CurrentProfessionalContext, CurrentProfessionalContextType } from '../contexts/CurrentProfessionalProvider';
import LabelValueDisplayItem from '../components/LabelValueDisplayItem';
import { Invitee, ScheduleItem } from '../models/ScheduleItem';
import { createScheduleItem, fetchUsersScheduleForClient } from '../services/ScheduleService';
import { DataTable } from '../components/DataTable';
import { fetchWardrobeStats } from '../services/WardrobeItemsService';
import { WardrobeStats } from '../models/WardrobeStats';
import '@tremor/react/dist/esm/tremor.css';
import '../theme/tremor-overrides.css';
import { ProfessionalEngagementServiceRecord } from '../models/ProfessionalEngagementServiceRecord';
import LoadingScreen from '../components/LoadingScreen';
import { CreateMessageDTO } from '../models/Message';
import { createMessage } from '../services/MessagesService';
import { PageTabs } from '../components/PageTabs';
import ClientWardrobeScreen from './ClientWardrobe';
import { EXPERT_SERVICE_OFFERINGS } from '../models/ExpertServiceOfferings';
import CustomDatePickerInput from '../components/CustomDatePickerInput';
import 'react-datepicker/dist/react-datepicker.css';
import '../theme/react-datepicker-overrides.module.css';
import { ProfessionalEngagementDetails } from '../models/ProfessionalEngagementDetails';
import InvoiceTable from '../components/invoices/InvoiceTable';
import { ServiceDetails } from '../models/ProfessionalUser';
import {
  fetchCurrentEngagementForProAndClient,
  updateEngagementRequest,
  createProfessionalEngagementServiceRecord,
  updateProfessionalEngagementServiceRecord,
  deleteProfessionalEngagementServiceRecord,
} from '../services/EngagementsService';
import TextInputWithLabel from '../components/TextInputWithLabel';
import { UserSizeData } from '../models/User';
import { updateUserSizeData } from '../services/UsersService';

type EngagementSummaryCardProps = {
  title: string;
  iconname: string;
  value: string | number;
  handleClick?: () => void | undefined;
};

function EngagementSummaryCard({ iconname, title, value, handleClick }: EngagementSummaryCardProps) {
  return (
    <Flex flexDir="column" borderWidth={1} borderRadius="lg" justifyContent="flex-start" alignItems="center" w={36} h={36} mr={4}>
      <Text fontSize="md" mt={2}>
        {title}
      </Text>
      <Text color="charcoal" mt={1} mb={2}>
        {value}
      </Text>
      <Image src={`/icons/${iconname}.png`} mt={2} alt={iconname} boxSize={8} />
      {(iconname === 'budget' || iconname === 'deadline') && (
        <Icon as={RiEditCircleFill} boxSize={4} color="blue.400" transform="translateY(-10px) translateX(20px)" onClick={handleClick} />
      )}
    </Flex>
  );
}

function ClientDetailsScreen() {
  const { currentProfessional }: CurrentProfessionalContextType = useContext(CurrentProfessionalContext);
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const focusRef = useRef<HTMLButtonElement>(null);
  const toast = useToast();
  const cancelRef = React.useRef() as React.MutableRefObject<any>;
  const { isOpen: isCompleteEnagementOpen, onOpen: onCompleteEngagementOpen, onClose: onCompleteEngagementClose } = useDisclosure();
  const { state } = useLocation();
  const { clientuserid, tab } = state;
  const [selectedTab, setSelectedTab] = useState(tab || 'Client Overview');
  const [client, setClient] = useState<Client | undefined>();
  const [currentEngagementDetails, setCurrentEngagementDetails] = useState<ProfessionalEngagementDetails>();
  const [budgetAndDeadlineRequired, setBudgetAndDeadlineRequired] = useState<boolean>(false);
  const [engagementBudget, setEngagementBudget] = useState<number | undefined>();
  const [engagementDeadline, setEngagementDeadline] = useState<Date | null>();
  const [clientNotes, setClientNotes] = useState<ProfessionalClientNote[]>([]);
  const [appointments, setAppointments] = useState<ScheduleItem[]>([]);
  const [serviceOptions, setServiceOptions] = useState<any[]>([]);
  const [selectedServiceRecord, setSelectedServiceRecord] = useState<ProfessionalEngagementServiceRecord | undefined>(undefined);
  const [wardrobeStats, setWardrobeStats] = useState<WardrobeStats>();
  const [showNewNoteModal, setShowNewNoteModal] = useState(false);
  const [newNoteText, setNewNoteText] = useState('');
  const [showNewMessageModal, setShowNewMessageModal] = useState(false);
  const [showNewAppointmentModal, setShowNewAppointmentModal] = useState(false);
  const [appointmentToModify, setAppointmentToModify] = useState<ScheduleItem | undefined>();
  const [newMessageBody, setNewMessageBody] = useState('');
  const [styleDetailsInEditMode, setStyleDetailsInEditMode] = useState(false);
  const [styleSizeDetailsInEditMode, setStyleSizeDetailsInEditMode] = useState(false);
  const [clientSizeData, setClientSizeData] = useState<UserSizeData>();

  useEffect(() => {
    if (!user) return;
    const fetchClient = async () => {
      await fetchClientForProfessional(user, clientuserid)
        .then((res) => res.json())
        .then((result: Client) => {
          setClient(result);
          setClientSizeData(result.user.userSizeData);
        });
    };
    fetchClient().catch(console.error);
  }, [clientuserid, user]);

  useEffect(() => {
    if (!user) return;
    if (!currentProfessional) return;
    const fetchCurrentEngagement = async () => {
      await fetchCurrentEngagementForProAndClient(user, currentProfessional.userid, clientuserid)
        .then((res) => res.json())
        .then((result: ProfessionalEngagementDetails) => {
          if (!result) console.warn('WARN: no active engagement found for this pro and client');
          if (!result.serviceRecords) result.serviceRecords = [];
          result.serviceRecords.sort((a, b) => ((a.start as unknown as Date) > (b.start as unknown as Date) ? -1 : 1));
          if (!result.engagement.budget || !result.engagement.targetEndDate) setBudgetAndDeadlineRequired(true);
          if (result.engagement.budget) setEngagementBudget(result.engagement.budget);
          if (result.engagement.targetEndDate) setEngagementDeadline(result.engagement.targetEndDate);
          setCurrentEngagementDetails(result);
        });
    };
    fetchCurrentEngagement().catch(console.error);
  }, [clientuserid, currentProfessional, user]);

  useEffect(() => {
    if (!user) return;
    if (!currentProfessional) return;
    const fetchNotes = async () => {
      await fetchProfessionalNotesForClient(user, currentProfessional.userid, clientuserid)
        .then((res) => res.json())
        .then((result: ProfessionalClientNote[]) => {
          setClientNotes(result);
        });
    };
    fetchNotes().catch(console.error);
  }, [clientuserid, currentProfessional, user]);

  useEffect(() => {
    if (!user) return;
    if (!currentProfessional) return;
    const fetchAppointments = async () => {
      await fetchUsersScheduleForClient(user, currentProfessional.userid, clientuserid)
        .then((res) => res.json())
        .then((result: ScheduleItem[]) => {
          if (!result || result.length === 0) return;
          const pendingappointments = result.filter((a) => a.status.toLowerCase() === 'pending' || a.status.toLowerCase() === 'active');
          setAppointments(pendingappointments);
        });
    };
    fetchAppointments().catch(console.error);
  }, [clientuserid, currentProfessional, user]);

  useEffect(() => {
    if (!clientuserid || !user) return;
    const fetchClientStats = async () => {
      await fetchWardrobeStats(user, clientuserid)
        .then((res) => res.json())
        .then((result: WardrobeStats) => {
          result.wardrobeStatistics.forEach((ws) => {
            ws.stats = ws.stats.filter((s) => s.item2 > 0);
          });
          setWardrobeStats(result);
        });
    };
    fetchClientStats().catch(console.error);
  }, [user, clientuserid]);

  useEffect(() => {
    if (!currentProfessional) return;
    const servicesOfferedByPro = currentProfessional.serviceDetails?.map((sd) => sd.serviceName);
    const servicesOffered = EXPERT_SERVICE_OFFERINGS.filter((so) => servicesOfferedByPro.includes(so.value));
    if (servicesOffered && servicesOffered.length > 0) setServiceOptions(servicesOffered);
    else setServiceOptions(EXPERT_SERVICE_OFFERINGS);
  }, [currentProfessional]);

  const renderClientDetails = () => {
    if (!client) return <Box />;
    return (
      <Flex flexDir="column" flex={1} mr={3}>
        <Text color="charcoal" fontWeight="bold" fontSize="lg" mb={1.5}>
          Client
        </Text>
        <Flex flex={1} flexDir="column">
          <Flex borderWidth={1} borderRadius="md" p={4} flexDir="column">
            <Flex mb={2}>
              <Avatar src={client.user.useravatarurl} size="xl" />
            </Flex>
            <Text fontWeight="bold" my={1}>
              Personal Details
            </Text>
            <LabelValueDisplayItem fieldname="Name" fieldvalue={client.user.username} />
            <LabelValueDisplayItem fieldname="Age" fieldvalue={moment().diff(client.user.birthday, 'years', false).toString()} />
            {/* <LabelValueDisplayItem fieldname="Profession / Industry" fieldvalue="Banking (TODO)" /> */}
            <LabelValueDisplayItem fieldname="Address" fieldvalue={client.user.address?.line1} />
            <LabelValueDisplayItem
              fieldname="City, State, Zip"
              fieldvalue={`${client.user.address?.city}, ${client.user.address?.stateCode}  ${client.user.address?.zipCode}`}
            />
            <Text fontWeight="bold" my={1}>
              Contact Preferences
            </Text>
            <LabelValueDisplayItem fieldname="Email (preferred)" fieldvalue={client.user.email} />
            <LabelValueDisplayItem fieldname="Text (preferred)" fieldvalue={client.user.phone} />
          </Flex>
        </Flex>
      </Flex>
    );
  };

  const renderRequestSummary = () => {
    if (!client) return <Box />;
    if (!currentEngagementDetails) return <Box />;
    return (
      <Flex flexDir="column" flex={1} mt={6} mb={4}>
        <Flex mb={1.5}>
          <Text color="charcoal" fontWeight="bold" fontSize="lg">
            Request Summary
          </Text>
        </Flex>
        <Flex flex={1} flexDir="column">
          <Flex borderWidth={1} borderRadius="md" py={6} px={8} flex={1}>
            <Flex flexDir="column" flex={1}>
              <Text fontWeight="bold" my={1}>
                Services Requested
              </Text>
              {currentEngagementDetails?.engagement?.requestedservices?.map((rs, i) => {
                const prosvcdetails: ServiceDetails = currentProfessional.serviceDetails.filter((sd) => sd.serviceName === rs)[0];
                return (
                  <Flex key={i.toString()} my={1} justifyContent="space-between" maxW="50%">
                    <Text color="charcoal">{rs}</Text>
                    <Text>
                      ${prosvcdetails?.pricepoint} / {prosvcdetails?.pricetype} @ ${prosvcdetails?.averagevalue} average
                    </Text>
                  </Flex>
                );
              })}
              {budgetAndDeadlineRequired && (
                <Box bg="olive.400" borderRadius="lg" px={2} py={1} my={2} maxW="50%">
                  <Text color="white" fontWeight="bold">
                    Total Earnings Potential: ${currentEngagementDetails.engagement.estimatedvalue}
                  </Text>
                </Box>
              )}
            </Flex>
            <Flex flexDir="column" flex={1}>
              <Text fontWeight="bold" my={1}>
                Request Info
              </Text>
              <LabelValueDisplayItem fieldname="Message" fieldvalue={`${currentEngagementDetails.engagement.requestnotes}`} />
              <LabelValueDisplayItem fieldname="Availability" fieldvalue={`${currentEngagementDetails.engagement.requesttimeframe}`} />
              <LabelValueDisplayItem
                fieldname="In Person or Virtual"
                fieldvalue={`${currentEngagementDetails.engagement.isinperson ? 'In Person' : 'Virtual'}`}
              />
              <LabelValueDisplayItem fieldname="Location" fieldvalue={`${currentEngagementDetails.engagement.clientlocation}`} />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    );
  };

  const renderCurrentEngagementSummary = () => {
    if (!client) return <Box />;
    if (!currentEngagementDetails) return <Box />;
    if (!currentEngagementDetails.engagement) return <Box />;

    const saveBudgetAndDeadline = async () => {
      const clone: ProfessionalEngagementDetails = structuredClone(currentEngagementDetails);
      clone.engagement.budget = engagementBudget;
      clone.engagement.targetEndDate = engagementDeadline || undefined;
      setCurrentEngagementDetails(clone);
      await updateEngagementRequest(user as UserAuth, currentProfessional.userid, currentEngagementDetails.engagement.id, clone.engagement)
        .then(() => setBudgetAndDeadlineRequired(false))
        .catch((err) => console.log(err));
    };

    return (
      <Flex flexDir="column" flex={1} mt={6} mb={4}>
        <Flex mb={1.5}>
          <Text color="charcoal" fontWeight="bold" fontSize="lg">
            Engagement Summary
          </Text>
        </Flex>
        <Flex flex={1}>
          {budgetAndDeadlineRequired && (
            <Flex flexDir="column" alignItems="center" justifyContent="center" flex={1} borderWidth={1}>
              <Text my={6} fontSize="md">
                Set a budget and deadline for personal tracking.
              </Text>
              <Flex flexDir="column" maxW="50%" mt={2}>
                <Text>Engagement Budget</Text>
                <Input type="number" placeholder="$" value={engagementBudget} onChange={(e) => setEngagementBudget(e.target.valueAsNumber)} />
                <Text mt={2}>Engagement Deadline</Text>
                <Flex mt={2} alignItems="center">
                  {/* <CalendarIcon mr={1} /> */}
                  <Input
                    type="date"
                    value={engagementDeadline ? moment(engagementDeadline).format('YYYY-MM-D') : ''}
                    onChange={(e) => setEngagementDeadline(e.target.valueAsDate)}
                  />
                </Flex>
                <Button my={8} variant="solid" onClick={saveBudgetAndDeadline}>
                  Save
                </Button>
              </Flex>
            </Flex>
          )}
          {!budgetAndDeadlineRequired && (
            <Flex flex={1}>
              <EngagementSummaryCard
                iconname="budget"
                title="Budget"
                value={`$${currentEngagementDetails.engagement.budget}`}
                handleClick={() => setBudgetAndDeadlineRequired(true)}
              />
              <EngagementSummaryCard
                iconname="budget-used"
                title="Budget Used"
                value={`$${currentEngagementDetails.invoicedamount + currentEngagementDetails.outstandingcharges}`}
              />
              <EngagementSummaryCard iconname="budget-left" title="Budget Remaining" value={`$${currentEngagementDetails.remainingbudget}`} />
              <EngagementSummaryCard iconname="billed-time" title="Billed Time" value={`${currentEngagementDetails.totalhours} hours`} />
              <EngagementSummaryCard
                iconname="deadline"
                title="Deadline"
                // value={`${moment(currentEngagementDetails.engagement.targetEndDate).diff(moment(), 'days')} days left`}
                value={moment(currentEngagementDetails.engagement.targetEndDate).format('MMM DD YYYY')}
                handleClick={() => setBudgetAndDeadlineRequired(true)}
              />
            </Flex>
          )}
        </Flex>
      </Flex>
    );
  };

  const addNote = () => {
    const title = newNoteText;
    const date = new Date();
    if (title === '') {
      toast({
        title: 'Missing info',
        description: 'Please enter some text',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    const newitem: ProfessionalClientNote = {
      id: 0,
      prouserid: currentProfessional.userid,
      clientuserid,
      note: title,
      isdeleted: false,
      createddate: date,
      modifieddate: date,
    };
    createProfessionalClientNote(user as UserAuth, currentProfessional.userid, newitem as ProfessionalClientNote)
      .then((res) => res.json())
      .then((result: ProfessionalClientNote) => {
        const clone = [...clientNotes];
        clone.push(result);
        setClientNotes(clone);
      });
    setNewNoteText('');
    setShowNewNoteModal(false);
  };

  const handleSendMessage = async () => {
    const msg: CreateMessageDTO = {
      messageBody: newMessageBody,
      recipients: [clientuserid],
    };
    await createMessage(user as UserAuth, msg).then(() => {
      toast({
        title: 'Message sent',
        description: 'Message sent successfully.',
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
    });
  };

  const handleCompleteEngagement = async () => {
    if (!user) return;
    if (!currentEngagementDetails) return;
    currentEngagementDetails.engagement.status = 'Completed';
    await updateEngagementRequest(user, user.uid, currentEngagementDetails.engagement.id, currentEngagementDetails.engagement)
      .then((result) => {
        setCurrentEngagementDetails({ ...currentEngagementDetails, engagement: result });
      })
      .then(() => navigate('/clients'))
      .catch((er) => console.log(er));
  };

  const renderNotesSection = () => {
    if (!client) return <Box />;
    return (
      <Flex flexDir="column" flex={1} ml={3} height="100%">
        <Flex justifyContent="space-between" alignItems="center" mb={1}>
          <Text color="charcoal" fontWeight="bold" fontSize="lg">
            Notes
          </Text>
          <Button variant="solid" maxH={6} maxW="20%" leftIcon={<SmallAddIcon />} onClick={() => setShowNewNoteModal(true)}>
            Add Note
          </Button>
        </Flex>
        <Flex flex={1} flexDir="column">
          <Flex borderWidth={1} borderRadius="md" p={4} flexDir="column">
            {clientNotes &&
              clientNotes.map((note: ProfessionalClientNote) => (
                <Flex key={note.id} flex={1} width="100%" fontSize="xs" p={4} justifyContent="space-between" alignItems="center" borderBottomWidth={1}>
                  <Text flex={9} mr="15%">
                    {note.note}
                  </Text>
                  <Text flex={1}>{moment(note.createddate).format('MM/DD/YY')}</Text>
                </Flex>
              ))}
          </Flex>
        </Flex>
        <Modal size="sm" isCentered isOpen={showNewNoteModal} onClose={() => setShowNewNoteModal(false)}>
          <ModalOverlay />
          <ModalContent borderRadius="lg">
            <ModalHeader>Add New Note</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex flexDir="column" justifyContent="center" m={2}>
                <Text fontSize="sm">Note:</Text>
                <Textarea onChange={(e) => setNewNoteText(e.target.value)} />
              </Flex>
            </ModalBody>
            <ModalFooter>
              <Button variant="darkoutline" mr={4} onClick={() => setShowNewNoteModal(false)}>
                Cancel
              </Button>
              <Button variant="solid" onClick={() => addNote()}>
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
    );
  };

  const renderAppointmentsSection = () => {
    if (!client) return <Box />;
    type scheduleDisplayData = {
      title: string;
      day: string;
      time: string;
      description: string;
      id: number;
    };

    const tabledata: scheduleDisplayData[] = appointments?.map((i) => ({
      title: i.appointmentType,
      day: moment(i.startDateTime).format('MM-DD-YYYY'),
      time: `${moment(i.startDateTime).format('hh:mm A')}-${moment(i.endDateTime).format('hh:mm A')}`,
      description: i.description,
      id: i.id,
    }));

    const columnHelper = createColumnHelper<scheduleDisplayData>();

    const columns = [
      columnHelper.accessor('title', {
        cell: (info: any) => info.getValue(),
        header: 'Title',
      }),
      columnHelper.accessor('day', {
        cell: (info: any) => info.getValue(),
        header: 'Day',
      }),
      columnHelper.accessor('time', {
        cell: (info: any) => info.getValue(),
        header: 'Time',
      }),
      columnHelper.accessor('description', {
        cell: (info: any) => info.getValue(),
        header: 'Description',
      }),
    ];

    const handleSaveAppointment = () => {
      if (!appointmentToModify) return;
      if (!user) return;

      appointmentToModify.id = 0;
      appointmentToModify.createdby = currentProfessional.userid;
      appointmentToModify.status = 'Active';
      // appointmentToModify.appointmentType = 'Appointment';
      appointmentToModify.description = `Appointment with ${client.user.username}`;
      appointmentToModify.invitees = [];
      const host: Invitee = {
        userid: currentProfessional.userid,
        attendeeRole: 'host',
        attendanceOptional: false,
        attendanceStatus: 'Accepted',
      };
      appointmentToModify.invitees.push(host);
      const attendee: Invitee = {
        userid: clientuserid,
        attendeeRole: 'required',
        attendanceOptional: false,
        attendanceStatus: 'Pending',
      };
      appointmentToModify.invitees.push(attendee);

      createScheduleItem(user, appointmentToModify)
        .then((res) => res.json())
        .then((result) => {
          const clone = structuredClone(appointments);
          clone.push(result);
          setAppointments(clone);
        })
        .catch((er) => console.log(er));
      setAppointmentToModify(undefined);
    };

    const rowSelectedHandler = (index: number) => {
      const rowid = tabledata[index].id;
      const appointment = appointments.filter((i) => i.id === rowid)[0];
      // format dates so that datepicker can ingest them:
      appointment.startDateTime = moment(appointment.startDateTime, 'YYY-MM-DD HH:mm:ss').toDate();
      appointment.endDateTime = moment(appointment.endDateTime, 'YYY-MM-DD HH:mm:ss').toDate();
      setAppointmentToModify(appointment);
      setShowNewAppointmentModal(true);
    };

    return (
      <>
        <Flex flexDir="column" flex={1} mr={3} height="100%">
          <Flex mb={4} justifyContent="space-between" alignItems="center">
            <Text color="charcoal" fontWeight="bold" fontSize="lg">
              Appointments
            </Text>
            <Flex>
              <Button variant="darkoutline" onClick={() => navigate('/availablity')} mr={4}>
                See all
              </Button>
              <Button variant="solid" leftIcon={<SmallAddIcon />} onClick={() => setShowNewAppointmentModal(true)}>
                Add Appointment
              </Button>
            </Flex>
          </Flex>
          <Flex flex={1} width="100%">
            <DataTable columns={columns} data={tabledata} shadeAlternatingRows={false} handleRowSelected={rowSelectedHandler} />
          </Flex>
        </Flex>
        <Modal size="md" isCentered isOpen={showNewAppointmentModal} onClose={() => setShowNewAppointmentModal(false)}>
          <ModalOverlay />
          <ModalContent borderRadius="lg">
            <ModalHeader>Add Appointment</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex flexDir="column" justifyContent="center" m={2}>
                <Flex flexDir="column">
                  <Text fontSize="sm">Name</Text>
                  <Input
                    value={appointmentToModify?.appointmentType}
                    onChange={(e) => setAppointmentToModify({ ...appointmentToModify, appointmentType: e.target.value } as ScheduleItem)}
                  />
                </Flex>
                <Flex flexDir="column" mt={2}>
                  <Text fontSize="sm">Start Date/Time</Text>
                  <DatePicker
                    customInput={<CustomDatePickerInput />}
                    isClearable
                    value="Set Appointment Date"
                    showTimeSelect
                    selected={appointmentToModify?.startDateTime ? (appointmentToModify.startDateTime as Date) : new Date()}
                    onChange={(date) => setAppointmentToModify({ ...appointmentToModify, startDateTime: date } as ScheduleItem)}
                  />
                  <Text ml={2}>{appointmentToModify?.startDateTime ? moment(appointmentToModify?.startDateTime).format('MM/DD/YY h:mm A') : ''}</Text>
                </Flex>
                <Flex flexDir="column" mt={2}>
                  <Text fontSize="sm">End Date/Time</Text>
                  <DatePicker
                    customInput={<CustomDatePickerInput />}
                    isClearable
                    value="Set Appointment Date"
                    showTimeSelect
                    selected={appointmentToModify?.endDateTime ? (appointmentToModify.endDateTime as Date) : new Date()}
                    onChange={(date) => setAppointmentToModify({ ...appointmentToModify, endDateTime: date } as ScheduleItem)}
                  />
                  <Text ml={2}>{appointmentToModify?.endDateTime ? moment(appointmentToModify?.endDateTime).format('MM/DD/YY h:mm A') : ''}</Text>
                </Flex>
                <Flex my={2} alignItems="center">
                  <Text mr={2}>All Day?</Text>
                  <Switch
                    size="sm"
                    colorScheme="orange"
                    isChecked={appointmentToModify?.allDay}
                    onChange={() => {
                      if (!appointmentToModify) return;
                      setAppointmentToModify({
                        ...appointmentToModify,
                        allDay: !appointmentToModify.allDay,
                        startDateTime: appointmentToModify.startDateTime.setHours(0, 0, 0),
                        endDateTime: appointmentToModify.endDateTime.setHours(23, 59, 59),
                      } as unknown as ScheduleItem);
                    }}
                  />
                </Flex>
              </Flex>
            </ModalBody>
            <ModalFooter my={2}>
              <Button variant="darkoutline" mr={4} onClick={() => setShowNewAppointmentModal(false)}>
                Cancel
              </Button>
              <Button
                variant="solid"
                onClick={() => {
                  handleSaveAppointment();
                  setShowNewAppointmentModal(false);
                }}
              >
                Add Appointment
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  };

  const renderStyleSection = () => {
    if (!client) return <Box />;
    if (!wardrobeStats) return <Spinner />;
    const valueFormatter = (number: number) => `${Intl.NumberFormat('us').format(number).toString()}`;
    const statgroup = wardrobeStats?.wardrobeStatistics[0];
    const formattedStyleData = !statgroup
      ? []
      : statgroup?.stats?.map((s) => ({
          name: s.item1,
          portion: s.item2,
        }));
    const formattedPrimaryCategoryData = !wardrobeStats?.wardrobeStatistics[1]
      ? []
      : wardrobeStats.wardrobeStatistics[1].stats?.map((s) => ({
          name: s.item1,
          portion: s.item2,
        }));

    const handleSaveClientSizeData = async () => {
      if (!clientSizeData) return;
      await updateUserSizeData(user as UserAuth, client.user.userid, clientSizeData)
        .then(() => {
          const clone: Client = structuredClone(client);
          client.user.userSizeData = clientSizeData;
          setClient(clone);
        })
        .then(() => {
          setStyleDetailsInEditMode(false);
          setStyleSizeDetailsInEditMode(false);
        })
        .catch((e) => console.log(e));
    };

    return (
      <Flex flex={1} height="100%" mb={16} flexWrap="wrap">
        <Flex flexDir="column" flex={4} height="100%" flexWrap="wrap">
          <Flex flex={1} flexWrap="wrap">
            {wardrobeStats && (
              <>
                <Flex flex={1} flexWrap="wrap">
                  <Card maxWidth="max-w-lg">
                    <Text color="charcoal" fontWeight="bold" fontSize="lg" mb={2}>
                      Style
                    </Text>
                    <DonutChart
                      data={formattedStyleData}
                      variant="pie"
                      showLabel={false}
                      showAnimation
                      category="portion"
                      dataKey="name"
                      showTooltip
                      valueFormatter={valueFormatter}
                      // marginTop="mt-6"
                      colors={['slate', 'violet', 'red', 'indigo', 'zinc', 'cyan', 'amber', 'emerald', 'fuchsia', 'orange', 'stone', 'yellow', 'rose']}
                    />
                    <Center>
                      <Legend
                        categories={statgroup ? statgroup.stats?.map((s) => s.item1) : []}
                        colors={['slate', 'violet', 'red', 'indigo', 'zinc', 'cyan', 'amber', 'emerald', 'fuchsia', 'orange', 'stone', 'yellow', 'rose']}
                        marginTop="mt-3"
                      />
                    </Center>
                  </Card>
                </Flex>
                <Flex ml={6} flex={1} flexWrap="wrap">
                  <Card maxWidth="max-w-lg">
                    <Text color="charcoal" fontWeight="bold" fontSize="lg" mb={2}>
                      Items
                    </Text>
                    <DonutChart
                      data={formattedPrimaryCategoryData}
                      variant="pie"
                      showLabel={false}
                      showAnimation
                      category="portion"
                      dataKey="name"
                      showTooltip
                      valueFormatter={valueFormatter}
                      // marginTop="mt-6"
                      colors={['slate', 'violet', 'indigo', 'rose', 'cyan', 'amber', 'emerald', 'lime']}
                    />
                    <Center>
                      <Legend
                        categories={wardrobeStats?.wardrobeStatistics[1] ? wardrobeStats?.wardrobeStatistics[1].stats?.map((s) => s.item1) : []}
                        colors={['slate', 'violet', 'indigo', 'rose', 'cyan', 'amber', 'emerald', 'lime']}
                        marginTop="mt-3"
                      />
                    </Center>
                  </Card>
                </Flex>
              </>
            )}
          </Flex>
          <Flex mt={6} flex={1} flexWrap="wrap">
            {!styleDetailsInEditMode && (
              <Flex flex={1}>
                <Card maxWidth="max-w-lg">
                  <Flex flexDir="row" justifyContent="space-between" alignItems="center">
                    <Text fontWeight="bold" mb={2}>
                      Styling Details
                    </Text>
                    <EditIcon color="orange.400" onClick={() => setStyleDetailsInEditMode(true)} />
                  </Flex>
                  <LabelValueDisplayItem fieldname="Style" fieldvalue={client.user.styleSummary} />
                  <LabelValueDisplayItem fieldname="Fit Preference" fieldvalue={clientSizeData?.preferredFit} />
                  <LabelValueDisplayItem fieldname="Sizing (Tops)" fieldvalue={clientSizeData?.sizingTops} />
                  <LabelValueDisplayItem fieldname="Sizing (Bottoms)" fieldvalue={clientSizeData?.sizingBottoms} />
                  <LabelValueDisplayItem fieldname="Sizing (Dresses)" fieldvalue={clientSizeData?.sizingDresses} />
                  <LabelValueDisplayItem fieldname="Sizing (Shoes)" fieldvalue={clientSizeData?.shoeSize} />
                </Card>
              </Flex>
            )}
            {styleDetailsInEditMode && (
              <Flex flex={1}>
                <Card maxWidth="max-w-lg">
                  <Text fontWeight="bold" mb={2}>
                    Styling Details
                  </Text>
                  <LabelValueDisplayItem fieldname="Style" fieldvalue={client.user.styleSummary} />
                  <TextInputWithLabel
                    fieldname="Fit Preference"
                    fieldvalue={clientSizeData?.preferredFit}
                    fieldChangeHandler={(val: string) => setClientSizeData({ ...clientSizeData, preferredFit: val } as unknown as UserSizeData)}
                  />
                  <TextInputWithLabel
                    fieldname="Sizing (Tops)"
                    fieldvalue={clientSizeData?.sizingTops}
                    fieldChangeHandler={(val: string) => setClientSizeData({ ...clientSizeData, sizingTops: val } as unknown as UserSizeData)}
                  />
                  <TextInputWithLabel
                    fieldname="Sizing (Bottoms)"
                    fieldvalue={clientSizeData?.sizingBottoms}
                    fieldChangeHandler={(val: string) => setClientSizeData({ ...clientSizeData, sizingBottoms: val } as unknown as UserSizeData)}
                  />
                  <TextInputWithLabel
                    fieldname="Sizing (Dresses)"
                    fieldvalue={clientSizeData?.sizingDresses}
                    fieldChangeHandler={(val: string) => setClientSizeData({ ...clientSizeData, sizingDresses: val } as unknown as UserSizeData)}
                  />
                  <TextInputWithLabel
                    fieldname="Sizing (Shoes)"
                    fieldvalue={clientSizeData?.shoeSize}
                    fieldChangeHandler={(val: string) => setClientSizeData({ ...clientSizeData, shoeSize: val } as unknown as UserSizeData)}
                  />
                  <Flex flexDir="row" justifyContent="space-between" alignItems="center" mt={4}>
                    <Button variant="darkoutline" onClick={() => setStyleDetailsInEditMode(false)}>
                      Cancel
                    </Button>
                    <Button variant="solid" onClick={handleSaveClientSizeData}>
                      Save
                    </Button>
                  </Flex>
                </Card>
              </Flex>
            )}
            {!styleSizeDetailsInEditMode && (
              <Flex ml={6} flex={1}>
                <Card maxWidth="max-w-lg">
                  <Flex flexDir="row" justifyContent="space-between" alignItems="center" mr={10}>
                    <Text fontWeight="bold" mb={2}>
                      Size Details
                    </Text>
                    <EditIcon color="orange.400" onClick={() => setStyleSizeDetailsInEditMode(true)} />
                  </Flex>
                  <LabelValueDisplayItem fieldname="Height" fieldvalue={clientSizeData?.heightInInches} />
                  <LabelValueDisplayItem fieldname="Weight" fieldvalue={clientSizeData?.weightInPounds} />
                  <LabelValueDisplayItem fieldname="Bust" fieldvalue={clientSizeData?.bustInInches} />
                  <LabelValueDisplayItem fieldname="Hips" fieldvalue={clientSizeData?.hipsInInches} />
                </Card>
              </Flex>
            )}
            {styleSizeDetailsInEditMode && (
              <Flex ml={6} flex={1}>
                <Card maxWidth="max-w-lg">
                  <Text fontWeight="bold" mb={2}>
                    Size Details
                  </Text>
                  <TextInputWithLabel
                    fieldname="Height"
                    fieldvalue={clientSizeData?.heightInInches}
                    fieldChangeHandler={(val: string) => setClientSizeData({ ...clientSizeData, heightInInches: val } as unknown as UserSizeData)}
                  />
                  <TextInputWithLabel
                    fieldname="Weight"
                    fieldvalue={clientSizeData?.weightInPounds}
                    fieldChangeHandler={(val: string) => setClientSizeData({ ...clientSizeData, weightInPounds: val } as unknown as UserSizeData)}
                  />
                  <TextInputWithLabel
                    fieldname="Bust"
                    fieldvalue={clientSizeData?.bustInInches}
                    fieldChangeHandler={(val: string) => setClientSizeData({ ...clientSizeData, bustInInches: val } as unknown as UserSizeData)}
                  />
                  <TextInputWithLabel
                    fieldname="Hips"
                    fieldvalue={clientSizeData?.hipsInInches}
                    fieldChangeHandler={(val: string) => setClientSizeData({ ...clientSizeData, hipsInInches: val } as unknown as UserSizeData)}
                  />
                  <Flex flexDir="row" justifyContent="space-between" alignItems="center" mt={4}>
                    <Button variant="darkoutline" onClick={() => setStyleSizeDetailsInEditMode(false)}>
                      Cancel
                    </Button>
                    <Button variant="solid" onClick={handleSaveClientSizeData}>
                      Save
                    </Button>
                  </Flex>
                </Card>
              </Flex>
            )}
          </Flex>
        </Flex>
        <Flex flexDir="column" flex={1} ml={3} mb={6} height="100%" flexWrap="wrap">
          <Flex flexDir="column" mb={8} alignItems="center">
            <Stat borderWidth={1} p={6} borderRadius="md" display="flex" flex={1} width="100%" alignItems="stretch" justifyContent="center">
              <StatLabel color="charcoal" fontWeight="bold">
                Items
              </StatLabel>
              <StatNumber>{wardrobeStats.wardrobeItemsCount}</StatNumber>
            </Stat>
          </Flex>
          <Flex flexDir="column" mb={8} alignItems="center" flex={1}>
            <Stat borderWidth={1} p={6} borderRadius="md" display="flex" flex={1} width="100%" alignItems="stretch" justifyContent="center">
              <StatLabel color="charcoal" fontWeight="bold">
                Outfits
              </StatLabel>
              <StatNumber>{wardrobeStats.outfitsCount}</StatNumber>
            </Stat>
          </Flex>
          <Flex flexDir="column" mb={8} alignItems="center">
            <Stat borderWidth={1} p={6} borderRadius="md" display="flex" flex={1} width="100%" alignItems="stretch" justifyContent="center">
              <StatLabel color="charcoal" fontWeight="bold">
                LookBooks
              </StatLabel>
              <StatNumber>{wardrobeStats.lookBooksCount}</StatNumber>
            </Stat>
          </Flex>
          <Flex justifyContent="center" alignItems="center">
            <Button variant="solid" onClick={() => setSelectedTab('Wardrobe')}>
              Go to Wardrobe
            </Button>
          </Flex>
        </Flex>
      </Flex>
    );
  };

  const renderServiceHistorySection = () => {
    if (!client) return <Box />;
    if (!currentEngagementDetails) return <Box />;

    type serviceRecordsDisplayData = {
      service: string;
      start: string;
      end: string;
      hours: string;
      notes: string;
      invoice?: number;
      id: number;
    };

    const tabledata: serviceRecordsDisplayData[] = currentEngagementDetails?.serviceRecords?.map((i) => ({
      service: i.service as string,
      start: moment(i.start).format('MM/DD/YY HH:mm'),
      end: i.end ? moment(i.end).format('MM/DD/YY HH:mm') : '',
      hours: i.end ? moment(i.end).diff(moment(i.start), 'hours', true).toFixed(1) : '',
      notes: i.notes as string,
      invoice: i.invoiceid,
      id: i.id,
    }));

    const columnHelper = createColumnHelper<serviceRecordsDisplayData>();

    const columns = [
      columnHelper.accessor('service', {
        cell: (info: any) => info.getValue(),
        header: 'Service',
      }),
      columnHelper.accessor('start', {
        cell: (info: any) => info.getValue(),
        header: 'Start',
      }),
      columnHelper.accessor('end', {
        cell: (info: any) => info.getValue(),
        header: 'End',
      }),
      columnHelper.accessor('hours', {
        cell: (info: any) => info.getValue(),
        header: 'Hours',
      }),
      columnHelper.accessor('notes', {
        cell: (info: any) => info.getValue(),
        header: 'Notes',
      }),
      columnHelper.accessor('invoice', {
        cell: (info: any) => info.getValue(),
        header: 'Invoice #',
      }),
    ];

    const rowSelectedHandler = (index: number) => {
      const rowid = tabledata[index].id;
      // const record = currentEngagementDetails.serviceRecords.filter((i) => i.id === rowid)[0];
      const record: ProfessionalEngagementServiceRecord = structuredClone(currentEngagementDetails.serviceRecords.filter((i) => i.id === rowid)[0]);
      // console.log(record);
      // format dates so that datepicker can ingest them:
      record.start = moment(record.start).toDate();
      record.end = record.end ? moment(record.end).toDate() : undefined;
      console.log(record);
      setSelectedServiceRecord(record);
    };

    const handleSaveServiceRecord = async () => {
      const clone = structuredClone(selectedServiceRecord) as ProfessionalEngagementServiceRecord;
      if (!clone.service || !clone.start) {
        toast({
          title: 'Missed required data',
          description: 'Must choose service and start date/time.',
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
        return;
      }
      if (!clone.professionalengagementid) clone.professionalengagementid = currentEngagementDetails?.engagement?.id;
      clone.status = clone.end ? 'Completed' : 'In Progress';
      if (!clone.id) {
        await createProfessionalEngagementServiceRecord(user as UserAuth, currentProfessional.userid, clone.professionalengagementid as number, clone)
          .then((res) => res.json())
          .then((result) => {
            // const serviceRecordsClone = structuredClone(serviceRecords);
            // serviceRecordsClone.unshift(result);
            // setServiceRecords(serviceRecordsClone);
            const currentengagementClone: ProfessionalEngagementDetails = structuredClone(currentEngagementDetails);
            currentengagementClone.serviceRecords.unshift(result);
            setCurrentEngagementDetails(currentengagementClone);
          })
          .catch((err) => console.log(err));
      } else {
        await updateProfessionalEngagementServiceRecord(user as UserAuth, currentProfessional.userid, clone.professionalengagementid as number, clone)
          .then((res) => res.json())
          .then((result) => {
            const currentengagementClone: ProfessionalEngagementDetails = structuredClone(currentEngagementDetails);
            const indx = currentengagementClone.serviceRecords.findIndex((x) => x.id === clone.id);
            currentengagementClone.serviceRecords[indx] = result;
            setCurrentEngagementDetails(currentengagementClone);
          })
          .catch((err) => console.log(err));
      }

      setSelectedServiceRecord(undefined);
    };

    const handleDeleteServiceRecord = async () => {
      const clone = structuredClone(selectedServiceRecord) as ProfessionalEngagementServiceRecord;
      await deleteProfessionalEngagementServiceRecord(
        user as UserAuth,
        currentProfessional.userid,
        clone.professionalengagementid as number,
        clone.id as number
      ).then(() => {
        // const srs = serviceRecords.filter((sr) => sr.id !== clone.id);
        // setServiceRecords(srs);
        const currentengagementClone: ProfessionalEngagementDetails = structuredClone(currentEngagementDetails);
        currentengagementClone.serviceRecords = currentengagementClone.serviceRecords.filter((sr) => sr.id !== clone.id);
        setCurrentEngagementDetails(currentengagementClone);
        setSelectedServiceRecord(undefined);
      });
    };

    return (
      <Flex flexDir="column" flex={1} mr={3} height="100%" mt={4}>
        <Flex flex={1} justifyContent="space-between" alignItems="center" mb={2}>
          <Text color="charcoal" fontWeight="bold" fontSize="lg">
            Activity Log for Current Engagement
          </Text>
          <Flex>
            {currentEngagementDetails && cancelRef && (
              <AlertDialog isOpen={isCompleteEnagementOpen} leastDestructiveRef={cancelRef} onClose={onCompleteEngagementClose} isCentered size="lg">
                <AlertDialogOverlay>
                  <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                      Complete Engagement
                    </AlertDialogHeader>
                    <AlertDialogBody>Are you sure you want to complete this engagement?</AlertDialogBody>
                    <AlertDialogFooter>
                      <Button variant="darkoutline" ref={cancelRef} onClick={onCompleteEngagementClose}>
                        Cancel
                      </Button>
                      <Button
                        colorScheme="red"
                        onClick={() => {
                          onCompleteEngagementClose();
                          handleCompleteEngagement();
                        }}
                        ml={3}
                      >
                        Complete
                      </Button>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialogOverlay>
              </AlertDialog>
            )}
            {!budgetAndDeadlineRequired && (
              <>
                <Button
                  variant="darkoutline"
                  mx={4}
                  onClick={() => navigate('/invoice', { state: { clientuserid: client.user.userid, invoiceid: undefined } })}
                >
                  Invoice
                </Button>
                <Button
                  variant="solid"
                  leftIcon={<SmallAddIcon />}
                  onClick={() => setSelectedServiceRecord({ id: 0, clientuserid, prouserid: currentProfessional.userid })}
                >
                  Add Time
                </Button>
              </>
            )}
          </Flex>
        </Flex>
        <Flex flex={1} width="100%">
          {tabledata && tabledata.length > 0 && !budgetAndDeadlineRequired && (
            <DataTable columns={columns} data={tabledata} shadeAlternatingRows={false} handleRowSelected={rowSelectedHandler} />
          )}
          {budgetAndDeadlineRequired && <Text>Set budget and deadline above to start tracking time and invoicing.</Text>}
          {tabledata && tabledata.length === 0 && !budgetAndDeadlineRequired && (
            <Flex flexDir="column" my={4}>
              <Text mb={2}>No activity data. Add time to track activity.</Text>
              <Button
                variant="solid"
                leftIcon={<SmallAddIcon />}
                onClick={() => setSelectedServiceRecord({ id: 0, clientuserid, prouserid: currentProfessional.userid })}
              >
                Add Time
              </Button>
            </Flex>
          )}
          <Drawer placement="right" size="xl" onClose={() => setSelectedServiceRecord(undefined)} isOpen={selectedServiceRecord !== undefined}>
            <DrawerOverlay />
            <DrawerContent bg="white">
              <DrawerHeader borderBottomWidth="1px">
                <Text variant="header" color="charcoal">
                  Service Tracking Log
                </Text>
              </DrawerHeader>
              <DrawerBody>
                <Flex flexDir="column">
                  <Breadcrumb spacing="8px" mb={6} separator={<ChevronRightIcon color="blue.400" />}>
                    <BreadcrumbItem>
                      <BreadcrumbLink href="">Clients</BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <BreadcrumbLink href="">{client?.user.username}</BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <BreadcrumbLink href="">Tracking Log</BreadcrumbLink>
                    </BreadcrumbItem>
                  </Breadcrumb>
                  <Flex flexDir="column" my={2}>
                    <Text fontSize="lg" fontWeight="bold">
                      Service
                    </Text>
                    <Select
                      variant="outline"
                      width="30%"
                      flex={2}
                      mx={1}
                      bg="white"
                      color="charcoal"
                      borderColor="charcoal"
                      fontWeight="bold"
                      isRequired
                      placeholder="Select Service"
                      // value={selectedServiceRecord?.service ? selectedServiceRecord.service : serviceOptions[0].value}
                      value={selectedServiceRecord?.service}
                      onChange={(e) => setSelectedServiceRecord({ ...selectedServiceRecord, service: e.target.value } as ProfessionalEngagementServiceRecord)}
                    >
                      {serviceOptions &&
                        serviceOptions
                          .filter((es) => es.value !== 'All')
                          .map((so) => (
                            <option key={so.value} value={so.value}>
                              {so.title}
                            </option>
                          ))}
                    </Select>
                  </Flex>
                  <Flex flexDir="column" my={2}>
                    <Text fontSize="lg" fontWeight="bold" mb={2}>
                      Time Spent
                    </Text>
                    <Flex width="50%" flex={2} alignItems="center">
                      <Flex width="50%" my={1} alignItems="center">
                        <CalendarIcon mr={1} />
                        <DatePicker
                          customInput={<CustomDatePickerInput />}
                          isClearable
                          value="Set Start Date"
                          showTimeSelect
                          selected={selectedServiceRecord?.start ? (selectedServiceRecord.start as Date) : undefined}
                          onChange={(date) => setSelectedServiceRecord({ ...selectedServiceRecord, start: date } as ProfessionalEngagementServiceRecord)}
                        />
                      </Flex>
                      <Text flex={1} width="50%">
                        {moment(selectedServiceRecord?.start).format('MM/DD/YY hh:mm A')}
                      </Text>
                    </Flex>
                    <Flex width="50%" flex={1} my={2} alignItems="center">
                      <Flex width="50%">
                        <CalendarIcon mr={1} />
                        <DatePicker
                          customInput={<CustomDatePickerInput />}
                          isClearable
                          value="Set End Date  "
                          showTimeSelect
                          timeFormat="HH:mm:ss"
                          selected={selectedServiceRecord?.end ? moment(selectedServiceRecord.end, 'YYY-MM-DD HH:mm:ss').toDate() : undefined}
                          onChange={(date) => setSelectedServiceRecord({ ...selectedServiceRecord, end: date } as ProfessionalEngagementServiceRecord)}
                        />
                      </Flex>
                      <Text flex={1} width="50%">
                        {selectedServiceRecord?.end ? moment(selectedServiceRecord?.end).format('MM/DD/YY hh:mm A') : ''}
                      </Text>
                    </Flex>
                    {selectedServiceRecord?.start && selectedServiceRecord.end && (
                      <Text my={2}>
                        Selected duration: {moment(selectedServiceRecord?.end).diff(moment(selectedServiceRecord?.start), 'hours', true).toFixed(1)} hour(s)
                      </Text>
                    )}
                  </Flex>
                  <Flex flexDir="column" my={2}>
                    <Text fontSize="lg" fontWeight="bold">
                      Notes
                    </Text>
                    <Textarea
                      value={selectedServiceRecord?.notes}
                      onChange={(e) => setSelectedServiceRecord({ ...selectedServiceRecord, notes: e.target.value } as ProfessionalEngagementServiceRecord)}
                    />
                  </Flex>
                </Flex>
              </DrawerBody>
              <DrawerFooter>
                <Flex flex={1} mx={6} justifyContent="space-between">
                  {selectedServiceRecord && selectedServiceRecord.id > 0 && (
                    <Button variant="solid" bg="red.400" mr={4} onClick={handleDeleteServiceRecord}>
                      Delete
                    </Button>
                  )}
                  <Flex>
                    <Button mr={3} variant="darkoutline" onClick={() => setSelectedServiceRecord(undefined)}>
                      Cancel
                    </Button>
                    {!(selectedServiceRecord && selectedServiceRecord.invoiceid && selectedServiceRecord.invoiceid > 0) && (
                      <Button variant="solid" onClick={handleSaveServiceRecord}>
                        Save Log
                      </Button>
                    )}
                  </Flex>
                </Flex>
              </DrawerFooter>
            </DrawerContent>
          </Drawer>
        </Flex>
      </Flex>
    );
  };

  const renderNewMessageModal = () => (
    <Modal size="sm" isCentered isOpen={showNewMessageModal} onClose={() => setShowNewMessageModal(false)}>
      <ModalOverlay />
      <ModalContent borderRadius="lg">
        <ModalHeader>Send Message</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flexDir="column" justifyContent="center" m={2}>
            <Text fontSize="sm">Message:</Text>
            <Textarea onChange={(e) => setNewMessageBody(e.target.value)} />
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button variant="darkoutline" mr={4} onClick={() => setShowNewMessageModal(false)}>
            Cancel
          </Button>
          <Button
            variant="solid"
            onClick={(e) => {
              handleSendMessage();
              setShowNewMessageModal(false);
            }}
          >
            Send
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  const renderclientOverViewTab = () => (
    <Flex flexDir="column" flex={1} mb={4}>
      <Flex mt={4}>
        {renderClientDetails()}
        {renderNotesSection()}
      </Flex>
      {renderRequestSummary()}
      {renderCurrentEngagementSummary()}
      {renderServiceHistorySection()}
      <Flex justifyContent="flex-end" mt={4}>
        {/* <Button borderColor="red.400" color="red.400" borderWidth={1} borderRadius="2xl" bg="white" mr={4} onClick={() => console.log('TODO')}>
          Archive Client
        </Button> */}
        {currentEngagementDetails?.engagement.status === 'In Progress' && (
          <Button variant="solid" mr={4} onClick={onCompleteEngagementOpen}>
            Complete Engagement
          </Button>
        )}
      </Flex>
    </Flex>
  );

  const renderInvoicesTab = () => {
    if (!client) return <Box />;
    return (
      <Flex flexDir="column" flex={1}>
        <Flex justifyContent="flex-end">
          <Button mx={1} onClick={() => navigate('/invoice', { state: { clientuserid: client.user.userid, invoiceid: undefined } })}>
            Create New Invoice
          </Button>
        </Flex>
        <InvoiceTable clientuserid={clientuserid} />
      </Flex>
    );
  };

  // const renderEarningsTab = () => {
  //   if (!client) return <Box />;

  //   type serviceRecordsDisplayData = {
  //     service: string;
  //     earnings: number;
  //     hours: number;
  //     date: string;
  //     id: number;
  //   };

  //   const tabledata: serviceRecordsDisplayData[] = serviceRecords.map((i) => ({
  //     service: i.service as string,
  //     earnings: i.calculatedcharge as number,
  //     hours: moment(i.end).diff(moment(i.start), 'hours'),
  //     date: moment(i.start).format('MMM DD, YYYY'),
  //     id: i.id,
  //   }));

  //   const columnHelper = createColumnHelper<serviceRecordsDisplayData>();

  //   const columns = [
  //     columnHelper.accessor('service', {
  //       cell: (info: any) => info.getValue(),
  //       header: 'Service',
  //     }),
  //     columnHelper.accessor('earnings', {
  //       cell: (info: any) => `$${info.getValue()}`,
  //       header: 'Total Earnings',
  //       meta: {
  //         isNumeric: true,
  //       },
  //     }),
  //     columnHelper.accessor('hours', {
  //       cell: (info: any) => `${info.getValue()} hour(s)`,
  //       header: 'Billed Hours',
  //       meta: {
  //         isNumeric: true,
  //       },
  //     }),
  //     columnHelper.accessor('date', {
  //       cell: (info: any) => info.getValue(),
  //       header: 'Date',
  //     }),
  //   ];

  //   return (
  //     <Flex flexDir="column" flex={1} mr={3} height="100%" mt={4}>
  //       <DataTable columns={columns} data={tabledata} shadeAlternatingRows={false} handleRowSelected={() => console.log('clicked')} />
  //     </Flex>
  //   );
  // };

  if (!client) return <LoadingScreen />;

  return (
    <Flex flexDir="column" my={4} flex={1} mx={12} width="100%">
      <Flex flex={1} width="100%">
        <Breadcrumb spacing="8px" separator={<ChevronRightIcon color="blue.400" />}>
          <BreadcrumbItem>
            <BreadcrumbLink href="/clients">Clients</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="">{client?.user.username}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Flex>
      <Flex justifyContent="flex-end" flex={1} width="100%">
        <Button
          ref={focusRef}
          variant="darkoutline"
          borderRadius="xs"
          ml={2}
          onClick={(e) => {
            e.preventDefault();
            setShowNewMessageModal(true);
            if (focusRef.current) focusRef.current.blur();
          }}
          leftIcon={<BiMessageRounded />}
        >
          Message Client
        </Button>
        {renderNewMessageModal()}
      </Flex>
      <Flex flex={1} width="100%">
        <Text variant="header" color="charcoal">
          {client?.user.firstname} {client?.user.lastname}
        </Text>
      </Flex>
      <Flex my={6} flex={1}>
        <PageTabs
          tabNames={['Client Overview', 'Style', 'Wardrobe', 'Invoices', 'Appointments']}
          selectedTab={selectedTab}
          handleTabSelected={setSelectedTab}
        />
      </Flex>
      <Flex flex={1} width="100%">
        {selectedTab === 'Client Overview' && renderclientOverViewTab()}
        {selectedTab === 'Style' && renderStyleSection()}
        {selectedTab === 'Wardrobe' && <ClientWardrobeScreen />}
        {/* {selectedTab === 'Earnings by Client' && renderEarningsTab()} */}
        {selectedTab === 'Invoices' && renderInvoicesTab()}
        {selectedTab === 'Appointments' && renderAppointmentsSection()}
      </Flex>
    </Flex>
  );
}

export default ClientDetailsScreen;
