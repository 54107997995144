import moment from 'moment';
import { Text, Flex } from '@chakra-ui/react';
import React, { useCallback, useMemo, useEffect, useState } from 'react';

type LabelValueDisplayItemPros = {
  fieldname: string;
  fieldvalue: any;
  vertical?: boolean;
};

function LabelValueDisplayItem({ fieldname, fieldvalue, vertical, ...rest }: LabelValueDisplayItemPros) {
  return (
    <Flex flex={1} mb={0.75} py={0.5} flexDir={vertical ? 'column' : 'row'} {...rest}>
      <Text flex={1} size="sm" color="charcoal" fontWeight={500}>
        {fieldname}
      </Text>
      <Text flex={1} size="sm" color="charcoal" fontWeight={400}>
        {fieldvalue}
      </Text>
    </Flex>
  );
}

export default LabelValueDisplayItem;
