import { User as UserAuth } from 'firebase/auth';
import fetchWrapper from './fetchWrapper';
import { ENGAGEMENTS_API } from '../config/ServiceConstants';
import { ProfessionalEngagementServiceRecord } from '../models/ProfessionalEngagementServiceRecord';
import { ProfessionalEngagementRequest } from '../models/ProfessionalEngagementRequest';

export function fetchProfessionalEngagementRequests(userAuth: UserAuth, userId: string): Promise<any> {
  const url = `${ENGAGEMENTS_API}/professional/${userId}/requests`;
  return fetchWrapper(userAuth).get(url);
}

export function sendAcceptProfessionalEngagementRequest(userAuth: UserAuth, userId: string, engagementRequestId: number, message: string): Promise<any> {
  const url = `${ENGAGEMENTS_API}/professional/${userId}/requests/${engagementRequestId}/accept`;
  return fetchWrapper(userAuth).put(url, message);
}

export function sendDeclineProfessionalEngagementRequest(userAuth: UserAuth, userId: string, engagementRequestId: number, message: string): Promise<any> {
  const url = `${ENGAGEMENTS_API}/professional/${userId}/requests/${engagementRequestId}/decline`;
  return fetchWrapper(userAuth).put(url, message);
}

export function fetchCurrentEngagementForProAndClient(userAuth: UserAuth, proUserId: string, clientUserId: string): Promise<any> {
  const url = `${ENGAGEMENTS_API}/professional/${proUserId}/requests/client/${clientUserId}/current`;
  return fetchWrapper(userAuth).get(url);
}

export function updateEngagementRequest(userAuth: UserAuth, userId: string, engagementRequestId: number, model: ProfessionalEngagementRequest): Promise<any> {
  const url = `${ENGAGEMENTS_API}/${userId}/requests/${engagementRequestId}`;
  return fetchWrapper(userAuth).put(url, model);
}

export function fetchProfessionalRequestServiceRecordsForClient(userAuth: UserAuth, proUserId: string, clientUserId: string): Promise<any> {
  const url = `${ENGAGEMENTS_API}/professional/${proUserId}/servicerecords/${clientUserId}`;
  return fetchWrapper(userAuth).get(url);
}

export function createProfessionalEngagementServiceRecord(
  userAuth: UserAuth,
  userid: string,
  professionalEnagementId: number,
  item: ProfessionalEngagementServiceRecord
): Promise<any> {
  const url = `${ENGAGEMENTS_API}/${professionalEnagementId}/servicerecords`;
  return fetchWrapper(userAuth).post(url, item);
}

export function updateProfessionalEngagementServiceRecord(
  userAuth: UserAuth,
  userid: string,
  professionalEnagementId: number,
  item: ProfessionalEngagementServiceRecord
): Promise<any> {
  const url = `${ENGAGEMENTS_API}/${professionalEnagementId}/servicerecords/${item.id}`;
  return fetchWrapper(userAuth).put(url, item);
}

export function deleteProfessionalEngagementServiceRecord(
  userAuth: UserAuth,
  userid: string,
  professionalEnagementId: number,
  serviceRequestId: number
): Promise<any> {
  const url = `${ENGAGEMENTS_API}/${professionalEnagementId}/servicerecords/${serviceRequestId}`;
  return fetchWrapper(userAuth).delete(url);
}
