import { Text, Flex, Box, Button, Link, Avatar, background } from '@chakra-ui/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useAuthState } from 'react-firebase-hooks/auth';
import { User } from '../models/User';
import { searchUsers } from '../services/UsersService';
import { PaginatedResult } from '../models/PaginatedResult';
import { auth } from '../config/firebase';
import { UserQuery, UserSearchScope } from '../models/UserQuery';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import '../theme/react-bootstrap-typeahead-overrides.css';

type UserSearchMultiSelectProps = {
  value?: string | string[] | undefined;
  // handleSelection: (e: string | User | (string | User)[]) => void;
  handleSelection: (e: User[]) => void;
  allowMultiple?: boolean;
};

function UserSearchControl({ value, handleSelection, allowMultiple }: UserSearchMultiSelectProps) {
  const ref = useRef<any>(null);
  const [user, loading, error] = useAuthState(auth);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<User[]>([]);

  const handleSearch = (query: string) => {
    if (!user) return;
    setIsLoading(true);
    const userquery: UserQuery = {
      userid: user.uid,
      queryTerm: query,
      scope: UserSearchScope.All,
    };
    searchUsers(user, userquery)
      .then((res) => res.json())
      .then((result: PaginatedResult) => {
        console.log('user serach count: ', result.totalItemsAvailable);
        setOptions(result.pageItems);
        setIsLoading(false);
      });
  };

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  return (
    <AsyncTypeahead
      ref={ref}
      filterBy={filterBy}
      id="user-search-control"
      isLoading={isLoading}
      labelKey="username"
      minLength={3}
      onSearch={handleSearch}
      options={options}
      placeholder="Search for a user (at least 3 letters)..."
      multiple={allowMultiple}
      onChange={(selected: any[]) => handleSelection(selected)}
      // style={{ zIndex: 99, padding: 4, background: 'grey' }}
      renderMenuItemChildren={(option: any) => (
        <Flex bg="white" borderBottomWidth={1} p={2} alignItems="center">
          <Avatar ml={1} src={(option as User).useravatarurl} size="sm" />
          <Text ml={2}>{(option as User).username}</Text>
        </Flex>
      )}
    />
  );
}

export default UserSearchControl;
