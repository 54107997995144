import moment from 'moment';
import { Box, Text, Heading, HStack, Flex, Select, Button, SimpleGrid, Image, Center, Switch, Avatar } from '@chakra-ui/react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { User as UserAuth } from 'firebase/auth';
import { createColumnHelper } from '@tanstack/table-core';
import { useNavigate } from 'react-router-dom';
import { User } from '../models/User';
import { auth } from '../config/firebase';
import { CurrentUserContext } from '../contexts/CurrentUserProvider';
import { getClientListForProfessional } from '../services/ProfessionalsService';
import { Client } from '../models/Client';
import { userTableCellDisplayData, DataTable } from '../components/DataTable';
import LoadingScreen from '../components/LoadingScreen';

function ClientsScreen() {
  const { currentUser } = useContext(CurrentUserContext);
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const [fetching, setfetching] = useState(true);
  const [clientList, setClientList] = useState<Client[]>([]);
  const [showGridView, setShowGridView] = useState(false);

  useEffect(() => {
    if (!user) return;
    if (clientList && clientList.length > 0) return;
    const fetchClientList = async () => {
      await getClientListForProfessional(user)
        .then((res) => res.json())
        .then((result: Client[]) => {
          result.sort((a, b) => a.currentstatus.localeCompare(b.currentstatus));
          setClientList(result);
          setfetching(false);
        });
    };
    fetchClientList().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const renderClientsCardView = () => (
    // templateColumns='repeat(auto-fill, minmax(200px, 1fr))'
    <SimpleGrid columns={[2, 4, 6]} spacing={6}>
      {clientList &&
        clientList.map((client) => (
          <Flex flexDir="column" key={client.user.id} onClick={() => navigate('/clientdetails', { state: { clientuserid: client.user.userid } })}>
            <Center w={36} h={40} bg="grey.100" px={2}>
              <Image src={client?.user?.useravatarurl} my={1} height="95%" width="95%" objectFit="cover" />
            </Center>
            <Flex flexDir="column" justifyContent="flex-start" fontSize="xs">
              <HStack justifyContent="space-between">
                <HStack color="orange.400">
                  <Text>{client?.user?.firstname ? client.user.firstname : client?.user?.username} </Text>
                  <Text>{client?.user?.lastname}</Text>
                </HStack>
                <Text>${client?.totalearnings}</Text>
              </HStack>
              <Text>{client?.currentstatus}</Text>
              {/* <Text>
                {client?.engagements
                  ?.filter((e) => e.status === 'In Progress')
                  .map((er) => er.requestedservices)
                  .join(', ')}
              </Text> */}
              <Text>{client?.engagements.length} Service(s)</Text>
            </Flex>
          </Flex>
        ))}
    </SimpleGrid>
  );

  const renderClientsGridView = () => {
    type clientDisplayData = {
      client: userTableCellDisplayData;
      status: string;
      location: string | undefined;
      earnings: number;
      services: string[];
      id: number;
    };

    const tabledata: clientDisplayData[] = clientList.map((er) => ({
      client: {
        name: er.user.username,
        avatarurl: er.user.useravatarurl,
      },
      status: er.currentstatus,
      location: er.user.address?.city,
      earnings: er.totalearnings,
      services: er.engagements.flatMap((engs) => engs.requestedservices),
      id: er.user.id,
    }));

    const columnHelper = createColumnHelper<clientDisplayData>();

    const columns = [
      columnHelper.accessor('client', {
        // TODO: extract this to a function
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info: any) => (
          <HStack>
            <Avatar src={info.getValue().avatarurl} size="sm" />
            <Text>{info.getValue().name}</Text>
          </HStack>
        ),
        header: 'Client',
      }),
      columnHelper.accessor('status', {
        cell: (info: any) => info.getValue(),
        header: 'Status',
      }),
      columnHelper.accessor('location', {
        cell: (info: any) => info.getValue(),
        header: 'Location',
      }),
      columnHelper.accessor('earnings', {
        cell: (info: any) => `$${info.getValue()}`,
        header: 'Earnings',
        meta: {
          isNumeric: true,
        },
      }),
      columnHelper.accessor('services', {
        cell: (info: any) => info.getValue().join(', \n'),
        header: 'Service History ',
      }),
    ];

    const rowSelectedHandler = (index: number) => {
      const rowid = tabledata[index].id;
      // console.log('index: ', index, 'erid: ', rowid);
      const client = clientList.filter((i) => i.user.id === rowid)[0];
      console.log('selected user: ', client.user.userid);
      navigate('/clientdetails', { state: { clientuserid: client.user.userid } });
    };

    return (
      <Flex flex={1} width="100%">
        <DataTable columns={columns} data={tabledata} shadeAlternatingRows={false} handleRowSelected={rowSelectedHandler} />
      </Flex>
    );
  };

  const handleSort = (sortval: string) => {
    const clone: Client[] = structuredClone(clientList);
    if (sortval === 'earnings') {
      clone.sort((a, b) => b.totalearnings - a.totalearnings);
    }
    if (sortval === 'status') {
      clone.sort((a, b) => a.currentstatus.localeCompare(b.currentstatus));
    }
    if (sortval === 'name') {
      clone.sort((a, b) => a.user?.lastname?.localeCompare(b.user?.lastname));
    }
    setClientList(clone);
  };

  if (fetching) return <LoadingScreen />;

  return (
    <Flex flexDirection="column" flex={1} width="100%" px={12} py={8}>
      <Flex flex={1} width="100%" mb={4}>
        <Text variant="header" color="charcoal" fontWeight="bold" mr={6}>
          Clients
        </Text>
      </Flex>
      <Flex justifyContent="space-between" alignItems="center" mb={6}>
        <HStack>
          <Select
            variant="outline"
            placeholder="Sort..."
            flex={1}
            mx={1}
            bg="white"
            color="charcoal"
            borderColor="charcoal"
            fontWeight="bold"
            onChange={(e) => handleSort(e.target.value)}
          >
            <option value="earnings">By Earnings</option>
            <option value="status">By Status</option>
            <option value="name">By Name</option>
          </Select>
        </HStack>
        <HStack>
          <Text>Card View</Text>
          <Switch size="md" colorScheme="orange" onChange={(e) => setShowGridView(!showGridView)} />
          <Text noOfLines={2}>Grid View</Text>
        </HStack>
      </Flex>
      <Flex flex={1} width="100%">
        <>
          {clientList && clientList.length === 0 && <Text ml={4}>No Clients to display.</Text>}
          {!showGridView && renderClientsCardView()}
          {showGridView && renderClientsGridView()}
        </>
      </Flex>
    </Flex>
  );
}

export default ClientsScreen;
