import { ComponentStyleConfig } from '@chakra-ui/react';

const Select: ComponentStyleConfig = {
  // style object for base or default style
  baseStyle: {
    placeholder: 'Sort...',
    mx: 1,
    bg: 'white',
    color: 'charcoal',
    borderColor: 'charcoal',
    fontWeight: 'bold',
  },
  // styles for different sizes ("sm", "md", "lg")
  sizes: {},
  // styles for different visual variants ("outline", "solid")
  variants: {
    basic: {
      placeholder: 'Sort...',
      mx: 1,
      bg: 'white',
      color: 'charcoal',
      borderColor: 'charcoal',
      fontWeight: 'bold',
    },
  },
  // default values for 'size', 'variant' and 'colorScheme'
  defaultProps: {
    variant: 'basic',
    placeholder: 'Sort...',
    mx: 1,
    bg: 'white',
    color: 'charcoal',
    borderColor: 'charcoal',
    fontWeight: 'bold',
  },
};

export default Select;
