/* eslint-disable react/no-array-index-key */
import moment from 'moment';
import PropTypes from 'prop-types';
import { Calendar, momentLocalizer, Event } from 'react-big-calendar';
import DatePicker from 'react-datepicker';
import {
  Button,
  HStack,
  Image,
  Flex,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  Input,
  Select,
  Divider,
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  AvatarGroup,
  Avatar,
  Container,
  Textarea,
} from '@chakra-ui/react';
import React, { forwardRef, useContext, useEffect, useMemo, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { User as UserAuth } from 'firebase/auth';
import { CheckCircleIcon, NotAllowedIcon, ViewIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { User } from '../models/User';
import { auth } from '../config/firebase';
import { CurrentUserContext } from '../contexts/CurrentUserProvider';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './CalendarStyles.css';
import { CurrentProfessionalContext } from '../contexts/CurrentProfessionalProvider';
import { createMessageThread, fetchUserMessages, putMessageAsRead } from '../services/MessagesService';
import { Message, MessageParticipant, MessageThread, UserMessage } from '../models/Message';
import LoadingScreen from '../components/LoadingScreen';
import { Client } from '../models/Client';
import { getClientListForProfessional } from '../services/ProfessionalsService';
import ClientDetailsDisplay, { ClientContactDetailsDisplay, ClientPersonalDetailsDisplay, ClientStyleDetailsDisplay } from '../components/ClientDetailsDisplay';
import { fetchUser } from '../services/UsersService';

function MessagesScreen() {
  const { currentProfessional } = useContext(CurrentProfessionalContext);
  const navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);
  const [fetching, setfetching] = useState(true);
  const [messages, setMessages] = useState<UserMessage[]>([]);
  const [clientMessages, setClientMessages] = useState<UserMessage[]>([]);
  const [personalMessages, setPersonalMessages] = useState<UserMessage[]>([]);
  const [clientList, setClientList] = useState<Client[]>([]);
  const [messageListMode, setMessageListMode] = useState('Clients'); // Clients or Personal
  const [selectedMessage, setSelectedMessage] = useState<UserMessage | undefined>();
  const [selectedMessageUser, setSelectedMessageUser] = useState<User | undefined>();
  const [newThreadText, setNewThreadText] = useState<string | undefined>();

  useEffect(() => {
    if (!user) return;
    if (messages && messages.length > 0) return;
    const fetchMessages = async () => {
      await fetchUserMessages(user)
        .then((res) => res.json())
        .then((result: UserMessage[]) => {
          result.sort((a, b) => (a.message.ts > b.message.ts ? -1 : 1));
          setMessages(result);
          setfetching(false);
        });
    };
    fetchMessages().catch(console.error);
  }, [messages, user]);

  useEffect(() => {
    if (!user) return;
    if (clientList && clientList.length > 0) return;
    const fetchClientList = async () => {
      await getClientListForProfessional(user)
        .then((res) => res.json())
        .then((result: Client[]) => {
          result.sort((a, b) => a.currentstatus.localeCompare(b.currentstatus));
          setClientList(result);
        });
    };
    fetchClientList().catch(console.error);
  }, [clientList, user]);

  useEffect(() => {
    if (!clientList || !messages) return;
    if (clientList.length === 0 || messages.length === 0) return;
    // divide the messages up into clients and personal
    const allClients = clientList.map((client) => client.user.userid);
    // console.log('allClients: ', allClients);
    const clientones = messages.filter((m) => m.participants.some((r) => allClients.includes(r.userid)));
    // console.log('clientMessages: ', clientMessages);
    setClientMessages(clientones);
    const personalones = messages.filter((m) => m.participants.every((r) => !allClients.includes(r.userid)));
    // console.log('personalMessages:', personalMessages);
    setPersonalMessages(personalones);
  }, [messages, clientList]);

  const truncate = (input: string) => (input.length > 24 ? `${input.substring(0, 24)}...` : input);

  const renderMessageParticipants = (participants: MessageParticipant[]) => (
    <AvatarGroup size="sm" max={5} mr={1}>
      {participants &&
        participants
          .filter((p: MessageParticipant) => p.userid !== currentProfessional?.userid)
          .map((participant: MessageParticipant) => <Avatar key={participant.userid} name={participant.username} src={participant.useravatarurl} />)}
    </AvatarGroup>
  );

  const handleMessageSelected = (msg: UserMessage) => {
    setSelectedMessage(msg);

    // only show the client detail side panel if there is 1 other person in the message
    if (msg.participants.length <= 2) {
      const { userid } = msg.participants.filter((p: MessageParticipant) => p.userid !== currentProfessional?.userid)[0];
      fetchUser(user as UserAuth, userid)
        .then((res: { json: () => any }) => res.json())
        .then((result: User) => setSelectedMessageUser(result));
    } else {
      setSelectedMessageUser(undefined);
    }

    putMessageAsRead(user as UserAuth, msg.id).then((res: { json: () => any }) => res.json());

    messages[messages.findIndex((m) => m.id === msg.id)].hasUnreadThreads = false;
    setMessages(messages);
  };

  const handleAddThread = () => {
    if (!user) return;
    if (!selectedMessage) return;
    if (!newThreadText) return;

    createMessageThread(user as UserAuth, selectedMessage.id, newThreadText).then((res: { json: () => any }) => res.json());

    const newthread: MessageThread = {
      userid: currentProfessional.userid,
      username: currentProfessional.username,
      useravatarurl: currentProfessional.useravatarurl,
      messageBody: newThreadText,
      ts: new Date().toLocaleDateString(),
    };
    selectedMessage.message.threads.push(newthread);
    setSelectedMessage(selectedMessage);
    setNewThreadText('');
  };

  const renderAllMessagesList = () => {
    if (!messages) return <Box />;
    let messagesToRender: UserMessage[] = messageListMode === 'Personal' ? personalMessages : clientMessages;
    if (!messagesToRender) messagesToRender = messages;
    return (
      <Flex flexDir="column" flex={1} mx={2}>
        <Flex mb={1} justifyContent="space-evenly" mx={3}>
          <Text
            py={2}
            fontWeight="bold"
            color={messageListMode === 'Clients' ? 'orange.400' : 'grey.400'}
            borderBottomColor="blue.400"
            borderBottomWidth={messageListMode === 'Clients' ? 2 : 0}
            mr={3}
            onClick={() => setMessageListMode('Clients')}
          >
            Clients
          </Text>
          <Text
            py={2}
            fontWeight="bold"
            color={messageListMode === 'Personal' ? 'orange.400' : 'grey.400'}
            borderBottomColor="blue.400"
            borderBottomWidth={messageListMode === 'Personal' ? 2 : 0}
            onClick={() => setMessageListMode('Personal')}
          >
            Personal
          </Text>
        </Flex>
        {messagesToRender &&
          messagesToRender.map((message: UserMessage) => (
            <Flex key={message.id} flex={1} my={2} alignItems="center" justifyContent="flex-start" onClick={() => handleMessageSelected(message)} fontSize="xs">
              {renderMessageParticipants(message.participants)}
              <Flex flexDir="column" flex={1} mx={2}>
                <Text>
                  {message.participants &&
                    message.participants
                      .filter((p: MessageParticipant) => p.userid !== currentProfessional?.userid)
                      .map((p) => p.username)
                      .join(', ')}
                </Text>
                <Text color={message.hasUnreadThreads ? 'blue.700' : 'black.700'} fontWeight={message.hasUnreadThreads ? 'bold' : 'medium'}>
                  {truncate(message.message.messageBody)}
                </Text>
              </Flex>
              <Flex ml={1} flexDir="column" alignItems="flex-end">
                <Text>{moment(message.message.ts).format('MMM DD')}</Text>
                {message.hasUnreadThreads && <Box mr={2} h={2} w={2} borderRadius="full" bg="blue.500" />}
              </Flex>
            </Flex>
          ))}
      </Flex>
    );
  };

  const renderMessageThreads = () => {
    if (!selectedMessage) return <Box />;
    return (
      <Flex flexDir="column" flex={1} mx={2} px={4}>
        <Flex borderWidth={1} alignItems="center" py={4}>
          {renderMessageParticipants(selectedMessage.participants)}
          {selectedMessage.participants &&
            selectedMessage.participants
              .filter((p: MessageParticipant) => p.userid !== currentProfessional?.userid)
              .map((p, i2) => (
                <Text key={p.userid} mr={2}>
                  {p.username}
                  {i2 === selectedMessage.participants.length - 2 ? ' ' : ','}
                </Text>
              ))}
        </Flex>
        <Flex flexDir="column" my={2}>
          <Flex my={1} flexDir="column" justifyContent={selectedMessage.message.authorUserId === currentProfessional.userid ? 'flex-end' : 'flex-start'}>
            <Flex justifyContent={selectedMessage.message.authorUserId === currentProfessional.userid ? 'flex-end' : 'flex-start'}>
              {selectedMessage.message.authorUserId !== currentProfessional.userid && <Avatar src={selectedMessage.message.useravatarurl} />}
              <Flex
                borderRadius="xl"
                py={1}
                px={3}
                maxW="40%"
                bg={selectedMessage.message.authorUserId === currentProfessional.userid ? 'blue.300' : 'grey.300'}
                color={selectedMessage.message.authorUserId === currentProfessional.userid ? 'white' : 'grey.700'}
              >
                {selectedMessage.message.messageBody}
              </Flex>
            </Flex>
            <Flex justifyContent={selectedMessage.message.authorUserId === currentProfessional.userid ? 'flex-end' : 'flex-start'}>
              {selectedMessage.message.imageDataUrl && <Image maxW="30%" src={selectedMessage.message.imageDataUrl} />}
            </Flex>
          </Flex>
          {selectedMessage.message.threads &&
            selectedMessage.message.threads.map((thread, i) => (
              <Flex key={i.toString()} my={2} justifyContent={thread.userid === currentProfessional.userid ? 'flex-end' : 'flex-start'}>
                <Flex width="100%" alignItems={thread.userid === currentProfessional.userid ? 'flex-end' : 'flex-start'}>
                  <Flex width="100%" alignItems={thread.userid === currentProfessional.userid ? 'flex-end' : 'flex-start'}>
                    {thread.userid !== currentProfessional.userid && <Avatar src={thread.useravatarurl} />}
                    <Flex width="100%" flexDir="column" alignItems={thread.userid === currentProfessional.userid ? 'flex-end' : 'flex-start'}>
                      <Flex
                        borderRadius="xl"
                        py={1}
                        px={3}
                        maxW="40%"
                        bg={thread.userid === currentProfessional.userid ? 'blue.300' : 'grey.300'}
                        color={thread.userid === currentProfessional.userid ? 'white' : 'grey.700'}
                      >
                        {thread.messageBody}
                      </Flex>
                      <Text fontSize="xs" mx={1}>
                        {moment(thread.ts).calendar()}
                      </Text>
                      {thread.imageDataUrl && <Image src={thread.imageDataUrl} maxH={48} />}
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            ))}
        </Flex>
        <Flex flexDir="column" justifyContent="center" alignItems="center" m={2}>
          <Textarea width="100%" value={newThreadText} onChange={(e) => setNewThreadText(e.target.value)} placeholder="write a message..." size="lg" />
          <Flex flex={1} width="100%" justifyContent="flex-end" my={1}>
            <Button onClick={handleAddThread}>Send</Button>
          </Flex>
        </Flex>
      </Flex>
    );
  };

  const renderClientDetails = () => {
    if (!selectedMessageUser) return <Box />;
    const client = clientList.filter((c) => c.user.userid === selectedMessageUser.userid)[0];
    if (!selectedMessageUser) return <Box />;
    return (
      <Flex flexDir="column" px={4}>
        <Flex mb={2} alignItems="center">
          <Avatar src={selectedMessageUser.useravatarurl} />
          <Text fontSize="lg" fontWeight="bold">
            {selectedMessageUser.username}
          </Text>
        </Flex>
        <Flex flexDir="column">
          <Text fontWeight="bold" mb={1}>
            Services Requested
          </Text>
          <Text>TODO: get from EngReqs...</Text>
          {client?.engagements[0]?.requestedservices?.map((service) => (
            <Text key={service} p={1}>
              {service}
            </Text>
          ))}
        </Flex>
        <Flex my={2}>
          <ClientPersonalDetailsDisplay user={selectedMessageUser} />
        </Flex>
        <Flex my={2}>
          <ClientStyleDetailsDisplay user={selectedMessageUser} />
        </Flex>
        <Flex my={2}>
          <ClientContactDetailsDisplay user={selectedMessageUser} />
        </Flex>
        <Flex mt={6}>
          <Button
            bg="blue.400"
            color="white"
            borderRadius="md"
            size="sm"
            onClick={() => navigate('/clientdetails', { state: { clientuserid: selectedMessageUser.userid } })}
          >
            Go to user profile
          </Button>
        </Flex>
      </Flex>
    );
  };

  if (fetching) return <LoadingScreen />;

  return (
    <Flex flex={1} width="100%" justifyContent="center" m={4}>
      <Flex flex={0.2} height="full" flexDir="column" borderRightWidth={2}>
        {renderAllMessagesList()}
      </Flex>
      <Flex flex={0.6} minW="45%" maxHeight={700} overflowY="scroll">
        {renderMessageThreads()}
      </Flex>
      <Flex flex={0.2} borderLeftWidth={2}>
        {renderClientDetails()}
      </Flex>
    </Flex>
  );
}

export default MessagesScreen;
