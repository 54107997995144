// eslint-disable-next-line import/prefer-default-export
export const enum WardrobeItemCategory {
  All = 'all',
  Tops = 'tops',
  Bottoms = 'bottoms',
  Dresses = 'dress',
  Shoes = 'shoes',
  Accessories = 'accessories',
  Undetermined = 'undetermined',
}

export const WardrobeItemSubCategories = [
  'All',
  'Belts',
  'Blazers',
  'Capris',
  'Dresses',
  'Flats',
  'Gloves',
  'Hat',
  'Heels',
  'Innerwear Vests',
  'Jackets',
  'Jeans',
  'Jeggings',
  'Jumpsuit',
  'Leggings',
  'Pants',
  'Rompers',
  'Sandals',
  'Scarves',
  'Shirts',
  'Shoes',
  'Shorts',
  'Shrug',
  'Skirts',
  'Stockings',
  'Sweaters',
  'Sweatshirts',
  'Tops',
  'Track Pants',
  'Tracksuits',
  'Tshirts',
  'Tunics',
  'Waistcoat',
  'Other',
];

export const WardrobeItemSeasonality = ['All', 'Summer', 'Fall', 'Winter', 'Spring'];
