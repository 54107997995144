import { Icon, Image } from '@chakra-ui/react';
import React from 'react';
import { HiOutlinePlusCircle, HiPlusCircle } from 'react-icons/hi';

function AddIcon({ ...rest }) {
  // return <Icon as={HiOutlinePlusCircle} ml={4} bg="purple.100" color="purple.400" boxSize={6} borderRadius="100%" borderWidth={0} p={0} {...rest} />;
  // return <Icon as={HiPlusCircle} color="purple.100" boxSize={6} borderRadius="100%" p={0} {...rest} />;
  return <Image src="/icons/AddIcon.png" alt="Add" boxSize={6} borderRadius="100%" p={0} {...rest} />;
}

export default AddIcon;
